import {
  InfraTypography
} from '@infralabs/design-system';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../components';

const ChargeTitle = () => {
  const { t } = useTranslation();

  return (
    <Flex justify={'space-between'} wrap="wrap">
      <InfraTypography variant={'h1_small'} weight={'bold'}>
        {t('charge.title')}
      </InfraTypography>
    </Flex>
  );
}

export default ChargeTitle
