import {
  AlertModal,
  AutocompleteSellers,
  AutocompleteStatus,
  Box,
  Button,
  Flex,
  Paginate,
  useAccount,
} from '../../components';
import { MultiSelectCategories, MultiSelectBrands } from '../../components/Selects';
import {
  InfraBreadcrumbs,
  InfraCol,
  InfraDatepicker,
  InfraGrid,
  InfraIcons,
  InfraLabel,
  InfraModal,
  InfraTable,
  InfraTableColumn,
  InfraTag,
  InfraTextField,
  InfraTypography,
} from '@infralabs/design-system';
import { useEffect, useMemo, useState } from 'react';
import { Colors } from '../../Helper';
import { FormatCurrency } from '../../Helper/FormatCurrency';
import { WrapperModalConfirm, WrapperModalProgrammedPrice } from '../styles/style-wrapper-modal';
import { Link } from 'react-router-dom';
import ModalDelete from './modalDelete';
import ModalProduct from './modalProduct';
import ModalSku from './modalSku';
import { ProgrammedPriceRepository } from '../../Repository/ProgrammedPriceRepository';
import { ResultEnum } from '../../common/enum/result.enum';
import { StatusColor } from '../../common/utils/statusColor';
import { StatusEnum } from '../../common/enum/status.enum';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from '../../hooks/use-did-mount-effect';
import Tooltip from '../../components/Tooltip';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';
import useProgrammedPricesPaginate from './useProgrammedPricesPaginate';
import { useHistory } from 'react-router-dom'

const ProgrammedPricesComponent = ({ locationUrl = '' }: { locationUrl: string }) => {
  const history = useHistory()
  useEffect(() => {
    if (locationUrl != '') {
      history.push(locationUrl)
    }
  }, [locationUrl])

  const { currentTenant, currentChannel } = useAccount();
  const { t } = useTranslation();
  const [seller, setSeller] = useState('');
  const [channel, setChannel] = useState('');
  const [status, setStatus] = useState('');
  const [activeAt, setActiveAt] = useState('');
  const [deactiveAt, setDeactiveAt] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [description, setDescription] = useState('');
  const [showModalProduct, setShowModalProduct] = useState(false);
  const [showModalSKU, setShowModalSKU] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [contextAlert, setContexAlert] = useState<{
    context: string;
    type: any;
    messages?: any;
  }>({ context: '', type: 'success' });
  const [showModalDeleteProgrammedPrice, setShowModalDeleteprogrammedPrice] = useState(false);
  const [order, setOrder] = useState('status.id');
  const [programmedPriceSelected, setProgrammedPriceSelected] = useState(null);
  const [typeModal, setTypeModal] = useState('');
  const [showSuccessModalSku, setShowSuccessModalSku] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [typeOperation, setTypeOperation] = useState<'edit' | 'new'>('new');
  const [categoryId, setStateCategories] = useState([]);
  const [brandId, setStateBrands] = useState([]);

  const programmedPriceRepository = new ProgrammedPriceRepository();

  const params = useMemo(() => ({
    seller, channel, statusId: status, activeAt, deactiveAt, pageSize, order, currentTenant, categoryId, brandId, currentPage, description
  }), [seller, channel, status, activeAt, deactiveAt, pageSize, order, currentTenant, categoryId, brandId, currentPage, description])
  const { loading, paging, data: programmedPrices, refetch: refetchProgrammedPrices } = useProgrammedPricesPaginate(params)

  useDidMountEffect(() => {
    setShowModalAlert(false);
    setShowModalProduct(false);
    setShowModalSKU(false);
    setShowSuccessModalSku(false);
    setShowModalDeleteprogrammedPrice(false);
    setProgrammedPriceSelected(null);
  }, [currentTenant]);

  const links = [
    {
      text: t('app'),
      href: '/',
      use: 'link',
    },
    {
      text: t('main'),
      href: '/',
      disabled: false,
    },
    {
      text: t('programmedPrice.title'),
      disabled: false,
    },
  ];

  const renderStatus = (rowData: any) => {
    if (
      (rowData.active == false && rowData.status.status == StatusEnum.pending) ||
      (rowData.active == false && rowData.status.status == StatusEnum.current)
    ) {
      return (
        <InfraTag
          color="inactive"
          name={t('scheduledPrice.form.labels.statusInactive')}
          outline={false}
          borde={false}
        />
      );
    } else {
      let statusColor = new StatusColor();
      return (
        <InfraTag
          color={statusColor.status[rowData.status.status]}
          name={
            rowData.status
              ? t('programmedPrice.fields.status.status.' + rowData.status.status)
              : t('programmedPrice.fields.status.status.pending')
          }
        >
          {' '}
        </InfraTag>
      );
    }
  };

  const renderDelete = (priceData: any) => {
    if (priceData.status == StatusEnum.pending) {
      return (
        <a onClick={() => handleDelete(priceData)}>
          <InfraIcons name="TrashSimple" weight="bold" size={14} />
        </a>
      );
    }
  };

  const renderEdit = (priceData: any) => {
    if (priceData.status !== StatusEnum.canceled && priceData.status !== StatusEnum.expired) {
      return (
        <a onClick={() => handleEditPrice(priceData)}>
          <InfraIcons name="PencilSimple" weight="bold" size={14} />
        </a>
      );
    }
  };

  const handlePagination = (_, value) => {
    setCurrentPage(value);
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleSalesChannel = (channel) => {
    setCurrentPage(1);
    setChannel(channel.id);
  }

  const handleSellers = (id) => {
    setCurrentPage(1);
    setSeller(id);
  };

  const handleStatus = (id) => {
    setCurrentPage(1);
    setStatus(id);
  };

  const renderFilterIcon = () => {
    return (
      <span style={{ marginInline: '10px' }}>
        <InfraIcons name="SortDescending" size={16} weight="bold" />
      </span>
    );
  };

  const handleEditPrice = async (programmedPrice) => {
    const programmedpriceFound: any = await programmedPriceRepository.setChannelId(currentChannel.id).findOne('1', programmedPrice.id);
    setProgrammedPriceSelected(programmedpriceFound.data['programmed-prices'][0]);
    setShowModalSKU(true);
    setTypeModal('edit');
  };

  const handleDelete = (programmedPrice) => {
    setProgrammedPriceSelected(programmedPrice);
    setShowModalDeleteprogrammedPrice(true);
  };

  const handleOperation = async (result) => {
    setShowModalAlert(true);
    setShowModalProduct(false);
    setContexAlert({ context: 'createPriceByProduct', type: result.type, messages: result.messages });
    refetchProgrammedPrices();
  };

  const renderError = () => {
    if (contextAlert.messages && contextAlert.messages.length > 0) {
      return contextAlert.messages.map((el, key) => {
        return el.error ? (
          <span key={key}>
            {String(t(`programmedPrice.form.textValidations.${el.error}`)).replace(':sku', el.skuName)}
          </span>
        ) : (
          <span key={key}>
            {String(t(`programmedPrice.form.textValidations.ProgrammedPriceCreateSuccess`)).replace(':sku', el.skuName)}
          </span>
        );
      });
    }
  };

  const renderModalAlert = () => {
    if (contextAlert.context === 'delete') {
      if (contextAlert.type === ResultEnum.failed) {
        return 'Falha ao deletar o registro';
      } else if (contextAlert.type === ResultEnum.success) {
        return 'Registro deletado com sucesso';
      }
    } else if (contextAlert.context === 'createPriceByProduct') {
      if (contextAlert.type === ResultEnum.failed) {
        return t('programmedPrice.form.textValidations.AllSkuAreadyExists');
      } else if (contextAlert.type === ResultEnum.alreadyExists) {
        return t('programmedPrice.form.textValidations.AllSkuAreadyExists');
      } else if (contextAlert.type === ResultEnum.success) {
        return t('programmedPrice.form.textValidations.PriceCreateSuccess');
      } else if (contextAlert.type === ResultEnum.both) {
        return () => renderError();
      }
    }
  };

  const handleModalSkuCreate = () => {
    setShowModalSKU(true);
    setTypeModal('create');
  };

  const handleCloseModal = () => {
    setShowModalSKU(false);
    refetchProgrammedPrices();
    setProgrammedPriceSelected(null);
  };

  const handleSuccessModalSku = ({ typeOperation }) => {
    setTypeOperation(typeOperation);
    setShowSuccessModalSku(true);
    setProgrammedPriceSelected(null);
    refetchProgrammedPrices();
  };

  const renderNumber = (value) => {
    return <span>{value ? FormatCurrency.format('BRL', value) : value}</span>;
  };

  const renderModalSuccessSku = () => {
    if (typeOperation === 'edit') {
      return t(`successUpdateMessageDefault`);
    }
    return t(`successCreateMessageDefault`);
  };

  const renderBreadcrumb = () => {
    return (
      <Box>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </Box>
    );
  };

  const renderTitle = () => {
    return (
      <Flex justify={'space-between'} wrap="wrap" w="100%" align={'center'}>
        <InfraTypography variant={'h1_small'} weight={'bold'}>
          {t('programmedPrice.title')}
        </InfraTypography>
        <Flex gap="8px" justify={'end'} align={'center'}>
          <Box>
            <Button
              color="neutral"
              size="large"
              outline={false}
              onClick={handleModalSkuCreate}
              icon={'Plus'}
              iconRight={true}
              fullWidth={true}
            >
              <InfraTypography variant={'body_small'} weight={'semibold'} color={Colors.shade_0}>
                {t('programmedPrice.button.sku')}
              </InfraTypography>
            </Button>
          </Box>
          <Box>
            <Button
              color="neutral"
              size="large"
              outline={false}
              onClick={() => setShowModalProduct(true)}
              icon={'Plus'}
              iconRight={true}
            >
              <InfraTypography variant={'body_small'} weight={'semibold'} color={Colors.shade_0}>
                {t('programmedPrice.button.product')}
              </InfraTypography>
            </Button>
          </Box>
        </Flex>
      </Flex>
    );
  };

  function isClean(data) {
    if (data) {
      setActiveAt('');
      setDeactiveAt('');
    }
  }

  function handleMultiSelectCategory(data) {
    if (data.length > 0) {
      setCurrentPage(1);
      setStateCategories(data);
    } else {
      setStateCategories([]);
    }
  }

  function handleMultiSelectBrands(data) {
    setCurrentPage(1);
    if (data.length > 0) {
      setStateBrands(data);
    } else {
      setStateBrands([]);
    }
  }

  const renderToolbar = () => {
    return (
      <Flex justify={'space-between'}>
        <Flex gap="8px" direction={'row'} grow="0" shrink="1" basis="auto">
          <Box w="159.01px">
            <InfraLabel color="neutral_600">Channel</InfraLabel>
            <AutocompleteChannels
              onChange={handleSalesChannel}
            />
          </Box>
          <Box w="159.01px">
            <InfraLabel color="neutral_600">{t('programmedPrice.fields.autocomplete.seller.label')}</InfraLabel>
            <AutocompleteSellers
              versionId="1"
              onChange={handleSellers}
              channelId={channel}
              placeholder={t('programmedPrice.fields.autocomplete.seller.placeholder')}
            />
          </Box>

          <Box w="330px">
            <InfraLabel color="neutral_600">{t('programmedPrice.fields.filter.period.label')}</InfraLabel>
            <InfraDatepicker
              onCleared={isClean}
              height="small"
              autoComplete={'off'}
              subtractYears={10}
              placeholder={t('programmedPrice.fields.filter.period.placeholder')}
              period={true}
              value={''}
              shouldCloseOnSelect={false}
              onChange={(value, name) => {
                if (Array.isArray(value)) {
                  setActiveAt(format(new Date(value[0]), 'yyyy-MM-dd HH:mm:ss'));
                  if (value[1] !== null) {
                    setDeactiveAt(format(new Date(value[1]), 'yyyy-MM-dd HH:mm:ss'));
                  }
                } else {
                  setActiveAt(format(new Date(value), 'yyyy-MM-dd HH:mm:ss'));
                }
                setCurrentPage(1);
              }}
              theme="light"
              setHours={true}
              maskDate="dd.MM.yyyy, HH:mm"
            />
          </Box>

          <Box w="159px">
            <InfraLabel color="neutral_600">{t('price.fields.multselect.category.label')}</InfraLabel>
            <MultiSelectCategories
              limitTags={1}
              value={categoryId}
              onChange={(data) => {
                handleMultiSelectCategory(data);
              }}
              display="count"
              placeholder={t('price.fields.multselect.category.placeholder')}
            />
          </Box>

          <Box w="159px">
            <InfraLabel color="neutral_600">{t('price.fields.multselect.brands.label')}</InfraLabel>
            <MultiSelectBrands
              limitTags={1}
              value={brandId}
              display="count"
              onChange={(data) => {
                handleMultiSelectBrands(data);
              }}
              placeholder={t('price.fields.multselect.brands.placeholder')}
            />
          </Box>

          <Box>
            <InfraLabel color="neutral_600">{t('programmedPrice.fields.autocomplete.status.label')}</InfraLabel>
            <Box w="159.01px">
              <AutocompleteStatus
                versionId="1"
                typePrice="programmed"
                onChange={handleStatus}
                placeholder={t('programmedPrice.fields.autocomplete.status.placeholder')}
              />
            </Box>
          </Box>

          <Box w="300px">
            <InfraLabel color="neutral_600">{t('price.fields.search.label')}</InfraLabel>
            <InfraTextField
              id="1"
              inputStyle="normal"
              onChange={() => {}}
              onChangeValue={(v) => setDescription(v.target.value)}
              placeholder={t('price.fields.search.placeholder')}
              type="text"
              style={{ height: '45px' }}
            />
          </Box>
        </Flex>
      </Flex>
    );
  };

  const displayCategories = (categories) => {
    if (!categories?.length) {
      return '-';
    }
    return categories?.map((c) => c?.name).join(', ');
  };

  const renderDataTable = () => {
    return (
      <InfraTable value={programmedPrices} loading={loading} messageEmptyData={t('emptyTable')}>
        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'sku.skuId' ? '' : 'sku.skuId')}
          field="skuId"
          header={t('price.fields.table.columnHeaders.skuId')}
          headerAlign="left"
          body={({ skuId }) => skuId || '-'}
        >
          {order === 'sku.skuId' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'product.productId' ? '' : 'product.productId')}
          field="productId"
          header={t('price.fields.table.columnHeaders.productId')}
          headerAlign="left"
          body={({ productId }) => productId || '-'}
        >
          {order === 'product.productId' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'sku.externalSku' ? '' : 'sku.externalSku')}
          field="externalSku"
          header={t('price.fields.table.columnHeaders.externalSku')}
          headerAlign="left"
          body={({ externalSku }) => externalSku || '-'}
        >
          {order === 'sku.externalSku' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'product.name' ? '' : 'product.name')}
          field="product"
          header={t('price.fields.table.columnHeaders.product')}
          headerAlign="left"
          body={({ product, sku }) => <Tooltip text={`${product} - ${sku}`} limit={35} />}
        >
          {order === 'product.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'programmed_price.highPrice' ? '' : 'programmed_price.highPrice')}
          field="highPrice"
          header={t('programmedPrice.fields.table.columnHeaders.highPrice')}
          headerAlign="left"
          body={({ highPrice }) => renderNumber(highPrice)}
        >
          {order === 'programmed_price.highPrice' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'programmed_price.price' ? '' : 'programmed_price.price')}
          field="price"
          header={t('programmedPrice.fields.table.columnHeaders.price')}
          headerAlign="left"
          body={({ price }) => renderNumber(price)}
        >
          {order === 'programmed_price.price' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'brand.name' ? '' : 'brand.name')}
          field="brand"
          header={t('price.fields.table.columnHeaders.brand')}
          headerAlign="left"
          body={({ brand }) => brand || '-'}
        >
          {order === 'brand.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'categories.name' ? '' : 'categories.name')}
          field="categories"
          header={t('price.fields.table.columnHeaders.category')}
          headerAlign="left"
          body={({ categories }) => displayCategories(categories)}
        >
          {order === 'categories.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="seller"
          clickable
          onClick={(_, field) => setOrder(order === 'seller.name' ? '' : 'seller.name')}
          header={t('price.fields.autocomplete.seller.label')}
          headerAlign="left"
          width="200px"
        >
          {order === 'seller.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="period"
          clickable
          onClick={(_, field) => setOrder(order === 'programmed_price.activateAt' ? '' : 'programmed_price.activateAt')}
          header={t('programmedPrice.fields.table.columnHeaders.period')}
          headerAlign="left"
          width="400px"
        >
          {order === 'programmed_price.activateAt' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="status"
          header="Status"
          align="left"
          headerAlign="left"
          body={renderStatus}
          clickable
          onClick={(_, field) => setOrder(order === 'status.id' ? '' : 'status.id')}
        >
          {order === 'status.id' && renderFilterIcon()}
        </InfraTableColumn>
        <InfraTableColumn
          field="priceId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={renderEdit}
        ></InfraTableColumn>
        <InfraTableColumn
          field="priceId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={renderDelete}
        ></InfraTableColumn>
      </InfraTable>
    );
  };

  const renderPagination = () => {
    return (
      <Paginate
        onChangePageSize={handlePageSize}
        onChangePage={handlePagination}
        totalPages={paging?.totalPages}
        currentPage={currentPage}
      />
    );
  };

  return (
    <>
      <InfraGrid>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'31px'}>{renderBreadcrumb()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'32px'}>{renderTitle()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'22px'}>{renderToolbar()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'-12px'}>{renderDataTable()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt="4px" mb="64px">
            {renderPagination()}
          </Box>
        </InfraCol>
      </InfraGrid>
      <WrapperModalProgrammedPrice>
        <InfraModal
          theme="light"
          show={showModalProduct}
          onClose={() => setShowModalProduct(false)}
          title={t('programmedPrice.modais.titles.product')}
        >
          <ModalProduct onCloseModal={(result) => handleOperation(result)} />
        </InfraModal>
      </WrapperModalProgrammedPrice>

      {showModalSKU &&
        <WrapperModalProgrammedPrice>
          <InfraModal
            theme="light"
            show={showModalSKU}
            onClose={handleCloseModal}
            title={typeModal === 'create' ? 'Cadastrar por SKU' : 'Editar por SKU'}
          >
            <ModalSku
              onCloseModal={setShowModalSKU}
              price={programmedPriceSelected}
              cbAlertModal={(result) => {
                handleSuccessModalSku(result);
              }}
            />
          </InfraModal>
        </WrapperModalProgrammedPrice>
      }

      <WrapperModalConfirm>
        <InfraModal
          theme="light"
          show={showModalDeleteProgrammedPrice}
          onClose={() => {
            setShowModalDeleteprogrammedPrice(false);
            setProgrammedPriceSelected(null);
          }}
        >
          <ModalDelete
            onCloseModal={({ result }) => {
              setShowModalDeleteprogrammedPrice(false);
              if (result) {
                refetchProgrammedPrices();
                setContexAlert({ context: 'delete', type: result });
                setShowModalAlert(true);
              }
              setProgrammedPriceSelected(null);
            }}
            price={programmedPriceSelected}
          />
        </InfraModal>
      </WrapperModalConfirm>

      <AlertModal
        variation={contextAlert.type === 'both' ? 'failed' : contextAlert.type}
        message={renderModalAlert()}
        show={showModalAlert}
        onClose={() => setShowModalAlert(false)}
        width="278"
        onConfirmModal={() => setShowModalAlert(false)}
      />

      <AlertModal
        message={renderModalSuccessSku}
        show={showSuccessModalSku}
        onClose={() => setShowSuccessModalSku(false)}
        width="278"
        onConfirmModal={() => setShowSuccessModalSku(false)}
      />
    </>
  );
};

export default ProgrammedPricesComponent;
