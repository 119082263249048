import {
  InfraBreadcrumbs
} from '@infralabs/design-system';
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Box } from "../../components";

const ChargesBreadcrumb: FC = () => {
  const { t } = useTranslation()

  const links = [
    {
      text: t('app'),
      href: '/',
      use: 'link',
    },
    {
      text: t('main'),
      href: '/',
      disabled: false,
    },
    {
      text: t('charge.breadcrumbs.home'),
      disabled: false,
    },
  ];

  return (
      <Box>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </Box>
    );
}

export default ChargesBreadcrumb
