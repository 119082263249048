import { useEffect, useState } from 'react';

import { InfraSelect } from '@infralabs/design-system';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../CurrentAccount';
import { SalesOperatorRepository } from '../../../Repository/SalesOperatorRepository';
import { SalesOperator } from '../../../Model/SalesOperator';
import { SelectItem } from '../../../common/types/select-item';

const rep = new SalesOperatorRepository();

type Props = {
  onChange: (data: any) => void;
  value?: any;
  disable?: boolean;
  channelId?: string
};

export const MultiSelectSalesOperator = ({
  onChange,
  value,
  disable,
  channelId,
}: Props) => {
  const { currentTenant } = useAccount();
  const [items, setItems] = useState<Array<SelectItem>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const isItemSelected = (id) => value?.some((x) => x.value === id);

  const salesOperatorItemAdapter = (data: SalesOperator[]) => data.map(({name, code}) => ({
    label: name,
    value:  code,
    selected: isItemSelected(code),
  }))


  async function getSalesOperators() {
    setLoading(true);
    try {
      const { records } = await rep.setChannelId(channelId).findAll('1');
      const salesOperatorsItems = salesOperatorItemAdapter(records);
      setItems(salesOperatorsItems);
    } catch (error) {
      setItems([]);
    } finally {
      setLoading(false);
    }
  }
  const getSelectedItems = () => items.filter(({selected}) => selected);
  const getSelectedItemsCount = () => getSelectedItems().length

  const  _onChange = (_, updatedItems: Array<SelectItem>) => {
    setItems(updatedItems)
    onChange(getSelectedItems().map(({value}) => ({ value }))); 
  }

  useEffect(() => {
    if (!channelId) return
    getSalesOperators()

  }, [currentTenant, channelId, value])

  return (
    <>
      <InfraSelect
        customTagColor='black'
        disabled={!channelId}
        customColorCheckbox='neutral'
        multiple={true}
        items={items}
        loading={loading}
        handlerClick={_onChange}
        placeholder={t('components.multiselect.salesOperator.placeholder')}
        disable={disable}
        checkbox={true}
        limitTags={1}
        display={"count"}
        customMultiSelectedItemText={t('customMultiSelectedItemText', { count: getSelectedItemsCount() })}
        size={"small"}
        search={true}
        searchPlaceholder={t('components.multiselect.salesOperator.searchPlaceholder')}
      />
    </>
  );
};
