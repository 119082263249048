import { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  InfraButton,
  InfraTag,
  InfraSwitch,
  InfraInputCurrency,
  InfraLabel,
  InfraDatepicker,
  InfraTypography,
} from '@infralabs/design-system';
import {
  WrapperField,
  WrapperSwitchModal,
  WrapperButtonModal,
  AutocompleteSellers,
  Box,
  RowErrorMessage,
  SelectProducts,
} from '../../components';
import { ProgrammedPriceRepository } from '../../Repository/ProgrammedPriceRepository';
import { ResultEnum } from '../../common/enum/result.enum';
import { WrapperMessage } from '../../components/AlertMessage/styles';
import { isDateDiffMinutesAllowed } from '../../Helper';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';

const programmedPriceRepository = new ProgrammedPriceRepository();

const ModalProduct = ({ onCloseModal }) => {
  const [sellerId, setSellerId] = useState('');
  const [product, setProduct] = useState(null);
  const [status, setStatus] = useState(false);
  const { t } = useTranslation();

  const formLabel = {
    createdAt: t('programmedPrice.form.labels.createdAt'),
    costPrice: t('programmedPrice.form.labels.costPrice'),
    price: t('programmedPrice.form.labels.price'),
    highPrice: t('programmedPrice.form.labels.highPrice'),
    product: t('programmedPrice.form.labels.product'),
    seller: t('programmedPrice.form.labels.seller'),
    statusActive: t('programmedPrice.form.labels.statusActive'),
    statusInactive: t('programmedPrice.form.labels.statusInactive'),
    activateAt: t('programmedPrice.form.labels.activateAt'),
    deactivateAt: t('programmedPrice.form.labels.deactivateAt'),
  };

  const formPlaceholder = {
    createdAt: t('programmedPrice.form.placeholders.createdAt'),
    costPrice: t('programmedPrice.form.placeholders.costPrice'),
    price: t('programmedPrice.form.placeholders.price'),
    highPrice: t('programmedPrice.form.placeholders.highPrice'),
    product: t('programmedPrice.form.placeholders.product'),
    seller: t('programmedPrice.form.placeholders.seller'),
    activateAt: t('programmedPrice.form.placeholders.activateAt'),
    deactivateAt: t('programmedPrice.form.placeholders.deactivateAt'),
    searchProduct: t('programmedPrice.form.placeholders.searchProduct'),
  };

  const handleSubmit = async (values) => {
    let price = Number(String(values.price).replace(',', '.'));
    let highPrice = Number(String(values.highPrice).replace(',', '.'));
    let activateAt = values.activateAt;
    let deactivateAt = values.deactivateAt;
    if (highPrice === 0) {
      highPrice = undefined;
    }

    if (activateAt === undefined || activateAt === '' || activateAt.length === 0) {
      activateAt = '';
    }

    if (deactivateAt === undefined || deactivateAt === '' || deactivateAt.length === 0) {
      deactivateAt = '';
    }

    const programmedPrice = [
      ...product.skus.map((sku) => ({
        currency: 986,
        skuId: sku.skuId,
        sellerId,
        price: price,
        highPrice,
        active: status,
        activateAt: activateAt,
        deactivateAt: deactivateAt,
      })),
    ];

    type Result = {
      skuId: string;
      result: string;
    };

    try {
      const { records } = await programmedPriceRepository.setChannelId(values.salesChannel).save<Result>('1', programmedPrice);

      let type = ResultEnum.success;
      let messages;
      const someFailed = records.some(
        (el) => el.result === ResultEnum.failed || el.result === ResultEnum.alreadyExists,
      );

      if (someFailed) {
        type = ResultEnum.both;
        messages = prepareMsgBoth(records);
      }
      onCloseModal({ type, messages });
    } catch (err) {
      let messages;

      if (err.response.data.message) {
        messages = prepareMsgsErrors(err.response.data.message, programmedPrice, 'validator');
      } else {
        messages = prepareMsgsErrors(err.response.data.data['programmed-prices'], programmedPrice, 'internal');
      }
      onCloseModal({ type: 'failed', messages });
    }
  };

  function prepareMsgBoth(prices) {
    return prices.map((el) => ({
      id: el.id,
      sku: el.skuId,
      skuName: el.sku,
      result: el.result,
      error: el.error ?? null,
    }));
  }

  function prepareMsgsErrors(pricesFailedOrMessages: any[], prices, type: 'validator' | 'internal') {
    let errors = [];
    if (type === 'validator') {
      errors = pricesFailedOrMessages.map((error) => {
        const errorSplited = error.split('.');
        const position = parseInt(errorSplited[1]);
        const message = errorSplited[2];
        const price = prices[position];
        return { sku: price.skuId, message };
      });
    } else {
      errors = pricesFailedOrMessages
        .filter((el) => el.result === 'failed')
        .map((el) => ({
          sku: el.skuId,
          skuName: el.sku,
          message: el.error,
        }));
    }
    return errors;
  }

  const minTimeDifference = (hoursInSeconds: number) => new Date(new Date().getTime() + hoursInSeconds);
  const deactivationDateValid = (values) => {
    if (!values.activateAt) {
      return minTimeDifference(1);
    }
    return new Date(new Date(values.activateAt).getTime());
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          product: '',
          seller: '',
          price: '',
          highPrice: '',
          activateAt: '',
          deactivateAt: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          highPrice: Yup.string().notRequired(),
          price: Yup.string().required(t('textValidations.required')),
          seller: Yup.string().required(t('textValidations.required')),
          product: Yup.string().required(t('textValidations.required')),
          activateAt: Yup.string().required(t('textValidations.required')),
          deactivateAt: Yup.string().required(t('textValidations.required')),
        })}
        validate={(values) => {
          const errors: any = {};
          if (values.highPrice !== '') {
            if (Number(String(values.price).replace(',', '.')) > Number(String(values.highPrice).replace(',', '.'))) {
              errors.price = t('programmedPrice.form.textValidations.priceBiggerThen');
            }
          }

          if (values.price && values.price <= 0) {
            errors.price = t('textValidations.invalid');
          }

          if (values.activateAt == '') {
            errors.activateAt = t('textValidations.required');
          }
          if (values.deactivateAt == '') {
            errors.deactivateAt = t('textValidations.required');
          }

          if (isDateDiffMinutesAllowed(values.activateAt, +process.env.PROGRAMMED_DIFFERECE_DATE_LIMIT)) {
            errors.activateAt = t('programmedPrice.form.textValidations.ProgrammedPriceGreaterToday', {
              minutes: process.env.PROGRAMMED_DIFFERECE_DATE_LIMIT,
            });
          }

          if (values.deactivateAt <= values.activateAt) {
            errors.deactivateAt = t('programmedPrice.form.textValidations.ProgrammedPriceGreaterdeActive');
          }

          return errors;
        }}
      >
        {(props) => {
          const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } =
            props;
          return (
            <form onSubmit={handleSubmit}>
              <WrapperField>
                <AutocompleteChannels
                  label="Channels"
                  labelBoxProps={{
                    mt: "24px"
                  }}
                  onChange={(salesChannel) => {
                    setFieldValue('salesChannel', salesChannel.id);
                  }}
                />
              </WrapperField>
              <WrapperField>
                <Box mt="24px">
                  <InfraLabel color="neutral_600">{`${formLabel.seller}`}</InfraLabel>
                </Box>
                <AutocompleteSellers
                  versionId="1"
                  onChange={(sellerId, label) => {
                    setFieldValue('seller', label);
                    setSellerId(sellerId);
                  }}
                  placeholder={formPlaceholder.seller}
                  channelId={values.salesChannel}
                />
                {errors.seller && touched.seller && <RowErrorMessage>{errors.seller}</RowErrorMessage>}
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600">{formLabel.product}</InfraLabel>
                <SelectProducts
                  search={true}
                  value={values.product}
                  onChange={({ value, label }) => {
                    setProduct(value);
                    setFieldValue('product', label);
                  }}
                  placeholder={formPlaceholder.product}
                  searchPlaceholder={formPlaceholder.searchProduct}
                />
                {errors.product && touched.product && <RowErrorMessage>{errors.product}</RowErrorMessage>}
              </WrapperField>
              <div>
                <WrapperField>
                  <InfraLabel color="neutral_600" optional={true}>{`${formLabel.highPrice}`}</InfraLabel>
                  <InfraInputCurrency
                    id="highPrice"
                    placeholder={formPlaceholder.highPrice}
                    decimalSeparator=","
                    inputStyle={errors.highPrice && touched.highPrice ? 'error' : 'normal'}
                    decimalsLimit={2}
                    onValueChange={(value, name) => {
                      setFieldValue('highPrice', value, true);
                    }}
                    message={errors.highPrice && touched.highPrice ? errors.highPrice : ''}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    height="small"
                  />
                </WrapperField>
                <WrapperField>
                  <InfraLabel color="neutral_600">{`${formLabel.price}`}</InfraLabel>
                  <InfraInputCurrency
                    id="price"
                    name="price"
                    placeholder={formPlaceholder.price}
                    decimalSeparator=","
                    decimalsLimit={2}
                    inputStyle={errors.price && touched.price ? 'error' : 'normal'}
                    onValueChange={async (value, name) => {
                      setFieldValue(name, value, true);
                    }}
                    onBlur={handleBlur}
                    message={errors.price && touched.price ? errors.price : ''}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    height="small"
                  />
                </WrapperField>

                <WrapperField>
                  <InfraLabel label="activateAt" theme="light" color="neutral_600">
                    {t('programmedPrice.form.labels.activateAt')}
                  </InfraLabel>
                  <InfraDatepicker
                    height="small"
                    autoComplete={'off'}
                    subtractYears={10}
                    placeholder={t('programmedPrice.fields.filter.period.placeholder')}
                    onChange={async (value, name) => {
                      setFieldValue('activateAt', value, true);
                      setFieldValue('deactivateAt', deactivationDateValid(values), false);
                    }}
                    theme="light"
                    setHours={true}
                    maskDate="dd.MM.yyyy, HH:mm"
                    minDate={minTimeDifference(7200)}
                  />
                  {errors.activateAt && <WrapperMessage>{errors.activateAt}</WrapperMessage>}
                </WrapperField>

                <WrapperField>
                  <InfraLabel label="deactivateAt" theme="light" color="neutral_600">
                    {t('programmedPrice.form.labels.deactivateAt')}
                  </InfraLabel>
                  <InfraDatepicker
                    height="small"
                    autoComplete={'off'}
                    subtractYears={50}
                    placeholder={t('programmedPrice.fields.filter.period.placeholder')}
                    onChange={async (value, name) => {
                      setFieldValue('deactivateAt', value, true);
                    }}
                    theme="light"
                    setHours={true}
                    maskDate="dd.MM.yyyy, HH:mm"
                    minDate={deactivationDateValid(values)}
                  />
                  {errors.deactivateAt && <WrapperMessage>{errors.deactivateAt}</WrapperMessage>}
                </WrapperField>
                <WrapperSwitchModal>
                  <div className="statusStyle">
                    <InfraTypography variant={'h5_small'} weight={'regular'} style={{ marginRight: '9px' }}>
                      Status
                    </InfraTypography>
                    <InfraSwitch onChange={(e, checked) => setStatus(checked)} checked={status} />
                  </div>
                  <div>
                    <InfraTag
                      color={status ? 'active' : 'inactive'}
                      name={status ? formLabel.statusActive : formLabel.statusInactive}
                      outline={false}
                      borde={false}
                    />
                  </div>
                </WrapperSwitchModal>
                <WrapperButtonModal>
                  <InfraButton
                    color="neutral"
                    type="submit"
                    size="large"
                    fullWidth={true}
                    outline={false}
                    disabled={isSubmitting}
                  >
                    {t('programmedPrice.form.button.product')}
                  </InfraButton>
                </WrapperButtonModal>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ModalProduct;
