import { Flex, useAccount } from '../../../components';
import { InfraButton, InfraTypography } from '@infralabs/design-system';
import { TrashSimple } from 'phosphor-react';
import styled from 'styled-components';
import { ImportQueueRepository } from '../../../Repository/ImportQueueRepository';
import { Colors } from '../../../Helper';

const Button = styled(InfraButton)`
  border-color: #fa878f;
  color: #fa878f;
  :hover {
    border-color: #fa878f;
    span {
      color: #fa878f;
    }
  }
`;

export const ModalCanceldImport = ({ onCloseModal, body }) => {
  const { currentChannel } = useAccount()

  async function deleteImport(body) {
    if (!currentChannel) return
    const { guid } = body;
    body.status = 3;
    try {
      const rep = new ImportQueueRepository();
      const result = await rep.setChannelId(currentChannel.id).updateQueueById('1', body, guid);

      if (result) {
        onCloseModal({ result: 'success' });
      }
    } catch (error) {
      onCloseModal({ result: 'failed' });
    }
  }

  return (
    <>
       <Flex gap='20px' direction='column'>
        <Flex justify='center' align='center'>
          <TrashSimple color={'#FA878F'} size={54} />
        </Flex>

        <InfraTypography variant={'h5'} weight={'regular'} color="neutral_600" align="center">
          Você excluirá toda informação? Deseja continuar?
        </InfraTypography>

        <Flex gap='6px' mt="5px">
          <div style={{ width: "50%" }}>
            <Button size="large" fullWidth={true} outline={true} onClick={() => onCloseModal(false)}>
              <InfraTypography variant={'h5'} weight={'bold'} color="error_100">
                Cancelar
              </InfraTypography>
            </Button>
          </div>
          <div style={{ width: "50%" }}>
            <InfraButton
              size="large"
              fullWidth={true}
              outline={false}
              onClick={() => deleteImport(body)}
              style={{ border: '#FA878F', background: Colors.error_100 }}
            >
              <InfraTypography variant={'h5'} weight={'bold'} color="shade_0">
                Sim
              </InfraTypography>
            </InfraButton>
          </div>
        </Flex>
      </Flex>
    </>
  );
};
