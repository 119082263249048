import {
  InfraButton,
  InfraChips,
  InfraIcons,
  InfraInputCurrency,
  InfraLabel,
  InfraModal,
  InfraTextField,
  InfraTooltip,
} from '@infralabs/design-system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getValueValid } from '../../../Helper';
import { ServiceStorage } from '../../../Helper/ServiceStorage';
import { Box, Flex, RowErrorMessage } from '../../../components';
import {
  MultiSelectCategories,
  MultiSelectDeliveriesMode,
  MultiSelectMethodPayments,
  MultiSelectSkus,
  SelectClusters,
} from '../../../components/Selects';
import { MultiSelectSalesPlans } from '../../../components/Selects/MultiSelectSalesPlans';
import * as Styled from './style';

const creditCard = ServiceStorage.getCreditCard();

const ConfigStep = ({ values, touched, errors, handleChange, handleBlur, setFieldValue }: any) => {
  const [valueMethodPayment, setValueMethodPayment] = useState<boolean>(false);
  const [_, setValueSKUs] = useState<boolean>(false);
  const [__, setValueSkusExeception] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [optionCategories, setOptionCategories] = useState([]);
  const [disabledSkus, setDisabledSkus] = useState<boolean>(values.isSkuImports);
  const [showSalesPlans, setShowSalesPlans] = useState<boolean>(!!values?.salesPlans?.length);
  const { t } = useTranslation();

  const formLabels = {
    allowedOnlySkus: t('charge.forms.config.labels.allowedOnlySkus'),
    selectAllSkus: t('charge.forms.config.labels.selectAllSkus'),
    selectAllCategories: t('charge.forms.config.labels.selectAllCategories'),
    selectAllCategoriesTooltip: t('charge.forms.config.labels.selectAllCategoriesTooltip'),
    cluster: t('charge.forms.charge.labels.cluster'),
    none: t('charge.forms.config.labels.none'),
    registerSkus: t('charge.forms.config.labels.registerSkus'),
    importSkus: t('charge.forms.config.labels.importSkus'),
    finish: t('charge.forms.config.labels.finish'),
    category: t('charge.forms.config.labels.category'),
    methodsPayment: t('charge.forms.config.labels.methodsPayment'),
    maxInstallmentsCount: t('charge.forms.config.labels.maxInstallmentsCount'),
    deliveryModes: t('charge.forms.config.labels.deliveryModes'),
    discountValue: t('charge.forms.config.labels.discountValue'),
    discountPercent: t('charge.forms.config.labels.discountPercent'),
    sku: t('charge.forms.config.labels.sku'),
    skuExceptions: t('charge.forms.config.labels.skuExceptions'),
    salesPlans: t('charge.forms.config.labels.salesPlans'),
  };

  const formPlaceholders = {
    category: t('charge.forms.config.placeholders.category'),
    cluster: t('charge.forms.charge.placeholders.cluster'),
    methodsPayment: t('charge.forms.config.placeholders.methodsPayment'),
    maxInstallmentsCount: t('charge.forms.config.placeholders.maxInstallmentsCount'),
    deliveryModes: t('charge.forms.config.placeholders.deliveryModes'),
    discountValue: t('charge.forms.config.placeholders.discountValue'),
    discountPercent: t('charge.forms.config.placeholders.discountPercent'),
    sku: t('charge.forms.config.placeholders.sku'),
    skuExceptions: t('charge.forms.config.placeholders.skuExceptions'),
    salesPlans: t('charge.forms.config.placeholders.salesPlans'),
  };

  const customOptionIsNone = (values.customOption || 'none') === 'none';

  function isDisabledMaxInstallment() {
    if (values.methodsPayment !== undefined && values.methodsPayment.length > 0) {
      return !values.methodsPayment.some((mp) => mp.value === creditCard);
    }
    return true;
  }

  function handleFieldMaxInstallmentsCountMessage() {
    if (errors.maxInstallmentsCount && touched.maxInstallmentsCount) {
      return errors.maxInstallmentsCount;
    } else {
      return '';
    }
  }

  function handleChangeCustomOptins(event) {
    const option = event.target.value as 'none' | 'categories' | 'products';
    setFieldValue('customOption', option, true);
    setFieldValue('isSkuImports', false, true);
    setFieldValue('skuExceptions', [], true);
    setFieldValue('skus', [], true);

    if (option === 'none') {
      setFieldValue('isAllSkus', false, true);
      setFieldValue('isAllCategories', false, true);
      return setFieldValue('categories', [], true);
    }

    if (option === 'products') {
      setFieldValue('isAllCategories', false, true);
      setFieldValue('isAllSkus', true, true);
      return setFieldValue('categories', [], true);
    }

    if (option === 'categories') {
      setFieldValue('isAllSkus', false, true);
      setFieldValue('isAllCategories', true, true);
      setFieldValue('skus', [], true);
      return setFieldValue(
        'categories',
        [
          {
            value: 'all',
          },
        ],
        true,
      );
    }
  }

  const getLabelRegisterSkus = () => {
    if (!values.isSkuImports || !customOptionIsNone || !values.skus?.length) return <>{formLabels.registerSkus}</>;
    return (
      <>
        {formLabels.registerSkus} ({values.skus?.length})
      </>
    );
  };

  return (
    <Flex gap="43px" direction="column">
      <Styled.BoxRadio>
        <div>
          <input
            type="radio"
            onChange={handleChangeCustomOptins}
            id="products"
            name="typeAll"
            value="products"
            checked={values.customOption === 'products'}
          />
          <label htmlFor="products">{formLabels.selectAllSkus}</label>
        </div>

        <Styled.Categories>
          <input
            type="radio"
            onChange={handleChangeCustomOptins}
            id="categories"
            name="typeAll "
            value="categories"
            checked={values.customOption === 'categories'}
          />
          <label htmlFor="categories">{formLabels.selectAllCategories}</label>
          <InfraTooltip theme="dark" position="top" message={formLabels.selectAllCategoriesTooltip}>
            <InfraIcons name="Info" />
          </InfraTooltip>
        </Styled.Categories>

        <div>
          <input
            type="radio"
            onChange={handleChangeCustomOptins}
            id="none"
            name="typeAll"
            value="none"
            checked={customOptionIsNone || values.customOption === 'none'}
          />
          <label htmlFor="none">{formLabels.none}</label>
        </div>
      </Styled.BoxRadio>
      <Flex gap="24px">
        <Box w="270px">
          <InfraLabel color="neutral_600">{formLabels.category}</InfraLabel>
          <MultiSelectCategories
            search={true}
            value={values.categories}
            channelId={localStorage.getItem('ifc-channel-id')}
            limitTags={1}
            disable={values.skus?.length || !customOptionIsNone}
            onChange={(data) => {
              setFieldValue('categories', data, true);
            }}
            onFetch={(categories) => setOptionCategories(categories)}
            placeholder={formPlaceholders.category}
            display="count"
          />
          {errors.categories && <RowErrorMessage>{errors.categories}</RowErrorMessage>}
        </Box>
        <Box w="270px">
          <InfraLabel color="neutral_600">{formLabels.cluster}</InfraLabel>
          <SelectClusters
            multiple={true}
            search={false}
            value={values.clusters}
            channelId={localStorage.getItem('ifc-channel-id')}
            limitTags={1}
            onChange={(data) => {
              const selected = data.map((cluster) => ({
                label: cluster.label,
                value: cluster.value,
                selected: true,
                id: cluster.value,
              }));
              setFieldValue('clusters', selected, true);
            }}
            placeholder={formPlaceholders.cluster}
            display="count"
          />
        </Box>
        <Box w="270px">
          <InfraLabel color="neutral_600">{formLabels.methodsPayment}</InfraLabel>
          <MultiSelectMethodPayments
            value={valueMethodPayment ? values.methodsPayment : []}
            limitTags={1}
            onChange={(items: Array<any>) => {
              const creditCardItem = items.find((item) => item.value === creditCard);
              if (creditCardItem) {
                if (creditCardItem.selected) {
                  setFieldValue('maxInstallmentsCount', '', true);
                }
              }

              setShowSalesPlans(!!items.find((item) => item.value === 'CREDIT_LIMIT' && item.selected === true));

              setFieldValue('methodsPayment', items, true);
            }}
            onFetch={() => {
              setValueMethodPayment(true);
            }}
            placeholder={formPlaceholders.methodsPayment}
            disable={!values.chargeTypeId || [1, 2].includes(values.chargeTypeId)}
          />
          {errors.methodsPayment && <RowErrorMessage>{errors.methodsPayment}</RowErrorMessage>}
        </Box>
        {showSalesPlans && (
          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.salesPlans}</InfraLabel>
            <MultiSelectSalesPlans
              value={values.salesPlans}
              channel={localStorage.getItem('ifc-channel-id')}
              limitTags={1}
              onChange={(items: Array<any>) => {
                setFieldValue('salesPlans', items, true);
              }}
              placeholder={formPlaceholders.salesPlans}
            />
            {errors.salesPlans && <RowErrorMessage>{errors.salesPlans}</RowErrorMessage>}
          </Box>
        )}
        <Box w="268.01px">
          <InfraLabel color="neutral_600">{formLabels.maxInstallmentsCount}</InfraLabel>
          <InfraTextField
            id="maxInstallmentsCount"
            name="maxInstallmentsCount"
            disabled={isDisabledMaxInstallment()}
            placeholder={formPlaceholders.maxInstallmentsCount}
            type="number"
            min={'0'}
            max={'24'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.maxInstallmentsCount || ''}
            inputStyle={errors.maxInstallmentsCount && touched.maxInstallmentsCount ? 'error' : 'normal'}
            message={handleFieldMaxInstallmentsCountMessage()}
            height="small"
          ></InfraTextField>
        </Box>
        <Box w="270px">
          <InfraLabel color="neutral_600">{formLabels.deliveryModes}</InfraLabel>
          <MultiSelectDeliveriesMode
            limitTags={1}
            value={values.deliveryModes}
            onChange={(data) => {
              setFieldValue('deliveryModes', data, true);
            }}
            placeholder={formPlaceholders.deliveryModes}
            disable={!values.chargeTypeId || [1, 3].includes(values.chargeTypeId)}
          />
          {errors.deliveryModes && <RowErrorMessage>{errors.deliveryModes}</RowErrorMessage>}
        </Box>
      </Flex>
      <Flex gap="24px">
        <Box w="270px">
          <InfraLabel color="neutral_600">{formLabels.discountValue}</InfraLabel>
          <InfraInputCurrency
            placeholder={formPlaceholders.discountValue}
            decimalSeparator=","
            inputStyle={errors.discountValue ? 'error' : 'normal'}
            decimalsLimit={2}
            onValueChange={(value, name) => {
              setFieldValue('discountValue', getValueValid(value), true);
              setFieldValue('discountPercent', '', true);
            }}
            value={values.discountValue || ''}
            message={errors.discountValue ? errors.discountValue : ''}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            height="small"
            disabled={values.discountPercent && values.discountPercent !== '' ? true : false}
          />
        </Box>

        <Box w="270px">
          <InfraLabel color="neutral_600">{formLabels.discountPercent}</InfraLabel>
          <InfraTextField
            id="discountPercent"
            name="discountPercent"
            onChange={handleChange}
            placeholder={formPlaceholders.discountPercent}
            value={values.discountPercent || ''}
            onBlur={handleBlur}
            disabled={values.discountValue && values.discountValue !== '' ? true : false}
            inputStyle={errors.discountPercent ? 'error' : 'normal'}
            message={errors.discountPercent ? errors.discountPercent : ''}
            height="small"
          ></InfraTextField>
        </Box>
      </Flex>
      <Flex gap="24px">
        <Box w="564px">
          <InfraLabel color="neutral_600">{formLabels.sku}</InfraLabel>
          <MultiSelectSkus
            search={true}
            limitTags={1}
            value={values.isSkuImports ? [] : values.skus}
            channelId={localStorage.getItem('ifc-channel-id')}
            disable={disabledSkus || Boolean(values.categories?.length) || !customOptionIsNone}
            onChange={(data) => {
              setFieldValue('skuExceptions', [], true);
              setFieldValue('skus', data, true);
            }}
            onFetch={() => setValueSKUs(true)}
            placeholder={formPlaceholders.sku}
            display="count"
          />
          {errors.skus && <RowErrorMessage>{errors.skus}</RowErrorMessage>}
        </Box>
        <Box w="564px">
          <InfraLabel color="neutral_600">{formLabels.skuExceptions}</InfraLabel>
          <MultiSelectSkus
            search={true}
            value={values.skuExceptions}
            channelId={localStorage.getItem('ifc-channel-id')}
            onChange={(data) => {
              setFieldValue('skuExceptions', data);
            }}
            limitTags={1}
            placeholder={formPlaceholders.skuExceptions}
            categories={values.isAllCategories ? optionCategories : values.categories}
            onFetch={() => setValueSkusExeception(true)}
            display="count"
            disable={
              (values.skus && values.skus.length > 0) ||
              (values.categories && values.categories.length === 0) ||
              values.categories === undefined
                ? true
                : false
            }
          />
        </Box>
      </Flex>
      <Flex>
        <InfraButton
          color="neutral"
          outline={false}
          icon="Plus"
          type="button"
          iconRight={true}
          disabled={(values.skus?.length && !values.isSkuImports) || !customOptionIsNone}
          onClick={() => setOpen(true)}
        >
          {getLabelRegisterSkus()}
        </InfraButton>
        <InfraModal
          show={open}
          onClose={(event) => {
            if (event.target.nodeName !== 'svg') return;
            setOpen(false);
            setFieldValue('skus', []);
            setDisabledSkus(false);
          }}
        >
          <Styled.ModalTitle className="title-modal">
            {formLabels.importSkus}
            <InfraTooltip theme="dark" message={formLabels.allowedOnlySkus}>
              <InfraIcons name="Info" />
            </InfraTooltip>
          </Styled.ModalTitle>
          <Box mt="16px">
            <InfraChips
              values={
                values.skus?.map(({ value }) => ({
                  text: value,
                })) || []
              }
              onInserted={(chip, chips) => {
                const newChips = [chip, ...chips];
                const currentChips = [];
                newChips?.forEach(({ text }) => {
                  currentChips.push(...text.trim().split(' '));
                });
                setFieldValue(
                  'skus',
                  currentChips.map((value) => ({ value })),
                );
                setFieldValue('isSkuImports', true);
                setDisabledSkus(true);
              }}
              onRemoved={(chip, chips) => {
                const currentSkus = chips?.filter(({ text }) => chip.text !== text);
                const hasCurrent = Boolean(currentSkus?.length);
                setFieldValue(
                  'skus',
                  currentSkus.map(({ text }) => ({
                    value: text,
                  })),
                );
                setDisabledSkus(hasCurrent);
                setFieldValue('isSkuImports', hasCurrent);
              }}
            />
            <Box mt="16px">
              <InfraButton type="button" onClick={() => setOpen(false)} color="neutral" outline={false} fullWidth>
                {formLabels.finish}
              </InfraButton>
            </Box>
          </Box>
        </InfraModal>
      </Flex>
    </Flex>
  );
};

export default ConfigStep;
