import { Prices } from '../Model/Prices';
import { API } from '../Services/api';
import { BaseRepository } from './BaseRepository';

export class PriceExportRepository extends BaseRepository<Prices, typeof Prices.prototype.id> {
  name = 'exports/prices';

  async getExportPrices(versionId: string, body: any): Promise<any> {
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? body.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    const response = await API.post(this.name, body, {
      responseType: 'arraybuffer',
      headers: {
        ...headers,
      },
    });
    return response;
  }
}
