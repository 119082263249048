import { InfraSelect } from '@infralabs/design-system';
import { useEffect, useState } from 'react';
import { Category } from '../../../Model/Category';
import { CategoryRepository } from '../../../Repository/CategoryRepository';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../CurrentAccount';

const categoryRepository = new CategoryRepository();

type SelectCategoryProp = {
  onChange: (data: any) => void;
  value?: any;
  placeholder?: string;
  disable?: boolean;
  limitTags?: number;
  display?: 'count' | 'chip';
  size?: 'small' | 'medium' | 'large';
  search?: boolean;
  onFetch?: (categories: any[]) => void;
  channelId?: string;
};

export const MultiSelectCategories = ({
  onChange,
  onFetch,
  placeholder,
  value,
  disable,
  limitTags = 2,
  display = 'count',
  size = 'small',
  search = false,
  channelId,
}: SelectCategoryProp) => {
  const { currentTenant, currentChannel } = useAccount();
  const { t } = useTranslation();
  const [items, setItems] = useState<{ label: string; value: number; selected?: boolean }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function prepareItemsSelect(categories: Category[]) {
    const options = categories.map((category: Category) => ({
      label: category.name,
      value: category.id,
      selected: value?.some((x) => x.value === category.id),
    }));

    setItems(options);
    return options
  }

  function itemsSelected() {
    return items.filter((item) => item.selected === true);
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { records } = await categoryRepository.setChannelId(currentChannel.id).findAll('1');
        const items = prepareItemsSelect(records);
        onFetch?.(items)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setItems([]);
      }
    })();
  }, [currentTenant, currentChannel]);

  return (
    <>
      <InfraSelect
        customTagColor="black"
        customColorCheckbox="neutral"
        multiple={true}
        items={items}
        loading={loading}
        handlerClick={onChange}
        placeholder={placeholder}
        disable={disable}
        checkbox={true}
        limitTags={limitTags}
        display={display}
        customMultiSelectedItemText={t('customMultiSelectedItemText', { count: itemsSelected().length })}
        size={size}
        search={search}
        searchPlaceholder={t('price.fields.multselect.skus.searchPlaceholder')}
      />
    </>
  );
};
