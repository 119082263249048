import React, { useEffect, useState, createContext, useContext } from 'react';
import { KEY_CHANNEL_ID } from '../../constants/localStorageKeys';
import { AccountRepository } from '../../Repository';
import { getTenant, getToken } from '../../Services/api';
import { ChannelContextType, ProviderProps, TenantType } from './types';

export const CurrentChannelContext = createContext<ChannelContextType>(null);
const tenantId = getTenant();
const token = getToken();
let initialTenant;
if (tenantId && token) {
  initialTenant = {
    id: tenantId,
    channelName: 'Teste',
  };

  localStorage.setItem(
    'pricing',
    JSON.stringify({
      ...initialTenant,
      tenantId: initialTenant.id,
    }),
  );
}

export const CurrentChannelProvider: React.FC = ({ children, tokenIdSubject }: ProviderProps) => {
  const [currentTenant, setCurrentTenant] = useState<TenantType>(initialTenant);
  const [currentChannel, setCurrentChannel] = useState();

  useEffect(() => {
    const subscription = tokenIdSubject.subscribe((tokenObj) => {
      syncChannel(tokenObj.store);
    });
    if (subscription) {
      return () => subscription.unsubscribe();
    }
  }, [tokenIdSubject]);

  const changeCurrentChannel = (channel) => {
    if (!channel) return;
    localStorage.setItem(KEY_CHANNEL_ID, channel?.id || channel);
    setCurrentChannel(channel);
  };

  const syncChannel = async (token: any) => {
    const accountRepository = new AccountRepository();
    if (!token.id) return;
    const response = await accountRepository.getFirstChannel('1', token.id).catch(() => null);
    if (!response) return;
    const storage = {
      tenantId: token.id,
      channelName: response.accounts[0].name,
    };

    localStorage.setItem('pricing', JSON.stringify(storage));
    changeCurrentChannel(response.accounts[0]);
    setCurrentTenant(token);
  };

  const stateValues = {
    currentTenant,
    currentChannel,
    changeCurrentChannel,
  };

  return <CurrentChannelContext.Provider value={stateValues}>{children}</CurrentChannelContext.Provider>;
};

export const useAccount = () => {
  const context = useContext(CurrentChannelContext);

  return context;
};

export default CurrentChannelContext;
