export function createLinkAndDownload(fileData: any, filename: string) {
  const url = window.URL.createObjectURL(new Blob([fileData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    filename
  );
  document.body.appendChild(link);
  link.click();
}
