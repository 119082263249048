import {
  InfraTable,
  InfraTableColumn,
  InfraIcons,
  InfraTag,
} from '@infralabs/design-system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusEnum } from '../../common';
import { FormatCurrency } from '../../Helper';
import { StatusColor } from '../../common/utils/statusColor';
import { useHistory } from 'react-router-dom';

type PromotionTableProps = {
  promotions: any
  loading: any
  order: any
  onChangeOrder: any
  onRemove: any
}

const getFirstChannelId = (data) => {
  return data?.channels?.[0].id
}

const PromotionFilterIcon = () => {
  return (
    <span style={{ marginInline: '10px' }}>
      <InfraIcons name="SortDescending" size={16} weight="bold" />
    </span>
  )
}

type PromotionColumnDiscountProps = {
  discountValue: any;
  discountPercentage: any;
}

const PromotionColumnDiscount: FC<PromotionColumnDiscountProps> = (props) => {
    const { discountValue, discountPercentage } = props
    if (!discountValue) return <>{`${Number(discountPercentage)}%`}</>
    return  <span>{FormatCurrency.format('BRL', discountValue)}</span>;
}

type PromotionStatusProps = {
  rowData: any
}

const PromotionStatus: FC<PromotionStatusProps> = (props) => {
  const { rowData } = props
  const { t } = useTranslation();
  if (
    (rowData.isActive == false && rowData.status.status == StatusEnum.pending) ||
    (rowData.isActive == false && rowData.status.status == StatusEnum.current)
  ) {
    return (
      <InfraTag
        color="inactive"
        name={t('scheduledPrice.form.labels.statusInactive')}
        outline={false}
        borde={false}
      />
    );
  } else {
    let statusColor = new StatusColor();
    return (
      <InfraTag
        color={statusColor.status[rowData.status.status]}
        name={
          rowData.status
            ? t('programmedPrice.fields.status.status.' + rowData.status.status)
            : t('programmedPrice.fields.status.status.pending')
        }
      >
        {' '}
      </InfraTag>
    );
  }
};

type PromotionColumnProps = {
  promotionData?: any
}

const PromotionDetail: FC<PromotionColumnProps> = (props) => {
  const { promotionData } = props
  const firstChannelId = getFirstChannelId(promotionData)
  let history = useHistory();
  const goToDetail = () => {
    history.push(`/promotion/${promotionData.id}/details?channelId=${firstChannelId}`);
  }

  return (
    <a onClick={goToDetail}>
      <InfraIcons name="Eye" size={16} weight="bold" />
    </a>
  );
};

const PromotionEdit: FC<PromotionColumnProps> = (props) => {
  const { promotionData } = props
  const isPromotionPending = promotionData.status.status === StatusEnum.pending;
  let history = useHistory();
  const goToEdit = () => {
    const firstChannelId = getFirstChannelId(promotionData)
    history.push('/promotions/' + promotionData.id + '/edit?channelId=' + firstChannelId);
  }

  if (!isPromotionPending) return null

  return (
    <a onClick={goToEdit}>
      <InfraIcons name="PencilSimple" size={16} weight="bold" />
    </a>
  );
};

const PromotionDelete: FC<PromotionColumnProps & {
  onClick: any
}> = (props) => {
  const { onClick, promotionData} = props
  const handleClick = () => {
    onClick(promotionData)
  }

  if (promotionData.status.status == StatusEnum.pending || promotionData.status.status === StatusEnum.current) {
    return (
      <a onClick={handleClick}>
        <InfraIcons name="TrashSimple" size={16} weight="bold" />
      </a>
    );
  } else {
    return null
  }
};

const PromotionCopy: FC<PromotionColumnProps> = (props: any) => {
  const { promotionData } = props
  let history = useHistory();
  const goToClone = () => {
    const firstChannelId = getFirstChannelId(promotionData)
    history.push(`/promotions/new?copy=${promotionData.id}&channelId=${firstChannelId}`);
  }
  return (
    <a onClick={goToClone}>
      <InfraIcons name="Copy" size={16} weight="bold" />
    </a>
  );
};

const PromotionTable: FC<PromotionTableProps> = (props) => {
  const { promotions, loading, order, onChangeOrder, onRemove } = props
  const { t } = useTranslation();

   return (
      <InfraTable value={promotions} loading={loading} messageEmptyData={t('emptyTable')}>
        <InfraTableColumn
          clickable
          onClick={() => onChangeOrder(order === 'promotions.name' ? '' : 'promotions.name')}
          field="name"
          header={t('promotion.table.columnHeaders.promotion')}
          headerAlign="left"
        >
          {order === 'promotions.name' && <PromotionFilterIcon/>}
        </InfraTableColumn>
        <InfraTableColumn
          clickable
          onClick={() => onChangeOrder(order === 'promotions.code' ? '' : 'promotions.code')}
          field="code"
          header={t('promotion.table.columnHeaders.code')}
          headerAlign="left"
        >
          {order === 'promotions.code' && <PromotionFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={() => onChangeOrder(order === 'promotions.discountValue' ? '' : 'promotions.discountValue')}
          field="discountValue"
          header={t('promotion.table.columnHeaders.discount')}
          headerAlign="left"
          body={({ discountValue, discountPercentage }) => {
            return <PromotionColumnDiscount discountValue={discountValue} discountPercentage={discountPercentage}/>
          }}
        >
          {order === 'promotions.discountValue' && <PromotionFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={() => onChangeOrder(order === 'promotionType.name' ? '' : 'promotionType.name')}
          field="typeName"
          header={t('promotion.table.columnHeaders.type')}
          headerAlign="left"
        >
          {order === 'promotionType.name' && <PromotionFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          field="sellerName"
          clickable
          onClick={() => onChangeOrder(order === 'seller.name' ? '' : 'seller.name')}
          header={t('promotion.table.columnHeaders.seller')}
          headerAlign="left"
        >
          {order === 'seller.name' && <PromotionFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          field="period"
          clickable
          onClick={() => onChangeOrder(order === 'promotions.activateAt' ? '' : 'promotions.activateAt')}
          header={t('promotion.table.columnHeaders.period')}
          headerAlign="left"
        >
          {order === 'promotions.activateAt' && <PromotionFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          field="status"
          header="Status"
          align="left"
          headerAlign="left"
          body={(rowData) => <PromotionStatus rowData={rowData}/>}
          clickable
          onClick={() => onChangeOrder(order === 'status.id' ? '' : 'status.id')}
        >
          {order === 'status.id' && <PromotionFilterIcon/>}
        </InfraTableColumn>
        <InfraTableColumn
          field="promotionId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(rowData) => <PromotionDetail promotionData={rowData}/>}
        ></InfraTableColumn>
        <InfraTableColumn
          field="promotionId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(promotionData) => <PromotionEdit promotionData={promotionData}/>}
        ></InfraTableColumn>
        <InfraTableColumn
          field="promotionId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(promotionData) => <PromotionDelete onClick={onRemove} promotionData={promotionData}/>}
        ></InfraTableColumn>
        <InfraTableColumn
          field="promotionId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(promotionData) =>  <PromotionCopy promotionData={promotionData} />}
        ></InfraTableColumn>
      </InfraTable>
    );
}

export default PromotionTable
