import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfraSelect } from '@infralabs/design-system';

import { MethodPaymentRepository } from '../../../Repository/MethodPaymentRepository';
import { MethodPayment } from '../../../Model/MethodPayment';
import CurrentChannelContext from '../../CurrentAccount';

const methodPaymentRepository = new MethodPaymentRepository();

type SelectMethodPaymentProp = {
  onChange: (data: any) => void;
  channel?: string;
  onFetch?: () => void;
  value?: any;
  placeholder?: string;
  disable?: boolean;
  limitTags?: number;
  display?: 'count' | 'chip',
  size?: 'small' | 'medium' | 'large';
}

export const MultiSelectMethodPayments = ({ onChange, channel, placeholder, value, disable, onFetch, limitTags = 2, display= 'chip', size = 'small'}: SelectMethodPaymentProp) => {
  const { currentTenant, currentChannel } = useContext(CurrentChannelContext);
  const [items, setItems] = useState<{ label: string, value: string, selected?: boolean }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  function prepareItemsSelect(data: MethodPayment[]) {
    const options = data.map((el: MethodPayment) => ({
      label: el.name,
      value: el.id,
      selected: value?.find(x => x.value === el.id) ? true : false
    }));
    setItems(options)
    onFetch();
  }

  function itemsSelected() {
    return items.filter((item) => item.selected === true)
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { records } = await methodPaymentRepository.setChannelId(currentChannel.id).findAll('1');
        prepareItemsSelect(records);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setItems([]);
      }   
    })();
  }, [currentTenant, currentChannel])

  useEffect(() => {
    const options = items.map((el) => ({
      ...el,
      selected: value?.find((item) => item.value === el.value ) ? true : false
    }));
    setItems(options);
  }, [value])

  return (
    <>
      <InfraSelect
        customTagColor='black'
        customColorCheckbox='neutral'
        multiple={true}
        items={items}
        loading={loading}
        handlerClick={onChange}
        placeholder={placeholder}
        disable={disable}
        checkbox={true}
        limitTags={limitTags}
        display={display}
        customMultiSelectedItemText={t('customMultiSelectedItemText', { count: itemsSelected().length })}
        size={size}
      />
    </>
  );
}
