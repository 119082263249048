import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './Locale/en/translation.json';
import translationES from './Locale/es/translation.json';
import translationPTBR from './Locale/pt_BR/translation.json';

const fallbackLng = ['pt-BR'];
const availableLanguages = ['en', 'es', 'pt-BR'];

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  'pt-BR': {
    translation: translationPTBR,
  },
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true,
      lookupLocalStorage: 'lng',
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

i18n.languages = availableLanguages;

export default i18n;
