import { useContext, useEffect, useState } from 'react';
import { InfraSelect } from '@infralabs/design-system';
import { useTranslation } from 'react-i18next';
import { DeliveryModeRepository } from '../../../Repository/DeliveryModeRepository';
import { DeliveryMode } from '../../../Model/DeliveryMode';
import CurrentChannelContext from '../../CurrentAccount';


const DeliveriesRepository = new DeliveryModeRepository();

type SelectDeliveriesProp = {
  onChange: (data: any) => void;
  channel?: string;
  value?: any;
  placeholder?: string;
  disable: boolean;
  limitTags?: number;
  display?: 'count' | 'chip';
  size?: 'small' | 'medium' | 'large';  
}

export const MultiSelectDeliveriesMode = ({ onChange, channel, placeholder, value, disable, limitTags = 2, display = 'count', size = 'small' }: SelectDeliveriesProp) => {
  const { currentTenant, currentChannel } = useContext(CurrentChannelContext);
  const [items, setItems] = useState<{ label: string, value: number, selected?: boolean }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  function prepareItemsSelect(data: DeliveryMode[]) {
    const options = data.map((el: DeliveryMode) => ({
      label: el.name,
      value: el.id,
      selected: value?.find(x => x.value === el.id) ? true : false
    }));
    setItems(options)
  }

  function itemsSelected() {
    return items.filter((item) => item.selected === true)
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { records } = await DeliveriesRepository.setChannelId(currentChannel.id).findAll('1');
        prepareItemsSelect(records);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setItems([]);
      }
    })();
  }, [currentTenant, currentChannel])

  return (
    <>
      <InfraSelect
        customTagColor='black'
        customColorCheckbox='neutral'
        multiple={true}
        items={items}
        loading={loading}
        handlerClick={onChange}
        placeholder={placeholder}
        disable={disable}
        checkbox={true}
        limitTags={limitTags}
        display={display}
        customMultiSelectedItemText={t('customMultiSelectedItemText', { count: itemsSelected().length})}
        size={size}
      />
    </>
  );
}

