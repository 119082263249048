import { Prices } from '../Model/Prices';
import { API } from '../Services/api';
import { BaseRepository } from './BaseRepository';

export class PriceRepository extends BaseRepository<Prices, typeof Prices.prototype.id> {
  name = 'prices';

  uploadPrices(formData, onUploadProgress) {
    const url = this.name + '/uploads';
    const { tenantId } = this.getValuesStorage();
    const channelId = formData.get('channelId')
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? channelId;
    const headers = this.getHeaders(tenant, channel, '1');
    return API.post(url, formData, {
      headers,
      onUploadProgress,
    });
  }

  getTemplatePriceImport(importTypeId: number) {
    const url = `imports/template/download/` + importTypeId;
    const { tenantId, channelId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? channelId;
    const headers = this.getHeaders(tenant, channel, '1');
    return API.get(url, {
      responseType: 'arraybuffer',
      headers
    });
  }
}
