import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InfraLabel,
  InfraTextField,
  InfraTag,
  InfraSwitch,
  InfraFieldSet
} from '@infralabs/design-system';
import SelectDevices from '../../../components/SelectDevices';
import { Box, Flex  } from '../../../components'; 
import { MultiSelectSalesOperator } from '../../../components/Selects/MultiSelectSalesOperator';

const OriginStep = ({ values, touched, errors, handleChange, handleBlur, setFieldValue, controller }: any) => {
  const { isActiveController } = controller;
  const [isActive, setActive] = isActiveController;
  const { t } = useTranslation();
  const [valueDeviceId, setValueDeviceId] = useState<boolean>(false);
  const channelId = values.channel;

  const formLabels = {
    device: t('promotion.forms.origin.labels.device'),
    salesOperators: t('promotion.forms.origin.labels.sellerCodes'),
    term: t('promotion.forms.origin.labels.term'),
    campaign: t('promotion.forms.origin.labels.campaign'),
    medium: t('promotion.forms.origin.labels.medium'),
    source: t('promotion.forms.origin.labels.source'),
    status: t('promotion.forms.origin.labels.status'),
    statusYes: t('promotion.forms.origin.labels.statusYes'),
    statusNo: t('promotion.forms.origin.labels.statusNo'),
  };
  const formPlaceholders = {
    device: t('promotion.forms.origin.placeholders.device'),
    term: t('promotion.forms.origin.placeholders.term'),
    campaign: t('promotion.forms.origin.placeholders.campaign'),
    source: t('promotion.forms.origin.placeholders.source'),
    medium: t('promotion.forms.origin.placeholders.medium'),
  };

  return (
    <Flex justify="start" align="start" gap="30px" direction='column'>
      <InfraFieldSet legend='UTMs'>
        <Flex gap='20px' mt='0rem'>
          <Flex gap='20px'>
            <Box w='270px'>
              <InfraLabel color="neutral_600">{formLabels.term}</InfraLabel>
              <InfraTextField
                id="utmTerm"
                name="utmTerm"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.utmTerm || ''}
                placeholder={formPlaceholders.term}
                inputStyle={errors.utmTerm && touched.utmTerm ? 'error' : 'normal'}
                message={errors.utmTerm && touched.utmTerm ? errors.utmTerm : ''}
                height="small"
              />
            </Box>
            <Box w='270px'>
              <InfraLabel color="neutral_600">{formLabels.campaign}</InfraLabel>
              <InfraTextField
                placeholder={formPlaceholders.campaign}
                id="utmCampaign"
                name="utmCampaign"
                value={values.utmCampaign || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                inputStyle={errors.utmCampaign && touched.utmCampaign ? 'error' : 'normal'}
                message={errors.utmCampaign && touched.utmCampaign ? errors.utmCampaign : ''}
                height="small"
              />
            </Box>
          </Flex>
          <Flex gap='20px'>
            <Box w='270px'>
              <InfraLabel color="neutral_600">{formLabels.medium}</InfraLabel>
              <InfraTextField
                id="utmMedium"
                name="utmMedium"
                placeholder={formPlaceholders.medium}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.utmMedium || ''}
                inputStyle={errors.utmMedium && touched.utmMedium ? 'error' : 'normal'}
                message={errors.utmMedium && touched.utmMedium ? errors.utmMedium : ''}
                height="small"
              ></InfraTextField>
            </Box>
            <Box w='270px'>
              <InfraLabel color="neutral_600">{formPlaceholders.source}</InfraLabel>
              <InfraTextField
                id="utmSource"
                name="utmSource"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.utmSource || ''}
                inputStyle={errors.utmSource && touched.utmSource ? 'error' : 'normal'}
                message={errors.utmSource && touched.utmSource ? errors.utmSource : ''}
                placeholder={formPlaceholders.source}
                height="small"
              ></InfraTextField>
            </Box>
          </Flex>
        </Flex>
      </InfraFieldSet>

      <Flex gap='20px'>
        <Box w='270px'>
          <InfraLabel color="neutral_600">{formLabels.device}</InfraLabel>
          <SelectDevices
            onChange={(value) => {
              setFieldValue('deviceId', value);
            }}
            channel={values?.channel || ''}
            value={valueDeviceId ? values.deviceId : ''}
            placeholder={formPlaceholders.device}
            onFetch={() => setValueDeviceId(true)}
          />
        </Box>

        <Box w="514px">
          <InfraLabel color="neutral_600">{ formLabels.salesOperators }</InfraLabel>
           <MultiSelectSalesOperator
            channelId={channelId}
            disable={values.code}
            value={values.salesOperators} onChange={(selectedSalesOperators) => {
              setFieldValue('salesOperators', selectedSalesOperators)
            }}/>
        </Box>
        <Box mt="15px">
          <Flex
            w="152px"
            justify='space-between'
            align='center'
            mt='1rem'
            gap="12px"
          >
            <Box mt="6px" mr="-0.25rem">
              <InfraLabel color="neutral_600">{formLabels.status}:</InfraLabel>
            </Box>
            <div>
              <InfraSwitch onChange={(e, checked) => setActive(checked)} checked={isActive} />
            </div>
            <div style={{ position: 'relative' }}>
              <InfraTag
                color={isActive ? 'active' : 'inactive'}
                name={isActive ? formLabels.statusYes : formLabels.statusNo}
                outline={false}
                border={false}
              />
            </div>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default OriginStep;
