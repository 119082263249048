import { InfraButton, InfraLabel, InfraTypography, InfraUpload } from '@infralabs/design-system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ImportTypeEnum } from '../../../common/enum/import-type.enum';
import { AutocompleteSellers, Box, Flex, SelectImportTypes } from '../../../components';
import { AutocompleteChannels } from '../../../components/AutocompleteChannels';
import ClustersSelect from '../../../components/Selects/ClientClusters';
import { createLinkAndDownload } from '../../../Helper/download';
import { PriceRepository } from '../../../Repository';

const InfraButtonStyled = styled(InfraButton)`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding-inline: 17px;
  padding-block: 12.5px;
`;

const InfraUploadStyled = styled.div`
  div {
    min-width: 270px;
    div {
      min-width: 10px;
      p {
        word-wrap: break-word;
        font-weight: 400;
        line-height: 18px;
        margin-left: -2px;
      }
    }
    ul {
      width: 270px;
    }
  }
`;

const priceRepository = new PriceRepository();

export const ModalImportPrices = ({
  onCloseModal,
  onClosed: notBlockModal,
}: {
  onCloseModal: () => void;
  onClosed: (value) => any;
}) => {
  const [seller, setSeller] = useState('');
  const [salesChannel, setSalesChannel] = useState(localStorage.getItem('ifc-channel-id'));
  const [importType, setImportType] = useState<number>();
  const [clusterId, setClusterId] = useState<string>();
  const [files, setFiles] = useState([]);
  const [uploadList, setUploadList] = useState([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const { t } = useTranslation();

  function createFormUploadFile(file: File): FormData {
    const form = new FormData();
    form.append('channelId', salesChannel);
    form.append('sellerId', seller);
    form.append('importTypeId', String(importType));
    form.append('currency', '986');
    form.append('file', file);

    if (clusterId !== undefined) {
      form.append('clusterId', String(clusterId));
    }

    return form;
  }

  const handleOnDeleteFileUpload = (data) => {
    setUploadList([...uploadList.filter((_, index) => data.index !== index)]);
  };

  const handleDownloadTemplate = async () => {
    const response = await priceRepository.getTemplatePriceImport(Number(importType));
    const fileNameMap = new Map<ImportTypeEnum, string>([
      [ImportTypeEnum.PRODUCT, 'template_product.xlsx'],
      [ImportTypeEnum.SKU, 'template_sku.xlsx'],
      [ImportTypeEnum.EXTERNAL_SKU, 'template_skuexterno.xlsx'],
    ]);
    const fileName = fileNameMap.get(importType);

    createLinkAndDownload(response.data, fileName);
  };

  const onSendFiles = async (e) => {
    notBlockModal(false);
    setUploading(true);
    for (const index in uploadList) {
      const file = files[index];
      if (file.executed) {
        continue;
      }
      const formData = createFormUploadFile(file);
      const uploaded = uploadList;
      try {
        const result = await priceRepository.setChannelId(salesChannel).uploadPrices(formData, (eventProgress) => {
          const percentage = Math.round((100 * eventProgress.loaded) / eventProgress.total);
          uploaded[index].progress = percentage;
          uploaded[index].executed = true;
          setUploadList([...uploaded]);
        });
        if (result.statusText === 'Created') {
          uploaded[index].uploaded = true;
        }
      } catch (error) {
        const uploaded = uploadList;
        uploaded[index].uploaded = false;
        uploaded[index].error = true;
        uploaded[index].errorMessage =
          error?.response?.data?.message || t('importPrice.modalUpload.upload.defaultMessageError');
      } finally {
        setUploadList([...uploaded]);
      }
    }
    setUploading(false);
    notBlockModal(true);
  };

  const loadFile = (filesLoaded) => {
    const uploadedList = uploadList;
    setFiles([...files, ...filesLoaded]);
    for (const file of filesLoaded) {
      uploadedList.push({
        name: file.name,
        progress: 0,
        type: 'application/xlsx',
        uploaded: false,
        error: false,
        executed: false,
      });
    }
    setUploadList([...uploadedList]);
  };

  const allFileUploaded = () => {
    const allItemsCompleted = uploadList.every((item) => item.executed === true && item.progress === 100);
    return uploadList.length > 0 && allItemsCompleted;
  };

  const disableButtonUpload = (disabled) => {
    return disabled || !importType || uploadList.length === 0 || !seller || uploading;
  };

  return (
    <>
      <Box>
        {/** TODO: Increase Logic to save or edit */}
        <InfraLabel color="neutral_600">Channels</InfraLabel>
        <AutocompleteChannels
          onChange={(salesChannel) => {
            setSalesChannel(salesChannel.id);
          }}
        />
      </Box>
      <Box>
        <InfraLabel color="neutral_600">{t('importPrice.modalUpload.fields.seller.label')}</InfraLabel>
        <AutocompleteSellers
          onChange={(data) => setSeller(data)}
          versionId="1"
          channelId={localStorage.getItem('ifc-channel-id')}
          placeholder={t('importPrice.modalUpload.fields.seller.placeholder')}
        />
      </Box>
      <Box>
        <InfraLabel color="neutral_600">{t('importPrice.modalUpload.fields.clusters.label')}</InfraLabel>
        <ClustersSelect
          placeholder={t('importPrice.modalUpload.fields.clusters.placeholder')}
          value={clusterId}
          onChange={({ value }) => {
            setClusterId(value);
          }}
        />
      </Box>
      <Box>
        <InfraLabel color="neutral_600">{t('importPrice.modalUpload.fields.importType.label')}</InfraLabel>
        <SelectImportTypes
          placeholder={t('importPrice.modalUpload.fields.importType.placeholder')}
          onChange={({ value }) => {
            setImportType(value);
          }}
        />
      </Box>
      <InfraUploadStyled>
        <InfraUpload
          title={t('importPrice.modalUpload.upload.title')}
          direction="horizontal"
          description={t('importPrice.modalUpload.upload.description')}
          uploadMessageDefault={t('importPrice.modalUpload.upload.uploadMessageDefault')}
          filesTypesAccept="application/.xlsx, application/.xlsx, xlsx, .xlsx"
          uploadMessageReject={t('importPrice.modalUpload.upload.uploadMessageReject')}
          uploadMessageRejectMaxFiles={t('importPrice.modalUpload.upload.uploadMessageRejectMaxFiles')}
          uploadMessageRejectTypeInvalid={t('importPrice.modalUpload.upload.uploadMessageRejectTypeInvalid')}
          onUpload={loadFile}
          progressColor="neutral"
          uploadedList={uploadList}
          onClickSendFiles={(e) => onSendFiles(e)}
          maxFiles={5}
          renderCustomButtonUpload={(props) => {
            return !allFileUploaded() ? (
              <Flex gap={'8px'} mt="33px">
                <InfraButtonStyled
                  width={'114px'}
                  outline={false}
                  color="neutral"
                  iconLeft={true}
                  onClick={props.onClickSendFiles}
                  load={props.loading}
                  disabled={disableButtonUpload(props.disabled)}
                >
                  {t('importPrice.modalUpload.buttons.upload')}
                </InfraButtonStyled>

                <InfraButtonStyled
                  width={'148px'}
                  color="neutral"
                  disabled={!importType}
                  onClick={handleDownloadTemplate}
                >
                  {t('importPrice.modalUpload.buttons.downloadTemplate')}
                </InfraButtonStyled>
              </Flex>
            ) : (
              <InfraButtonStyled outline={false} color="neutral" fullWidth={true} onClick={() => onCloseModal()}>
                {t('importPrice.modalUpload.buttons.finish')}
              </InfraButtonStyled>
            );
          }}
          setDisabled={() => {}}
          handleDeleteUploadledFiles={(event, data) => {
            handleOnDeleteFileUpload(data);
          }}
        />
      </InfraUploadStyled>
      {importType && !allFileUploaded() && (
        <Box mt="-12px" zIndex={1000}>
          <InfraTypography variant="text_footer" color="neutral_400">
            {t('importPrice.modalUpload.upload.templateMessage')}
          </InfraTypography>
        </Box>
      )}
    </>
  );
};
