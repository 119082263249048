import { Container, Wrapper } from './styles';

export function BoxErrorMessage(props) {
  return (
    <>
      <Wrapper>
        <Container>{props.children}</Container>
      </Wrapper>
    </>
  );
}
