import {
  InfraDatepicker,
  InfraLabel,
  InfraSwitch,
  InfraTag,
  InfraTextarea,
  InfraTextField,
} from '@infralabs/design-system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KEY_CHANNEL_ID } from '../../../constants/localStorageKeys';

import { AutocompleteSellers, Box, Flex, RowErrorMessage, SelectChargeTypes, useAccount } from '../../../components';

const ChargeStep = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  controller,
  isEdit = false,
}: any) => {
  const { sellerController, isActiveController } = controller;
  const [sellerId, setSellerId] = sellerController;
  const [isActive, setIsActive] = isActiveController;
  const { t } = useTranslation();
  const { changeCurrentChannel } = useAccount();

  useEffect(() => {
    changeCurrentChannel(localStorage.getItem('ifc-channel-id'));
    localStorage.setItem(KEY_CHANNEL_ID, localStorage.getItem('ifc-channel-id'));
  }, []);
  const [valueChargeType, setValueChargeType] = useState<boolean>(false);

  const formLabels = {
    seller: t('charge.forms.charge.labels.seller'),
    cluster: t('charge.forms.charge.labels.cluster'),
    activateAt: t('charge.forms.charge.labels.activateAt'),
    deactivateAt: t('charge.forms.charge.labels.deactivateAt'),
    chargeType: t('charge.forms.charge.labels.chargeType'),
    name: t('charge.forms.charge.labels.name'),
    status: t('charge.forms.charge.labels.status'),
    statusActive: t('charge.forms.charge.labels.statusActive'),
    statusInactive: t('charge.forms.charge.labels.statusInactive'),
    description: t('charge.forms.charge.labels.description'),
  };
  const formPlaceholders = {
    seller: t('charge.forms.charge.placeholders.seller'),
    cluster: t('charge.forms.charge.placeholders.cluster'),
    activateAt: t('charge.forms.charge.placeholders.activateAt'),
    deactivateAt: t('charge.forms.charge.placeholders.deactivateAt'),
    chargeType: t('charge.forms.charge.placeholders.chargeType'),
    description: t('charge.forms.charge.placeholders.description'),
    name: t('charge.forms.charge.placeholders.name'),
  };

  const handleFieldNameMessage = () => {
    if (errors.name && touched.name) {
      return errors.name;
    }
    return t('charge.forms.charge.messages.max').replace(':max', '150');
  };

  const renderSellerInput = () => {
    if (!isEdit) {
      return (
        <AutocompleteSellers
          versionId="1"
          defaultValue={values.seller}
          onChange={(seller, label) => {
            setFieldValue('seller', label);
            setSellerId(seller);
          }}
          placeholder={formPlaceholders.seller}
          channelId={localStorage.getItem('ifc-channel-id')}
        />
      );
    }
    return <InfraTextField height="small" disabled={true} value={values.seller} />;
  };

  return (
    <>
      <Flex gap="64px" direction="column">
        <Flex gap="45px">
          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.seller}</InfraLabel>
            {renderSellerInput()}
            {errors.seller && touched.seller && <RowErrorMessage>{errors.seller}</RowErrorMessage>}
          </Box>

          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.activateAt}</InfraLabel>
            <InfraDatepicker
              id="activateAt"
              name="activateAt"
              autoComplete={'off'}
              value={values.activateAt}
              setHours={true}
              shouldCloseOnSelect={false}
              onChange={(value) => {
                setFieldValue('activateAt', value);
              }}
              placeholder={formPlaceholders.activateAt}
              height="small"
              maskDate="dd.MM.yyyy, HH:mm"
            />
            {errors.activateAt && <RowErrorMessage>{errors.activateAt}</RowErrorMessage>}
          </Box>
          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.deactivateAt}</InfraLabel>
            <InfraDatepicker
              id="deactivateAt"
              name="deactivateAt"
              autoComplete={'off'}
              value={values.deactivateAt}
              setHours={true}
              shouldCloseOnSelect={false}
              placeholder={formPlaceholders.deactivateAt}
              onChange={(value) => setFieldValue('deactivateAt', value)}
              height="small"
              maskDate="dd.MM.yyyy, HH:mm"
            />
            {errors.deactivateAt && <RowErrorMessage>{errors.deactivateAt}</RowErrorMessage>}
          </Box>
        </Flex>

        <Flex gap="45px">
          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.chargeType}</InfraLabel>
            <SelectChargeTypes
              onChange={(data) => {
                setFieldValue('chargeTypeId', data.value, true);
                if (values.deliveryModes !== undefined) {
                  setFieldValue('deliveryModes', [], true);
                }

                if (values.methodsPayment !== undefined) {
                  setFieldValue('methodsPayment', [], true);
                }
              }}
              onFetch={() => {
                setValueChargeType(true);
              }}
              value={valueChargeType ? values.chargeTypeId || [] : []}
              placeholder={formPlaceholders.chargeType}
              channelId={localStorage.getItem('ifc-channel-id')}
            />
            {errors.chargeTypeId && <RowErrorMessage>{errors.chargeTypeId}</RowErrorMessage>}
          </Box>

          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.name}</InfraLabel>
            <InfraTextField
              id="name"
              name="name"
              placeholder={formPlaceholders.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              inputStyle={errors.name && touched.name ? 'error' : 'normal'}
              message={handleFieldNameMessage()}
              height="small"
            />
          </Box>

          <Flex w="320px" justify="space-between" align={'center'} mt={'1rem'} gap="13px">
            <Box mt="5px" mr="-13px">
              <InfraLabel color="neutral_600">{formLabels.status}:</InfraLabel>
            </Box>
            <div>
              <InfraSwitch
                onChange={(e, checked) => {
                  setIsActive(checked);
                }}
                checked={isActive || false}
              />
            </div>
            <Box pos="relative">
              <InfraTag
                color={isActive ? 'active' : 'errorDefault'}
                name={isActive ? formLabels.statusActive : formLabels.statusInactive}
                outline={false}
                border={false}
              />
            </Box>
          </Flex>
        </Flex>

        <Flex gap="20px" mt="-20px">
          <div style={{ flex: '1' }}>
            <InfraLabel color="neutral_600">{formLabels.description}</InfraLabel>
            <InfraTextarea
              id="description"
              value={values.description}
              name="description"
              placeholder={formPlaceholders.description}
              onChange={handleChange}
              icon={false}
              style={{ height: '178px' }}
            >
              {values.description}
            </InfraTextarea>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default ChargeStep;
