export class StatusColor {
  public status: any = {
    pending: 'inactive',
    current: 'active',
    expired: 'change',
    canceled: 'errorDefault',
    processing: 'processing',
  };
}
// change the above class to enumerator
export enum StatusColorEnumerator {
  pending = 'inactive',
  current = 'active',
  expired = 'change',
  canceled = 'errorDefault',
  processing = 'processing',
}
