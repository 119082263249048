import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfraSelect, InfraPagination, InfraTypography } from '@infralabs/design-system';

import { Box, Flex } from '../Layout';

interface PaginateProps {
  currentPage: number; 
  totalPages: number;
  onChangePageSize: (pageSize: number | any) => void 
  onChangePage: (event: any, page: number | any) => void;
}

const paginationOption = [
  {
    label: '10',
    value: 10,
    selected: true,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '40',
    value: 40,
  },
  {
    label: '50',
    value: 50,
  },
];

export const Paginate = ({ currentPage, totalPages ,onChangePageSize, onChangePage }: PaginateProps) => {
  const [page, setPage] = useState(currentPage);
  const [count, setCount] = useState(totalPages);

  const { t } = useTranslation();

  useEffect(() => {
    setPage(currentPage);
    setCount(totalPages);
  }, [currentPage, totalPages])

  return (
    <Flex justify="space-between" wrap='wrap'>
      <Flex direction='row' align='center' >
        <InfraTypography variant="h5" weight="bold">{t('paginate.linesPerPage')}:</InfraTypography>
        <Box ml="8px" w="73px">
          <InfraSelect
            size="small"
            disable={false}
            placeholder={'10'}
            search={false}
            handlerClick={({ value }) => onChangePageSize(value)}
            items={paginationOption}
          ></InfraSelect>
        </Box>
      </Flex>
      <Flex
        direction='row'
        justify='end'
        align='center'
        grow='0'
        shrink='1'
        basis='auto'
      >
        <div>
          <InfraPagination
            color="neutral"
            onChange={(event, value) => onChangePage(event, value)}
            defaultPage={1}
            count={count}
            page={page}
            customtheme="light"
            shape="rounded"
          >
            {' '}
          </InfraPagination>
        </div>
      </Flex>
    </Flex>
  );
}

