import axios, { AxiosRequestConfig } from 'axios';


const baseURL = `${window.IFC_VARIABLES.baseURL}/pricing/admin`;

export const getTenant = () => window?.IFC_VARIABLES?.headers?.['x-tenant-id'];

export const getToken = () => `Bearer ${window?.IFC_VARIABLES?.auth.token}`;

export const getApiKey = () => window?.IFC_VARIABLES?.headers['api-key'];

const API = axios.create({
  baseURL: baseURL
});

if (process.env.STANDALONE) {
  API.interceptors.request.use(authRequestIntercept)
}

if (window?.IFC_VARIABLES?.auth.token) {
  API.interceptors.request.use(authRequestIntercept)
}

function authRequestIntercept(req: AxiosRequestConfig<any>) {
  req.headers['Authorization'] = getToken();
  req.headers['X-Tenant-Id'] = getTenant();
  req.headers['api-key'] = getApiKey();
  // req.headers['X-Channel-Id'] = process.env.CHANNEL_ID;
  return req;
}
const CancelToken = axios.CancelToken
export { API, CancelToken };
