import useDidMountEffect from '../../../hooks/use-did-mount-effect';
import { useDebounce } from '../../../hooks/debounce';
import { memo, useContext, useState } from 'react';
import { InfraSelect } from '@infralabs/design-system';
import { SkuRepository } from '../../../Repository';
import { Skus } from '../../../Model';
import CurrentChannelContext, { useAccount } from '../../CurrentAccount';

const skuRepository = new SkuRepository();
type SelectSkusProp = {
  onChange: (data: any) => void;
  value?: any;
  placeholder?: string;
  disable?: boolean;
  categories?: any[];
  size?: 'small' | 'medium' | 'large';
  search?: boolean;
  searchPlaceholder?: string;
  channelId?: string | boolean;
  sellerId?: string | boolean;
};

const PriceSelectSkus = ({
  onChange,
  placeholder,
  value,
  disable,
  size = 'small',
  search = false,
  searchPlaceholder = '',
  channelId,
  sellerId,
}: SelectSkusProp) => {
  const { currentTenant, currentChannel } = useAccount();
  const [items, setItems] = useState<{ label: string; value: string; selected?: boolean }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>();

  function prepareItemsSelect(data: Skus[]) {
    const options = data.map((sku: Skus) => ({
      label: `${sku.productName} - ${sku.name}(${sku.externalSku || '-'})`,
      value: sku.skuId,
      selected: value && value === sku.skuId ? true : false,
    }));
    setItems(options);
  }

  const fetch = async () => {
    if (!inputSearch) return setItems([]);
    setLoading(true);
    try {
      const { records } = await skuRepository
        .setChannelId(channelId ?? currentChannel.id)
        .findAll('1', { search: inputSearch, ...(!!sellerId && { sellerId }) });
      prepareItemsSelect(records);
    } catch (error) {
      setItems([]);
    } finally {
      setLoading(false);
    }
  };

  useDidMountEffect(() => {
    if (!channelId || !sellerId) {
      if (!!currentChannel?.id) return;
    }
    fetch();
  }, [currentTenant, currentChannel, channelId, sellerId]);

  useDebounce(() => {
    fetch();
  }, [inputSearch]);

  async function searchByCriteria(searchCriteria) {
    if (!searchCriteria) {
      return;
    }
    setInputSearch(searchCriteria);
  }

  return (
    <>
      <InfraSelect
        customTagColor="black"
        customColorCheckbox="neutral"
        size={size}
        items={items}
        loading={loading}
        handlerClick={onChange}
        placeholder={placeholder}
        disable={disable}
        search={search}
        searchPlaceholder={searchPlaceholder}
        handlerSearch={searchByCriteria}
      />
    </>
  );
};

export const SelectSkus = memo(PriceSelectSkus);
