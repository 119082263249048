import { InfraSelect } from '@infralabs/design-system';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Clusters } from '../../../Model/Clusters';
import { ClusterRepository } from '../../../Repository/ClusterRepository';
import CurrentChannelContext from '../../CurrentAccount';

const clusterRepository = new ClusterRepository();

type SelectClusterProp = {
  onChange: (data: any) => void;
  multiple?: boolean;
  value?: any;
  channelId?: string;
  placeholder?: string;
  disable?: boolean;
  limitTags?: number;
  display?: 'count' | 'chip';
  size?: 'small' | 'medium' | 'large';
  search?: boolean;
  showDefaultOption?: boolean;
  onFetch?: (item) => void;
};

export const SelectClusters = ({
  onChange,
  onFetch,
  multiple = false,
  placeholder,
  value,
  channelId,
  disable,
  limitTags = 2,
  display = 'count',
  size = 'small',
  search = false,
  showDefaultOption = false,
}: SelectClusterProp) => {
  const { currentTenant } = useContext(CurrentChannelContext);
  const { t } = useTranslation();
  const [items, setItems] = useState<{ label: string; value: string; selected?: boolean }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function prepareItemsSelect(clusters: Clusters[]) {
    const options = clusters.map((cluster: Clusters) => ({
      label: cluster.name,
      value: cluster.id,
      selected: value?.some((x) => x.value === cluster.id) ? true : false,
    }));

    setItems(options);
  }

  function itemsSelected() {
    return items.filter((item) => item.selected === true);
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      const data = await clusterRepository.find(channelId).catch(() => []);
      prepareItemsSelect(data.clusters);
      setLoading(false);
    })();
  }, [currentTenant]);

  return (
    <>
      <InfraSelect
        customTagColor="black"
        customColorCheckbox="neutral"
        multiple={multiple}
        items={items}
        loading={loading}
        handlerClick={onChange}
        placeholder={placeholder}
        disable={disable}
        limitTags={limitTags}
        display={display}
        customMultiSelectedItemText={t('customMultiSelectedItemText', { count: itemsSelected().length })}
        size={size}
        search={search}
        searchPlaceholder={t('price.fields.multselect.skus.searchPlaceholder')}
      />
    </>
  );
};
