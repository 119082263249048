import React from 'react';
import { InfraButton, InfraIcons, InfraModal, InfraTypography } from '@infralabs/design-system';
import * as S from './style';
import Colors from '../../../../Helper/Colors';
import { Box } from '../../../../components/Layout/Box';

interface AlertReportProps {
  show: boolean;
  title: string;
  subtitle: string;
  children: React.ReactNode;
  onConfirmModal?: () => void;
  onClose: any;
}

const AlertReport = ({ children, title, subtitle, show, onClose, onConfirmModal }: AlertReportProps) => {
  return (
    <S.WrapperModal >
      <InfraModal show={show} width={'600'} onClose={onClose}>
        <S.Content>
          <S.Icon>
            <InfraIcons size={54} name="Info" color={Colors.information_100} />
          </S.Icon>
          <S.Titles>
            <InfraTypography variant={'h4'} weight={'semibold'} align="center" color="neutral_600">{title}</InfraTypography>
            <InfraTypography variant={'h5'} weight={'regular'} align="center" color="neutral_400">{subtitle}</InfraTypography>
          </S.Titles>
          <S.ContentScrollable>
            <S.Scrollable>
              <Box h="292px" w="300px">
                {children}
              </Box>
            </S.Scrollable>
          </S.ContentScrollable>
          <S.ContentButton>
            <Box w="272px">
              <InfraButton 
                fullWidth={true}
                onClick={onConfirmModal}
                >OK</InfraButton>
            </Box>
          </S.ContentButton>
        </S.Content>
      </InfraModal>
    </S.WrapperModal>
  );
};

export default AlertReport;