import * as S from './style';

import { InfraButton, InfraIcons, InfraModal } from '@infralabs/design-system';

import { Colors } from '../../Helper';
import { Info } from 'phosphor-react';
import styled from 'styled-components';

type AlertModalProp = {
  variation?: 'success' | 'failed';
  message: any;
  show: boolean;
  onClose: any;
  width?: '278' | '350' | '450' | '600' | '700' | '800' | '900' | '960' | undefined;
  onConfirmModal?: () => void;
};

const WrapperAlert = styled.div`
  .modal-body {
    padding: 4px 1.844rem 2.5rem 1.844rem;
  }
  
`;

export function AlertModal({
  variation = 'success',
  message,
  show,
  onClose,
  width = '350',
  onConfirmModal,
}: AlertModalProp) {
  function getColorVariation() {
    switch (variation) {
      case 'success':
        return Colors.success_100;
      case 'failed':
        return Colors.information_100;
    }
  }

  function renderIcon() {
    return variation === 'success' ? (
      <InfraIcons name="CheckCircle" color={getColorVariation()} size={65} />
    ) : (
      <Info color={getColorVariation()} size={65} />
    );
  }

  function renderMessage() {
    if (typeof message === 'function') {
      return <S.WrapperMessage>{message()}</S.WrapperMessage>;
    } else {
      return <S.TextMessage>{message}</S.TextMessage>;
    }
  }

  return (
    <WrapperAlert>
      <InfraModal show={show} onClose={onClose} width={width}>
        <div>
          <S.IconContainer>
            <S.WrapperIcon>
              <span>{renderIcon()}</span>
            </S.WrapperIcon>
          </S.IconContainer>
          <S.ContentContainer>{renderMessage()}</S.ContentContainer>
          <div>
            <S.WrapperButton>
              <InfraButton
                color={variation === 'success' ? 'success' : 'error'}
                style={{ background: getColorVariation(), borderColor: getColorVariation() }}
                size="large"
                onClick={onConfirmModal}
                outline={false}
                fullWidth={true}
              >
                OK
              </InfraButton>
            </S.WrapperButton>
          </div>
        </div>
      </InfraModal>
    </WrapperAlert>

  );
}
