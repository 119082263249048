import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfraSelect } from '@infralabs/design-system';
import { ImportType } from '../../Model';
import { ImportTypeRepository } from '../../Repository';
import { useAccount } from '../CurrentAccount';

const importTypeRepository = new ImportTypeRepository();
type SelectImportTypeProps = {
  onChange: (data: any) => void;
  value?: any;
  placeholder?: string;
  onFetched?: () => void;
  optionFilterDefault?: boolean;
  disable?: boolean;
};

const PriceSelectImportTypes = ({
  onChange,
  placeholder,
  value,
  onFetched,
  optionFilterDefault = false,
  ...rest
}: SelectImportTypeProps) => {
  const { currentTenant, currentChannel } = useAccount();
  const { t } = useTranslation();
  const [items, setItems] = useState<{ label: string; value: number; selected?: boolean; disabled?: true }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);


  function prepareItemsSelect(data: ImportType[]) {
    const options = data.map((el: ImportType) => ({
      label: t(`importTypes.${el.name}`),
      value: el.id,
      selected: value === el.id ? true : false,
    }));
    if (optionFilterDefault) {
      setItems([{ label: t('optionFilterDefault'), value: -1 }, ...options]);
    } else {
      setItems(options);
    }
    const onFetchedCallback = onFetched || null;
    onFetchedCallback?.();
  }

  useEffect(() => {
    if (!currentChannel || !currentTenant) return
    setLoading(true);
    (async () => {
      try {
        const { records } = await importTypeRepository.setChannelId(currentChannel.id).findAll('1');
        prepareItemsSelect(records);
      } catch (error) {
        setItems([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentTenant, currentChannel]);

  useEffect(() => {
    const options = items.map((item) => ({
      ...item,
      selected: value == item.value ? true : false,
    }));
    setItems(options);
  }, [value]);

  return (
    <InfraSelect
      items={items}
      loading={loading}
      handlerClick={onChange}
      placeholder={placeholder}
      size={'small'}
      {...rest}
    />
  );
};

export const SelectImportTypes = memo(PriceSelectImportTypes)
