import {
  InfraIcons, InfraTable,
  InfraTableColumn, InfraTag
} from '@infralabs/design-system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StatusEnum } from '../../common';
import { StatusColor } from '../../common/utils/statusColor';
import { FormatCurrency } from '../../Helper';

type ChargeTableProps = {
  charges: any
  loading: any
  order: any
  onChangeOrder: any
  onRemove: any
}

const getFirstChannelId = (data) => {
  return data?.channels?.[0].id
}

const ChargeFilterIcon = () => {
  return (
    <span style={{ marginInline: '10px' }}>
      <InfraIcons name="SortDescending" size={16} weight="bold" />
    </span>
  )
}

type ChargeColumnadditionProps = {
  discountValue: any;
  discountPercentage: any;
}

const ChargeColumnaddition: FC<ChargeColumnadditionProps> = (props) => {
    const { discountValue, discountPercentage } = props
    if (!discountValue) return <>{`${Number(discountPercentage)}%`}</>
    return  <span>{FormatCurrency.format('BRL', discountValue)}</span>;
}

type ChargeStatusProps = {
  rowData: any
}

const ChargeStatus: FC<ChargeStatusProps> = (props) => {
  const { rowData } = props
  const { t } = useTranslation();
  if (
    (rowData.isActive == false && rowData.status.status == StatusEnum.pending) ||
    (rowData.isActive == false && rowData.status.status == StatusEnum.current)
  ) {
    return (
      <InfraTag
        color="inactive"
        name={t('scheduledPrice.form.labels.statusInactive')}
        outline={false}
        borde={false}
      />
    );
  } else {
    let statusColor = new StatusColor();
    return (
      <InfraTag
        color={statusColor.status[rowData.status.status]}
        name={
          rowData.status
            ? t('programmedPrice.fields.status.status.' + rowData.status.status)
            : t('programmedPrice.fields.status.status.pending')
        }
      >
        {' '}
      </InfraTag>
    );
  }
};

type ChargeColumnProps = {
  chargeData?: any
}

const ChargeDetail: FC<ChargeColumnProps> = (props) => {
  const { chargeData } = props
  const firstChannelId = getFirstChannelId(chargeData)
  let history = useHistory();
  const goToDetail = () => {
    history.push(`charge/${chargeData.id}/details?channelId=${firstChannelId}`);
  }

  return (
    <a onClick={goToDetail}>
      <InfraIcons name="Eye" size={16} weight="bold" />
    </a>
  );
};

const ChargeEdit: FC<ChargeColumnProps> = (props) => {
  const { chargeData } = props
  const isChargePending = chargeData.status.status === StatusEnum.pending;
  let history = useHistory();
  const goToEdit = () => {
    const firstChannelId = getFirstChannelId(chargeData)
    history.push('charges/' + chargeData.id + '/edit?channelId=' + firstChannelId);
  }

  if (!isChargePending) return null

  return (
    <a onClick={goToEdit}>
      <InfraIcons name="PencilSimple" size={16} weight="bold" />
    </a>
  );
};

const ChargeDelete: FC<ChargeColumnProps & {
  onClick: any
}> = (props) => {
  const { onClick, chargeData} = props
  const handleClick = () => {
    onClick(chargeData)
  }

  if (chargeData.status.status == StatusEnum.pending || chargeData.status.status === StatusEnum.current) {
    return (
      <a onClick={handleClick}>
        <InfraIcons name="TrashSimple" size={16} weight="bold" />
      </a>
    );
  } else {
    return null
  }
};

const ChargeCopy: FC<ChargeColumnProps> = (props: any) => {
  const { chargeData } = props
  let history = useHistory();
  const goToClone = () => {
    const firstChannelId = getFirstChannelId(chargeData)
    history.push(`/charges/new?copy=${chargeData.id}&channelId=${firstChannelId}`);
  }
  return (
    <a onClick={goToClone}>
      <InfraIcons name="Copy" size={16} weight="bold" />
    </a>
  );
};

const ChargeTable: FC<ChargeTableProps> = (props) => {
  const { charges, loading, order, onChangeOrder, onRemove } = props
  const { t } = useTranslation();

   return (
      <InfraTable value={charges} loading={loading} messageEmptyData={t('emptyTable')}>
        <InfraTableColumn
          clickable
          onClick={() => onChangeOrder(order === 'charges.name' ? '' : 'charges.name')}
          field="name"
          header={t('charge.table.columnHeaders.charge')}
          headerAlign="left"
        >
          {order === 'charges.name' && <ChargeFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={() => onChangeOrder(order === 'charges.discountValue' ? '' : 'charges.discountValue')}
          field="discountValue"
          header={t('charge.table.columnHeaders.addition')}
          headerAlign="left"
          body={({ discountValue, discountPercentage }) => {
            return <ChargeColumnaddition discountValue={discountValue} discountPercentage={discountPercentage}/>
          }}
        >
          {order === 'charges.discountValue' && <ChargeFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={() => onChangeOrder(order === 'chargeType.name' ? '' : 'chargeType.name')}
          field="typeName"
          header={t('charge.table.columnHeaders.type')}
          headerAlign="left"
        >
          {order === 'chargeType.name' && <ChargeFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          field="sellerName"
          clickable
          onClick={() => onChangeOrder(order === 'seller.name' ? '' : 'seller.name')}
          header={t('charge.table.columnHeaders.seller')}
          headerAlign="left"
        >
          {order === 'seller.name' && <ChargeFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          field="period"
          clickable
          onClick={() => onChangeOrder(order === 'charges.activateAt' ? '' : 'charges.activateAt')}
          header={t('charge.table.columnHeaders.period')}
          headerAlign="left"
        >
          {order === 'charges.activateAt' && <ChargeFilterIcon/>}
        </InfraTableColumn>

        <InfraTableColumn
          field="status"
          header="Status"
          align="left"
          headerAlign="left"
          body={(rowData) => <ChargeStatus rowData={rowData}/>}
          clickable
          onClick={() => onChangeOrder(order === 'status.id' ? '' : 'status.id')}
        >
          {order === 'status.id' && <ChargeFilterIcon/>}
        </InfraTableColumn>
        <InfraTableColumn
          field="chargeId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(rowData) => <ChargeDetail chargeData={rowData}/>}
        ></InfraTableColumn>
        <InfraTableColumn
          field="chargeId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(chargeData) => <ChargeEdit chargeData={chargeData}/>}
        ></InfraTableColumn>
        <InfraTableColumn
          field="chargeId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(chargeData) => <ChargeDelete onClick={onRemove} chargeData={chargeData}/>}
        ></InfraTableColumn>
        <InfraTableColumn
          field="chargeId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(chargeData) =>  <ChargeCopy chargeData={chargeData} />}
        ></InfraTableColumn>
      </InfraTable>
    );
}

export default ChargeTable
