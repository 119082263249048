import {
  InfraBreadcrumbs,
  InfraCol,
  InfraGrid,
  InfraIcons,
  InfraLabel,
  InfraModal,
  InfraTable,
  InfraTableColumn,
  InfraTag,
  InfraTextField,
  InfraTooltip,
  InfraTypography,
} from '@infralabs/design-system';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Colors, FormatCurrency } from '../../Helper';
import { PriceRepository } from '../../Repository/PriceRepository';
import { StatusColor } from '../../common/utils/statusColor';
import {
  AlertModal,
  AutocompleteSellers,
  AutocompleteStatus,
  Box,
  Button,
  CurrentChannelContext,
  Flex,
  MultiSelectCategories,
  Paginate,
  SelectClusters,
} from '../../components';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';
import { AutocompleteClusters } from '../../components/AutocompleteCluster';
import useDidMountEffect from '../../hooks/use-did-mount-effect';
import { WrapperModal, WrapperModalConfirm } from '../styles/style-wrapper-modal';
import ModalDeletePrice from './modalDeletePrice';
import ModalProduct from './modalProduct';
import ModalSku from './modalSku';
import usePricesPaginate from './usePricesPaginate';

const priceRepository = new PriceRepository();
const PricesComponent = ({ locationUrl = '' }: { locationUrl: string }) => {
  const { t } = useTranslation();
  const [seller, setSeller] = useState('');
  const [channel, setChannel] = useState('');
  const [status, setStatus] = useState('');
  const [clusterIds, setClustersIds] = useState([
    {
      label: 'Default',
      value: 'default',
      selected: true,
    },
  ]);
  const [pageSize, setPageSize] = useState(10);
  const [description, setDescription] = useState('');
  const [showModalProduct, setShowModalProduct] = useState(false);
  const [showModalSKU, setShowModalSKU] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [categoryId, setStateCategories] = useState([]);
  const [contextAlert, setContexAlert] = useState<{
    context: string;
    type: 'success' | 'failed' | 'both';
    messages?: any;
  }>({ context: '', type: 'success' });
  const [showModalDeletePrice, setShowModalDeletePrice] = useState(false);
  const [order, setOrder] = useState('status.id');
  const [priceSelected, setPriceSelected] = useState(null);
  const [typeModal, setTypeModal] = useState('');
  const [showSuccessModalSku, setShowSuccessModalSku] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [typeOperation, setTypeOperation] = useState<'edit' | 'new'>();
  const { currentTenant } = useContext(CurrentChannelContext);
  const params = useMemo(
    () => ({
      categoryId,
      description,
      order,
      pageSize,
      seller,
      channel,
      statusId: status,
      clusterIds,
      currentPage,
    }),
    [description, categoryId, order, pageSize, seller, channel, status, clusterIds, currentPage],
  );
  const { data: prices, paging, loading, refetch: refetchPrices } = usePricesPaginate(params);

  function renderTypography(message: string) {
    return (
      <InfraTypography variant={'body_small'} weight={'semibold'} color={Colors.shade_0}>
        {message}
      </InfraTypography>
    );
  }

  useDidMountEffect(() => {
    setShowModalAlert(false);
    setShowModalDeletePrice(false);
    setShowModalProduct(false);
    setShowModalSKU(false);
    setShowSuccessModalSku(false);
    setPriceSelected(null);
  }, [currentTenant]);

  const links = [
    {
      text: t('app'),
      href: '/',
      use: 'link',
    },
    {
      text: t('main'),
      href: '/',
      disabled: false,
    },
    {
      text: t('price.title'),
      disabled: false,
    },
  ];

  const renderStatus = (rowData: any) => {
    if (rowData.isActive == false) {
      return <InfraTag color="inactive" name={t('price.form.labels.statusInactive')} outline={false} borde={false} />;
    } else {
      let statusColor = new StatusColor();
      return (
        <InfraTag
          color={statusColor.status[rowData.status.status]}
          name={
            rowData.status
              ? t('price.fields.status.status.' + rowData.status.status)
              : t('price.fields.status.status.pending')
          }
        >
          {' '}
        </InfraTag>
      );
    }
  };

  const handlePagination = (event, value) => {
    setCurrentPage(value);
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleSellers = (id, name) => {
    setCurrentPage(1);
    setSeller(id);
  };

  const handleChannel = (channel) => {
    setCurrentPage(1);
    setClustersIds([{ label: 'Default', value: 'default', selected: true }]);
    setChannel(channel.id);
  };

  const handleStatus = (id, status) => {
    setStatus(id);
    setCurrentPage(1);
  };

  const renderFilterIcon = () => {
    return (
      <span style={{ marginInline: '10px' }}>
        <InfraIcons name="SortDescending" size={16} weight="bold" />
      </span>
    );
  };

  const handleEditPrice = async (priceData) => {
    const priceFound: any = await priceRepository.findOne('1', priceData.id);
    setPriceSelected(priceFound.data.prices[0]);
    setShowModalSKU(true);
    setTypeModal('edit');
  };

  const handleDelete = (priceData) => {
    setPriceSelected(priceData);
    setShowModalDeletePrice(true);
  };

  const handleOperation = async (result) => {
    setShowModalAlert(true);
    setShowModalProduct(false);
    setContexAlert({ context: 'createPriceByProduct', type: result.type, messages: result.messages });
    refetchPrices();
  };

  const renderError = () => {
    if (contextAlert.messages && contextAlert.messages.length > 0) {
      return contextAlert.messages.map((el, key) => {
        return el.error ? (
          <span key={key}>{String(t(`price.form.textValidations.${el.error}`)).replace(':sku', el.skuName)}</span>
        ) : (
          <span key={key}>{String(t(`price.form.textValidations.SkuCreateSuccess`)).replace(':sku', el.skuName)}</span>
        );
      });
    }
  };

  const renderModalAlert = () => {
    if (contextAlert.context === 'delete') {
      if (contextAlert.type === 'failed') {
        return 'Falha ao deletar o registro';
      } else if (contextAlert.type === 'success') {
        return 'Registro deletado com sucesso';
      }
    } else if (contextAlert.context === 'createPriceByProduct') {
      if (contextAlert.type === 'failed') {
        return t('price.form.textValidations.AllSkuAreadyExists');
      } else if (contextAlert.type === 'success') {
        return t('price.form.textValidations.PriceCreateSuccess');
      } else if (contextAlert.type === 'both') {
        return () => renderError();
      }
    }
  };

  const handleModalSkuCreate = () => {
    setShowModalSKU(true);
    setTypeModal('create');
  };

  const handleCloseModal = () => {
    setShowModalSKU(false);
    refetchPrices();
    setPriceSelected(null);
  };

  const handleSuccessModalSku = ({ typeOperation }) => {
    setTypeOperation(typeOperation);
    setShowSuccessModalSku(true);
    setPriceSelected(null);
    refetchPrices();
  };

  const renderNumber = (value) => {
    return <span>{FormatCurrency.format('BRL', value)}</span>;
  };

  const renderModalSuccessSku = () => {
    if (typeOperation === 'edit') {
      return t('successUpdateMessageDefault');
    }
    return t('successCreateMessageDefault');
  };

  const renderBreadcrumb = () => {
    return (
      <Box>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </Box>
    );
  };

  const renderTitle = () => {
    return (
      <div>
        <InfraTypography variant={'h1_small'} weight={'bold'}>
          {t('price.title')}
        </InfraTypography>
      </div>
    );
  };

  function handleMultiSelectCategory(data) {
    if (data.length > 0) {
      setCurrentPage(1);
      setStateCategories(data);
    } else {
      setStateCategories([]);
    }
  }

  function handleMultiSelectClusters(data = []) {
    if (data.length > 0) {
      setCurrentPage(1);
      setClustersIds(data);
    } else {
      setClustersIds([]);
    }
  }
  const renderToolbar = () => {
    return (
      <Flex direction="row" justify={'space-between'}>
        <Flex gap="8px" direction="row" grow="0" shrink="1" basis="auto">
          <Box w="159px">
            <AutocompleteChannels onChange={handleChannel} label="Channel" />
          </Box>
          <Box w="159px">
            <InfraLabel color="neutral_600">{t('price.fields.autocomplete.seller.label')}</InfraLabel>
            <AutocompleteSellers
              versionId="1"
              onChange={handleSellers}
              channelId={channel}
              placeholder={t('price.fields.autocomplete.seller.placeholder')}
            />
          </Box>

          <Box w="159px">
            <InfraLabel color="neutral_600">{t('price.fields.multselect.category.label')}</InfraLabel>
            <MultiSelectCategories
              limitTags={1}
              value={categoryId}
              onChange={(data) => {
                handleMultiSelectCategory(data);
              }}
              display="count"
              placeholder={t('price.fields.multselect.category.placeholder')}
            />
          </Box>

          <Box w="219px">
            <InfraLabel color="neutral_600">{t('price.fields.multselect.clusters.label')}</InfraLabel>
            <SelectClusters
              showDefaultOption={true}
              limitTags={1}
              multiple={true}
              value={clusterIds}
              display="count"
              channelId={channel}
              onChange={(data) => {
                handleMultiSelectClusters(data);
              }}
              placeholder={t('price.fields.multselect.clusters.placeholder')}
            />
          </Box>

          <Box w="159.01px">
            <InfraLabel color="neutral_600">{t('price.fields.autocomplete.status.label')}</InfraLabel>
            <AutocompleteStatus
              versionId="1"
              onChange={handleStatus}
              placeholder={t('price.fields.autocomplete.status.placeholder')}
              typePrice="price"
            />
          </Box>

          <Box w="280px">
            <InfraLabel color="neutral_600">{t('price.fields.search.label')}</InfraLabel>
            <InfraTextField
              id="1"
              inputStyle="normal"
              onChange={() => {}}
              onChangeValue={(v) => setDescription(v.target.value)}
              placeholder={t('price.fields.search.placeholder')}
              type="text"
              style={{ height: '45px' }}
            />
          </Box>
        </Flex>
        <Flex gap="10px" direction="row" justify={'end'} align={'center'} mt="25px">
          <Button
            color="neutral"
            size="large"
            outline={false}
            onClick={handleModalSkuCreate}
            icon={'Plus'}
            iconRight={true}
          >
            {renderTypography(t('price.button.sku'))}
          </Button>
          <Button
            color="neutral"
            size="large"
            outline={false}
            onClick={() => setShowModalProduct(true)}
            icon={'Plus'}
            iconRight={true}
          >
            {renderTypography(t('price.button.product'))}
          </Button>
        </Flex>
      </Flex>
    );
  };

  const renderProductSku = (text: string, limit: number) => {
    if (text.length > limit) {
      return (
        <InfraTooltip message={text} position="top" theme="dark" width={250}>
          {`${text.substring(0, limit)}...`}
        </InfraTooltip>
      );
    }
    return text;
  };

  const displayCategories = (categories) => {
    if (!categories?.length) {
      return '-';
    }
    return categories?.map((c) => c?.name).join(', ');
  };

  const renderDataTable = () => {
    return (
      <InfraTable value={prices} loading={loading} messageEmptyData={t('emptyTable')}>
        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'sku.skuId' ? '' : 'sku.skuId')}
          field="skuId"
          header={t('price.fields.table.columnHeaders.skuId')}
          headerAlign="left"
        >
          {order === 'sku.skuId' && renderFilterIcon()}
        </InfraTableColumn>
        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'product.productId' ? '' : 'product.productId')}
          field="productId"
          header={t('price.fields.table.columnHeaders.productId')}
          headerAlign="left"
        >
          {order === 'product.productId' && renderFilterIcon()}
        </InfraTableColumn>
        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'sku.externalSku' ? '' : 'sku.externalSku')}
          field="externalSku"
          header={t('price.fields.table.columnHeaders.externalSku')}
          headerAlign="left"
          body={({ externalSku }) => externalSku || '-'}
        >
          {order === 'sku.externalSku' && renderFilterIcon()}
        </InfraTableColumn>
        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'product.name' ? '' : 'product.name')}
          field="product"
          header={t('price.fields.table.columnHeaders.product')}
          headerAlign="left"
          body={({ product, sku }) => renderProductSku(`${product} - ${sku}`, 35)}
        >
          {order === 'product.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'price.highPrice' ? '' : 'price.highPrice')}
          field="highPrice"
          header={t('price.fields.table.columnHeaders.highPrice')}
          headerAlign="left"
          body={({ highPrice }) => renderNumber(highPrice)}
        >
          {order === 'price.highPrice' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'price.price' ? '' : 'price.price')}
          field="price"
          header={t('price.fields.table.columnHeaders.price')}
          headerAlign="left"
          body={({ price }) => renderNumber(price)}
        >
          {order === 'price.price' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'clientsCluster.name' ? '' : 'clientsCluster.name')}
          field="brand"
          header={t('price.fields.table.columnHeaders.clientsCluster')}
          headerAlign="left"
          body={({ cluster }) => {
            return cluster?.map((c) => c?.name).join(', ') ?? '-';
          }}
        >
          {order === 'clientsCluster.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'categories.name' ? '' : 'categories.name')}
          field="categories"
          header={t('price.fields.table.columnHeaders.category')}
          headerAlign="left"
          body={({ categories }) => displayCategories(categories)}
        >
          {order === 'categories.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="seller"
          clickable
          onClick={(_, field) => setOrder(order === 'seller.name' ? '' : 'seller.name')}
          header={t('price.fields.table.columnHeaders.seller')}
          headerAlign="left"
        >
          {order === 'seller.name' && renderFilterIcon()}
        </InfraTableColumn>
        <InfraTableColumn
          field="active"
          header="Status"
          align="left"
          headerAlign="left"
          body={renderStatus}
          clickable
          onClick={(_, field) => setOrder(order === 'status.id' ? '' : 'status.id')}
        >
          {order === 'status.id' && renderFilterIcon()}
        </InfraTableColumn>
        <InfraTableColumn
          field="priceId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(priceData) => (
            <a onClick={() => handleEditPrice(priceData)}>
              <InfraIcons name="PencilSimple" size={14} weight="bold" />
            </a>
          )}
        ></InfraTableColumn>
        <InfraTableColumn
          field="priceId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={(priceData) => (
            <a onClick={() => handleDelete(priceData)}>
              <InfraIcons name="TrashSimple" size={14} weight="bold" />
            </a>
          )}
        ></InfraTableColumn>
      </InfraTable>
    );
  };

  const renderPagination = () => {
    return (
      <Paginate
        onChangePageSize={handlePageSize}
        onChangePage={handlePagination}
        totalPages={paging?.totalPages}
        currentPage={currentPage}
      />
    );
  };

  return (
    <>
      <InfraGrid>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'31px'}>{renderBreadcrumb()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'32px'}>{renderTitle()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'22px'}>{renderToolbar()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'-10px'}>{renderDataTable()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt="4px" mb="64px">
            {renderPagination()}
          </Box>
        </InfraCol>
      </InfraGrid>
      <WrapperModal>
        <InfraModal
          theme="light"
          show={showModalProduct}
          onClose={() => setShowModalProduct(false)}
          title={t('price.modais.titles.product')}
        >
          <ModalProduct onCloseModal={(result) => handleOperation(result)} />
        </InfraModal>
      </WrapperModal>

      <WrapperModal>
        <InfraModal
          theme="light"
          show={showModalSKU}
          onClose={handleCloseModal}
          title={typeModal === 'create' ? 'Cadastrar por SKU' : 'Editar por SKU'}
        >
          <ModalSku
            onCloseModal={setShowModalSKU}
            price={priceSelected}
            cbAlertModal={(result) => {
              handleSuccessModalSku(result);
            }}
          />
        </InfraModal>
      </WrapperModal>

      <WrapperModalConfirm>
        <InfraModal
          theme="light"
          show={showModalDeletePrice}
          onClose={() => {
            setShowModalDeletePrice(false);
            setPriceSelected(null);
          }}
        >
          <ModalDeletePrice
            onCloseModal={({ result }) => {
              setShowModalDeletePrice(false);
              if (result) {
                refetchPrices();
                setContexAlert({ context: 'delete', type: result });
                setShowModalAlert(true);
              }
              setPriceSelected(null);
            }}
            price={priceSelected}
          />
        </InfraModal>
      </WrapperModalConfirm>

      <AlertModal
        variation={contextAlert.type === 'both' ? 'failed' : contextAlert.type}
        message={renderModalAlert()}
        show={showModalAlert}
        onClose={() => setShowModalAlert(false)}
        width="278"
        onConfirmModal={() => setShowModalAlert(false)}
      />

      <AlertModal
        message={renderModalSuccessSku}
        show={showSuccessModalSku}
        onClose={() => setShowSuccessModalSku(false)}
        width="278"
        onConfirmModal={() => setShowSuccessModalSku(false)}
      />
    </>
  );
};

export default PricesComponent;
