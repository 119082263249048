import { PromotionSalesOperator } from '../Model/PromotionSalesOperator';
import { API } from '../Services/api';
import { BaseRepository } from './BaseRepository';

export class PromotionSalesOperatorRepository extends BaseRepository<PromotionSalesOperator,   PromotionSalesOperator['codeId']> {
  name = 'promotions';

  async updateSaleOperator(versionId: string, promotionId: string, sellersCode: Array<PromotionSalesOperator>): Promise<PromotionSalesOperator> {
    const { tenantId, channelId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? this.channelId ?? channelId;
    let headers = this.getHeaders(tenant, channel, versionId);

    const dataToUpdate = {
      "promotionSalesOperators": sellersCode,
    };

    const response: any = await API.put<PromotionSalesOperator>(this.name+'/'+promotionId+'/sales-operators', dataToUpdate, {
      headers: headers,
    }).then((response) => response.data);
    return response.data;
  }
}
