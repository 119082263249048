import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useAccount } from "../../components";
import { ChargeRepository } from "../../Repository";
import { notEmptyObjectValue } from "../../utils/notEmptyObjectValue";
import { toISOString } from "../../utils/toISOString";

type UseChargesPaginateProps = {
  currentPage: number;
  pageSize: number;
  order: string;
  filter: {
    seller: string;
    type: string;
    status: string;
    description: string;
    activeAt: string;
    deactiveAt: string;
    channel: string;
  };
}

const formatRecordDate = (date: Date) => format(new Date(date), 'dd.MM.yyyy HH:mm:ss')
let timeout
const chargeRepository = new ChargeRepository();
const useChargesPaginate = (props: UseChargesPaginateProps) => {
  const [loading, setLoading] = useState(true);
  const { currentChannel } = useAccount();
  const channelCurrent = localStorage.getItem('ifc-channel-id') ?? currentChannel;

  const [data, setData] = useState<{
    data: any[];
    paging: {
      recordsReturned?: number;
      totalPages?: number;
      totalRecords?: number;
      currentPage?: number;
    };
  }>({
    data: [],
    paging: {},
  });

  const fetchCharges = async () => {
    chargeRepository.source.cancel();
    const { filter, pageSize, currentPage } = props;
    try {
      setLoading(true);
      const params = notEmptyObjectValue({
        pageNumber: currentPage,
        pageSize: pageSize,
        sellerId: filter.seller,
        statusId: filter.status,
        activeAt: toISOString(filter.activeAt),
        deactiveAt: toISOString(filter.deactiveAt),
        description: filter.description,
        typeId: filter.type,
        isAdditional: true,
      });

      const { paging, records } = await chargeRepository
        .setChannelId(channelCurrent ?? filter?.channel)
        .findPaginate('1', params);
      const formattedRecords = records.map(({ activateAt, deactivateAt, ...restRecord }) => {
        return {
          ...restRecord,
          period: `${formatRecordDate(activateAt)} - ${formatRecordDate(deactivateAt)}`,
        };
      });

      setData({
        paging,
        data: formattedRecords,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    clearTimeout(timeout);
    if (!channelCurrent) return;
    timeout = setTimeout(() => {
      fetchCharges();
    }, 300);
  }, [props, channelCurrent]);

  useEffect(() => {
    return () => chargeRepository.source.cancel();
  }, []);

  return {
    loading,
    data: data.data,
    paging: data.paging,
    refetch: fetchCharges,
    repository: chargeRepository,
  };
};

export default useChargesPaginate
