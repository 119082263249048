import {
  InfraBreadcrumbs,
  InfraButton,
  InfraCol,
  InfraGrid,
  InfraIcons,
  InfraStepper,
  InfraTypography,
} from '@infralabs/design-system';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { AlertModal, Box, Flex, ReturnLink, useAccount } from '../../../components';
import { isDateDiffMinutesAllowed } from '../../../Helper';
import Colors from '../../../Helper/Colors';
import { RegEx } from '../../../Helper/RegEx';
import { ServiceStorage } from '../../../Helper/ServiceStorage';
import { useQuery } from '../../../hooks/useQuery';
import { PromotionRepository } from '../../../Repository/PromotionRepository';
import AlertReport from './AlertReportModal';
import { AlertModalSuccessPromotion } from './AlertSuccess';
import ConfigStep from './ConfigStep';
import OriginStep from './OriginStep';
import PromotionStep from './PromotionStep';
import * as S from './style';

type RegisterPromotionForm = {
  id: string;
};

type ResultCreatePromotion = {
  categories?: Array<{ categoryId?: number; result: string; error: string }>;
  skus?: Array<{ skuId: string; result: string }>;
  id: string;
  result: string;
  sellerId: string;
  clusters?: Array<{ id: string }>;
  affectedSkus?: number;
};

const initValuesStepPromotion = {
  seller: '',
  name: '',
  activateAt: '',
  deactivateAt: '',
  description: '',
  promotionTypeId: '',
};

const initValuesStepConfig = {
  isSkuImports: false,
  customOption: 'none',
  categories: [],
  clusters: [],
  skus: [],
  skusExceptions: [],
  deliveryModes: [],
  methodsPayment: [],
  discountValue: '',
  discountPercent: '',
  code: '',
  maxInstallmentsCount: 0,
  salesPlans: [],
  states: [],
  startZipCode: '',
  endZipCode: '',
  zipCodeRanges: [],
};

const initValuesStepOrigin = {
  deviceId: '',
  utmTerm: '',
  utmMedium: '',
  utmCampaign: '',
  utmSource: '',
  isActive: false,
  sellerCodes: [],
};

enum STEPS {
  PROMOTION = 0,
  CONFIG = 1,
  ORIGIN = 2,
}

enum PromotionType {
  SKU = 1,
  FREIGHT = 2,
  PAYMENT = 3,
}

const creditCard = ServiceStorage.getCreditCard();

const promotionRepository = new PromotionRepository();

type ModalResultErrorsProp = {
  messages: { id: string; name: string; description: string; result: string; type: 'category' | 'sku' }[];
};

const ModalResultErrors = ({ messages }: ModalResultErrorsProp) => {
  const { t } = useTranslation();
  const renderIcon = (resultType: string) => {
    if (resultType === 'failed') {
      return <InfraIcons name="WarningCircle" color={Colors.error_200} weight="bold" />;
    } else {
      return <InfraIcons name="CheckCircle" color={Colors.success_100} weight="bold" />;
    }
  };

  const renderDescription = (el) => {
    let description = el.description;
    const search = ':' + el.type;
    if (el.result === 'failed') {
      return t('promotion.forms.textValidations.' + description).replace(
        search,
        `<span class="list-item-span">${el.name}</span> `,
      );
    } else {
      return t('promotion.forms.textValidations.' + description).replace(
        search,
        `<span class="list-item-span">${el.name}</span> `,
      );
    }
  };

  const orderByResult = function (a, b) {
    if (a.result < b.result) {
      return 1;
    } else if (a.result > b.result) {
      return -1;
    }
    return 0;
  };

  return (
    <div>
      {messages &&
        messages.length > 0 &&
        messages.sort(orderByResult).map((el) => {
          return (
            <S.ListItem key={el.id}>
              <div>{renderIcon(el.result)}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: renderDescription(el),
                }}
              ></div>
            </S.ListItem>
          );
        })}
    </div>
  );
};

const RegisterPromotionForm = ({ locationUrl = '' }: { locationUrl: string }) => {
  let history = useHistory();

  useEffect(() => {
    if (locationUrl != '') {
      history.push(locationUrl);
    }
  }, [locationUrl]);

  let { id } = useParams();
  let query = useQuery();
  const promotionCopy = query.get('copy');
  const queryChannelId = query.get('channelId');
  id = id || promotionCopy;

  const [promotion, setPromotion] = useState<any>();
  const [promotionValues, setPromotionValues] = useState<any>(initValuesStepPromotion);
  const [configValues, setConfigValues] = useState<any>(initValuesStepConfig);
  const [originValues, setOriginValues] = useState<any>(initValuesStepOrigin);
  const [alertContext, setAlertContext] = useState<{ variation: 'success' | 'failed'; show: boolean; props?: any }>({
    variation: 'success',
    show: false,
    props: {},
  });
  const [alertContextReport, setAlertContextReport] = useState<{
    variation: 'success' | 'failed';
    show: boolean;
    props?: any;
  }>({
    variation: 'success',
    show: false,
    props: {},
  });
  const [openAlertApiError, setOpenAlertApiError] = useState<boolean>(false);

  const [step, setStep] = useState(STEPS.PROMOTION);

  const { t } = useTranslation();

  const links = [
    {
      text: t('app'),
      href: '/',
      use: 'link',
    },
    {
      text: t('main'),
      href: '/',
      disabled: false,
    },
    {
      text: t('promotion.breadcrumbs.home'),
      href: '/promotions',
      disabled: false,
    },
    {
      text: t('promotion.breadcrumbs.' + (id ? 'formEdit' : 'formCreate')),
    },
  ];

  const steps = [
    {
      label: t('promotion.forms.steps.stepOne'),
      icon: 'Percent',
    },
    {
      label: t('promotion.forms.steps.stepTwo'),
      icon: 'Truck',
    },
    {
      label: t('promotion.forms.steps.stepTree'),
      icon: 'ListDashes',
    },
  ];

  const [promotionController, setPromotionController] = useState({
    sellerId: '',
    clusters: [],
    isAccumulative: false,
    firstBuy: false,
  });

  const [originController, setOriginController] = useState({
    isActive: false,
  });

  const [loading, setLoading] = useState(false);

  const shapesValidation = useCallback(() => {
    function replace(str: string, search: string, valueToReplace: any) {
      return str.replace(search, valueToReplace);
    }

    const promotionShape = Yup.object().shape({
      seller: Yup.string().required(t('textValidations.required')),
      name: Yup.string()
        .required(t('textValidations.required'))
        .max(150, replace(t('textValidations.max'), ':max', 150)),
      activateAt: Yup.date().required(t('textValidations.required')),
      deactivateAt: Yup.date().required(t('textValidations.required')),
      promotionTypeId: Yup.string().required(t('textValidations.required')),
      description: Yup.string(),
    });

    const configShape = Yup.object().shape({
      categories: Yup.array(),
      clusters: Yup.array(),
      skus: Yup.array(),
      skuExceptions: Yup.array().notRequired(),
      methodsPayment: Yup.array(),
      deliveryModes: Yup.array(),
      discountValue: Yup.string().notRequired(),
      discountPercent: Yup.string().matches(RegEx.percentage(), t('textValidations.invalid')).notRequired(),
      code: Yup.string()
        .max(36, replace(t('textValidations.max'), ':max', 36))
        .notRequired(),
      maxInstallmentsCount: Yup.number()
        .min(0, replace(t('textValidations.min'), ':min', 0))
        .max(24, replace(t('textValidations.max'), ':max', 24))
        .notRequired(),
    });

    const originShape = Yup.object().shape({
      deviceId: Yup.object().notRequired(),
      utmTerm: Yup.string()
        .max(255, replace(t('textValidations.max'), ':max', 255))
        .notRequired(),
      utmCampaign: Yup.string()
        .max(255, replace(t('textValidations.max'), ':max', 255))
        .notRequired(),
      utmMedium: Yup.string()
        .max(255, replace(t('textValidations.max'), ':max', 255))
        .notRequired(),
      utmSource: Yup.string()
        .max(255, replace(t('textValidations.max'), ':max', 255))
        .notRequired(),
      sellerCodes: Yup.array(),
      salesOperator: Yup.array(),
    });

    return {
      promotionShape,
      configShape,
      originShape,
    };
  }, []);
  const { currentTenant } = useAccount();
  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const result: any = await promotionRepository.setChannelId(queryChannelId).findOne('1', id);
          setPromotion(result.data.promotions[0]);
        } catch (err) {
          setOpenAlertApiError(true);
          history.push('/promotions');
        }
      }
    })();
  }, [id, currentTenant]);

  useEffect(() => {
    prepareInitValuesFromAPI();
  }, [promotion, id, currentTenant]);

  function useController() {
    function setSellerId(value) {
      setPromotionController({ ...promotionController, sellerId: value });
    }

    function setIsAccumulative(value) {
      setPromotionController({ ...promotionController, isAccumulative: value });
    }

    function setFirstBuy(value) {
      setPromotionController({ ...promotionController, firstBuy: value });
    }

    function setIsActive(value) {
      setOriginController({ ...originController, isActive: value });
    }

    return {
      sellerController: [promotionController.sellerId, setSellerId],
      isAccumulativeController: [promotionController.isAccumulative, setIsAccumulative],
      isActiveController: [originController.isActive, setIsActive],
      firstBuyController: [promotionController.firstBuy, setFirstBuy],
    };
  }

  function prepareInitValuesFromAPI() {
    if (id && promotion) {
      const channelId = promotion.channels?.[0]?.id;
      const sellerName = promotion.sellers?.[0]?.name || promotion?.sellerName;
      const sellerId = promotion.sellers?.[0]?.id || promotion?.sellerId;
      setPromotionValues({
        clusters: promotion?.clusters,
        seller: sellerName,
        channel: channelId,
        name: promotion?.name,
        activateAt: new Date(promotion?.activateAt),
        deactivateAt: new Date(promotion?.deactivateAt),
        description: promotion?.description,
        promotionTypeId: promotion.typeId,
      });
      // set value in components controlled
      setPromotionController({
        ...promotionController,
        isAccumulative: promotion.isAccumulative,
        firstBuy: promotion.firstBuy,
        clusters: promotion.clusters,
        sellerId,
      });
      const methodPaymentCreditCard = promotion?.methodsPayment?.find((item) => {
        return item.methodPaymentId === creditCard;
      });
      const skus = [];
      promotion?.skus?.forEach((item) => {
        if (isNaN(item.skuId)) return;
        skus.push({
          value: item.skuId,
          selected: true,
        });
      });
      setConfigValues({
        customOption: (promotion?.isAllCategories && 'categories') || (promotion?.isAllSkus && 'products') || 'none',
        deliveryModes: promotion?.deliveryModes?.map((item) => ({
          value: item.deliveryModeId,
          selected: true,
        })),
        isAllCategories: promotion?.isAllCategories,
        isAllSkus: promotion?.isAllSkus,
        methodsPayment: promotion?.methodsPayment?.map((item) => ({
          value: item.methodPaymentId,
          selected: true,
        })),
        maxInstallmentsCount: methodPaymentCreditCard ? methodPaymentCreditCard.maxInstallmentsCount : undefined,
        skus,
        skuExceptions: promotion?.skusException?.map((item) => ({
          value: item.skuId,
          selected: true,
        })),
        isSkuImports: promotion?.isSkuImports || promotion?.isSkuImport || false,
        categories:
          promotion?.isAllCategories && 'categories'
            ? []
            : promotion?.categories?.map((item) => ({
                value: item.categoryId,
                selected: true,
              })),
        code: promotion?.code || '',
        discountValue: promotion?.discountValue || undefined,
        discountPercent: promotion?.discountPercentage || undefined,
        salesPlans: promotion?.methodsPayment?.reduce((allSalesPlans, paymentMethod) => {
          const salesPlans = paymentMethod?.salesPlans ?? [];
          return [
            ...allSalesPlans,
            ...salesPlans.map((item) => ({
              value: item?.id,
              selected: true,
            })),
          ];
        }, []),
        zipCodeRanges: !promotion?.zipCodeRanges[0]?.state
          ? promotion.zipCodeRanges.map((range) => {
              return {
                startCode: range.rangeInitial,
                endCode: range.rangeFinal,
              };
            })
          : [],
        states: !!promotion?.zipCodeRanges[0]?.state
          ? promotion.zipCodeRanges.map((range) => {
              return {
                value: range.state,
                selected: true,
              };
            })
          : [],
      });

      let device = promotion?.deviceId ? { value: promotion?.deviceId, selected: true } : undefined;
      setOriginValues({
        deviceId: device,
        utmTerm: promotion.utmTerm || '',
        utmCampaign: promotion.utmCampaign || '',
        utmMedium: promotion.utmMedium || '',
        utmSource: promotion.utmSource || '',
        salesOperators: promotion.sellerCodes?.map((code) => ({
          value: code.sellerCode,
        })),
      });

      // set value in components controlled
      setOriginController({
        ...originController,
        isActive: promotion.isActive,
      });
    }
  }

  function getInitialValue() {
    const values = { ...configValues, ...promotionValues, ...originValues };
    switch (step) {
      case STEPS.PROMOTION:
        return id ? values : promotionValues;
      case STEPS.CONFIG:
        return id ? values : configValues;
      case STEPS.ORIGIN:
        return id ? values : originValues;
    }
  }

  function getShapeValidation() {
    const { promotionShape, configShape, originShape } = shapesValidation();
    switch (step) {
      case STEPS.PROMOTION:
        return promotionShape;
      case STEPS.CONFIG:
        return configShape;
      case STEPS.ORIGIN:
        return originShape;
    }
  }

  function getCustomValidationStepPromotion(values: any) {
    const dtNow = new Date();
    const dateValues = Object.assign({}, { ...values });
    let errors: any = {};
    if (isDateDiffMinutesAllowed(values.activateAt, +process.env.PROMOTION_DIFFERECE_DATE_LIMIT)) {
      errors.activateAt = t('promotion.forms.textValidations.ActivationDateGreaterThanCurrentDate', {
        minutes: process.env.PROMOTION_DIFFERECE_DATE_LIMIT,
      });
      return errors;
    }
    if (values.activateAt) {
      const startDt = new Date(values.activateAt);
      if (startDt.getTime() <= dtNow.getTime()) {
        errors.activateAt = t('promotion.forms.textValidations.ActivationDateGreaterThanCurrentDate');
        return errors;
      }
    }
    if (values.deactivateAt) {
      const endDt = new Date(dateValues.deactivateAt);
      if (endDt.getTime() <= dtNow.getTime()) {
        errors.deactivateAt = t('promotion.forms.textValidations.DeactivationDateGreaterThanCurrentDate');
        return errors;
      }
    }
    if (values.activateAt && values.deactivateAt) {
      const startDt = dateValues.activateAt;
      const endDt = new Date(dateValues.deactivateAt);
      if (startDt.getTime() >= endDt.getTime()) {
        errors.deactivateAt = t('promotion.forms.textValidations.DeactivationDateGreaterThanActivationDate');
        return errors;
      }
    }
  }

  function getCustomValidationStepConfig(values: any) {
    let errors: any = {};
    const customOptionIsNone = values.customOption === 'none';

    if (
      (values.discountPercent === undefined || values.discountPercent === '') &&
      (values.discountValue === undefined || values.discountValue === '')
    ) {
      errors.discountPercent = t('promotion.forms.textValidations.DiscountPercentIsRequired');
      errors.discountValue = t('promotion.forms.textValidations.DiscountValueIsRequired');
    }

    if (customOptionIsNone && !values.skus?.length && customOptionIsNone && !values.categories?.length) {
      errors.skus = t('promotion.forms.textValidations.SkusIsRequired');
      errors.categories = t('promotion.forms.textValidations.CategoriesIsRequired');
    }

    if (values.promotionTypeId === PromotionType.PAYMENT) {
      if (values.methodsPayment === undefined || values.methodsPayment.length === 0) {
        errors.methodsPayment = t('textValidations.required');
      }
    }

    if (values.promotionTypeId === PromotionType.FREIGHT) {
      if (values.deliveryModes === undefined || values.deliveryModes.length === 0) {
        errors.deliveryModes = t('textValidations.required');
      }
    }

    return errors;
  }

  function getCustomValidation(values) {
    if (step === STEPS.PROMOTION) {
      const errors = getCustomValidationStepPromotion(values);
      return errors;
    }
    if (step === STEPS.CONFIG) {
      const errors = getCustomValidationStepConfig(values);
      return errors;
    }
  }

  const handleOnPreviousStep = () => {
    if ([STEPS.CONFIG, STEPS.ORIGIN].includes(step)) {
      const previousStep = step - 1;
      setStep(previousStep);
    }
  };

  function handleOnSelected(index) {
    setStep(index);
  }

  function handleOnSubmitPromotionStep(values, actions) {
    setStep(STEPS.CONFIG);
  }

  function handleOnSubmitConfigStep(values, actions) {
    setStep(STEPS.ORIGIN);
  }

  async function handleOnSubmitOriginStep(values, actions) {
    const payload = createPayload(values);
    setLoading(true);

    await savePromotion(payload);
    setLoading(false);
  }

  function handleOnConfirmModal() {
    if (alertContextReport.show) {
      if (alertContextReport.variation === 'failed' && alertContextReport.props?.bothTypeError === false) {
        setAlertContextReport({ ...alertContextReport, show: false });
      } else if (alertContextReport.props?.bothTypeError === true) {
        history.push('/promotions');
      }
      setAlertContextReport({ ...alertContextReport, show: false });
    }

    if (alertContext.show) {
      if (alertContext.variation !== 'failed') {
        history.push('/promotions');
      }
      setAlertContext({ ...alertContext, show: false });
    }
  }

  function createPayload(values: any) {
    const sellerId = promotionController.sellerId;
    const isAccumulative = promotionController.isAccumulative;
    const firstBuy = promotionController.firstBuy;
    const isActive = originController.isActive;

    const activateAt = new Date(values.activateAt).toISOString();
    const deactivateAt = new Date(values.deactivateAt).toISOString();
    let categories = values?.categories?.map((el) => ({
      categoryId: el.value,
    }));

    if ((categories && categories.length === 0) || values.isAllCategories) {
      categories = undefined;
    }

    let clusters = values?.clusters?.map((el) => ({
      id: el.id,
    }));

    if (clusters && clusters.length === 0) {
      clusters = undefined;
    }

    let skus = values?.skus?.map((el) => ({
      skuId: el.value,
    }));

    if (skus && skus.length === 0) {
      skus = undefined;
    }

    let skusException = values?.skuExceptions?.map((el) => ({
      skuId: el.value,
    }));

    if (skusException && skusException.length === 0) {
      skusException = undefined;
    }

    let salesPlans = values?.salesPlans?.map((item) => ({ id: item?.value }));
    if (salesPlans && salesPlans.length === 0) {
      salesPlans = [];
    }

    let typeId = values.promotionTypeId;
    let methodsPayments = undefined;
    let deliveryModes = undefined;
    if (typeId === PromotionType.PAYMENT) {
      methodsPayments = values?.methodsPayment?.map((el) => ({
        methodPaymentId: el.value,
        maxInstallmentsCount: el.value === creditCard ? values.maxInstallmentsCount : null,
        salesPlans: el.value === 'CREDIT_LIMIT' ? salesPlans : [],
      }));
    } else if (typeId === PromotionType.FREIGHT) {
      deliveryModes = values?.deliveryModes?.map((el) => ({
        deliveryModeId: el.value,
      }));
    }

    const deviceId = values?.deviceId?.value;
    let discountValue =
      values?.discountValue === '' || values?.discountValue === null || values?.discountValue === undefined
        ? undefined
        : Number(String(values?.discountValue).replace(',', '.'));
    let discountPercentage =
      values?.discountPercent === '' || values?.discountPercent === null || values?.discountPercent === undefined
        ? undefined
        : Number(values.discountPercent);

    let salesOperator = values?.salesOperators?.map((code) => ({ codeId: code?.value }));
    if (salesOperator && salesOperator.length === 0) {
      salesOperator = [];
    }

    let zipCodeRanges = [];
    if (!!values?.zipCodeRanges?.length) {
      zipCodeRanges = values.zipCodeRanges.map((range) => {
        return {
          rangeInitial: parseInt(range.startCode),
          rangeFinal: parseInt(range.endCode),
        };
      });
    } else if (!!values?.states?.length) {
      zipCodeRanges = values.states.map((state) => {
        return {
          state: state.value,
        };
      });
    }

    return {
      id: id ? id : undefined,
      sellerId,
      clusters,
      isSkuImports: values.isSkuImports,
      isAllCategories: values.isAllCategories,
      isAllSkus: values.isAllSkus,
      name: values.name,
      description: values.description,
      code: values.code ?? undefined,
      isAccumulative,
      firstBuy,
      isActive,
      discountPercentage,
      discountValue,
      activateAt,
      deactivateAt,
      typeId,
      categories,
      skus,
      channelId: values.channel,
      skusException,
      methodsPayments,
      deliveryModes,
      deviceId,
      utmTerm: values?.utmTerm,
      utmCampaign: values?.utmCampaign,
      utmMedium: values?.utmMedium,
      utmSource: values?.utmSource,
      salesOperators: salesOperator,
      zipCodeRanges,
    };
  }

  async function savePromotion(payload) {
    try {
      let promotionSaved = null;
      let operation = null;
      if (promotionCopy || !id) {
        operation = 'create';
        delete payload.id;
        promotionSaved = await promotionRepository
          .setChannelId(payload.channelId)
          .save<ResultCreatePromotion>('1', payload);
      } else {
        operation = 'edit';
        promotionSaved = await promotionRepository
          .setChannelId(payload.channelId)
          .update<ResultCreatePromotion>('1', payload);
        promotionSaved.records = promotionSaved.data.promotions;
      }

      if (promotionSaved) {
        const records = promotionSaved.records;
        if (records && records.length > 0) {
          const result = records[0];
          if (result.result === 'failed') {
            setAlertContext({ show: true, variation: 'failed', props: { message: result.error } });
          }
          if (result.skus && result.skus.length > 0 && result.skus?.some((sku) => sku.result === 'failed')) {
            setAlertContext({ show: true, variation: 'failed' });
          } else if (operation === 'edit' && result.skus && result.skus.length === 0 && result.result === 'updated') {
            setAlertContext({
              show: true,
              variation: 'success',
              props: {
                skusInserted: result.affectedSkus,
                operation,
              },
            });
          } else if (result.categories && result.categories.length > 0) {
            const categories = result.categories;
            setAlertContextReport({
              show: true,
              variation: 'failed',
              props: {
                bothTypeError: true,
                skusInserted: result.affectedSkus,
                categories: categories,
                operation,
              },
            });
          } else {
            setAlertContext({
              show: true,
              variation: 'success',
              props: {
                skusInserted: result.affectedSkus,
                operation,
              },
            });
          }
        }
      }
    } catch (error) {
      if (error.response.data?.data) {
        const data = error.response.data?.data;
        const categories = data.promotions[0].categories;
        if (categories && categories.length > 0) {
          setAlertContextReport({
            show: true,
            variation: 'failed',
            props: {
              bothTypeError: false,
              skusInserted: data.affectedSkus,
              categories: categories,
              operation: id ? 'edit' : 'create',
            },
          });
        } else {
          let message = '';
          if (error.response.data.message) {
            message = error.response.data.message[0];
          } else {
            message = error.response.data.data.promotions[0].error;
          }
          setAlertContext({
            show: true,
            variation: 'failed',
            props: {
              message: message,
              error: data.promotions[0]?.error,
            },
          });
        }
      } else {
        let message = error.response.data.message;
        let errorMessage = error.response.data.error;
        setAlertContext({ show: true, variation: 'failed', props: { message: message, error: errorMessage } });
      }
    }
  }

  const renderTitleAlertReport = () => {
    if (alertContextReport.variation == 'failed' && alertContextReport.props.bothTypeError === false) {
      return t('promotion.forms.textValidations.PromotionFailed');
    }

    return t(
      'promotion.forms.textValidations.' +
        ((alertContextReport.props?.operation || 'create') === 'edit' ? 'PromotionUpdated' : 'PromotionCreated'),
    );
  };

  const renderBreadcrumb = () => {
    return (
      <div style={{ marginTop: 'rem' }}>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <Box mt="32px">
        <InfraTypography variant={'h1_small'} weight={'bold'}>
          {t('promotion.forms.titles.' + (id ? 'formEdit' : 'formCreate'))}
        </InfraTypography>
      </Box>
    );
  };

  const renderSteps = (props) => {
    switch (step) {
      case STEPS.PROMOTION:
        return (
          <PromotionStep
            {...props}
            controller={{
              sellerController: useController().sellerController,
              isAccumulativeController: useController().isAccumulativeController,
              firstBuyController: useController().firstBuyController,
            }}
            isEdit={id ? true : false}
          />
        );
      case STEPS.CONFIG:
        return <ConfigStep {...props} />;
      case STEPS.ORIGIN:
        return (
          <OriginStep
            {...props}
            controller={{
              isActiveController: useController().isActiveController,
            }}
          />
        );
    }
  };

  const renderNavegation = () => {
    const nextNavigatorText =
      step === STEPS.ORIGIN ? t('promotion.forms.navigatorFinish') : t('promotion.forms.navigatorNext');
    const renderIcon = step === STEPS.ORIGIN ? 'Check' : 'ArrowRight';

    return (
      <Flex justify="center" gap="20px" h="67px" align="center">
        {[STEPS.CONFIG, STEPS.ORIGIN].includes(step) && (
          <InfraButton
            onClick={handleOnPreviousStep}
            type="button"
            color="neutral"
            outline={true}
            style={{ width: '180px' }}
            icon="ArrowLeft"
            iconLeft={true}
          >
            <InfraTypography variant={'h6'} weight={'bold'}>
              {t('promotion.forms.navigatorPrevious')}
            </InfraTypography>
          </InfraButton>
        )}
        <InfraButton
          color="neutral"
          type={'submit'}
          iconRight={true}
          icon={renderIcon}
          style={{ width: '180px' }}
          load={loading}
          disabled={loading}
          loadingText={t('promotion.forms.navigatorLoadingButton')}
        >
          <InfraTypography variant={'body_small'} fullWidth={true} weight={'semibold'} color={Colors.shade_0}>
            {`${nextNavigatorText}`}
          </InfraTypography>
        </InfraButton>
      </Flex>
    );
  };

  const renderMessagesContextReport = () => {
    if (alertContextReport.show === true) {
      const isFromSKU = alertContextReport?.props?.skus ? true : false;
      return (
        <ModalResultErrors
          messages={alertContextReport.props.categories.map((el) => ({
            id: isFromSKU ? el.skuId : el.categoryId,
            name: el.name || '',
            description: el.error || (isFromSKU ? 'SkuProcessed' : 'CategoryProcessed'),
            result: el.result,
            type: isFromSKU ? 'sku' : 'category',
          }))}
        />
      );
    }
  };

  const renderMessagesAlert = () => {
    let message = alertContext.props?.message;
    let error = alertContext.props?.error;
    if (error == 'Bad Request') {
      if (message === 'promotions.sellerCodes is informed, you can not inform code') {
        message = t('promotion.forms.textValidations.sellerCodesInformedCanNotInformCode');
      } else if (/([a-zA-Z]+.sellerCodes.[0-9]+)/g.test(message)) {
        message = t('promotion.forms.textValidations.sellerCodesMustBeThenOrEqual36Caracteres');
      }
    } else {
      message = t('promotion.forms.textValidations.' + error);
    }

    if (alertContext.variation === 'success') {
      const operation = alertContext.props?.operation === 'edit' ? 'edit' : 'create';
      return (
        <S.ListMessageHeader>
          <S.ListMessageTitle>
            {t('promotion.forms.textValidations.' + (operation === 'edit' ? 'PromotionUpdated' : 'PromotionCreated'))}
          </S.ListMessageTitle>
          <S.ListMessageSubTitle>
            {t('promotion.forms.textValidations.SkusInserted').replace(
              ':skusInserted',
              alertContext.props.skusInserted + '',
            )}
          </S.ListMessageSubTitle>
        </S.ListMessageHeader>
      );
    } else {
      return (
        <S.ListMessageHeaderDefault>
          <S.ListMessageTitle>{t('promotion.forms.textValidations.PromotionFailed')}</S.ListMessageTitle>
          <S.ListMessageSubTitle>{message}</S.ListMessageSubTitle>
        </S.ListMessageHeaderDefault>
      );
    }
  };

  const buttonReturn = () => {
    return <ReturnLink to="/promotions" />;
  };

  function renderSubtitleReport() {
    if (alertContextReport.variation == 'failed' && alertContextReport.props.bothTypeError === false) {
      return t('promotion.forms.textValidations.PromotionFailedTryAgain');
    }
    return t('promotion.forms.textValidations.SkusInserted').replace(
      ':skusInserted',
      (alertContextReport.props.skusInserted || 0) + '',
    );
  }

  return (
    <InfraGrid>
      <InfraCol xxl={12} xl={12}>
        <Box mt={'31px'}>{renderBreadcrumb()}</Box>
      </InfraCol>
      <InfraCol xxl={12} xl={12}>
        {renderTitle()}
        {buttonReturn()}
      </InfraCol>
      <InfraCol xxl={12} xl={12}>
        <Box>
          <Formik
            enableReinitialize={id ? true : false}
            initialValues={getInitialValue()}
            onSubmit={(values, actions) => {
              if (step === STEPS.PROMOTION) {
                handleOnSubmitPromotionStep(values, actions);
              } else if (step === STEPS.CONFIG) {
                handleOnSubmitConfigStep(values, actions);
              } else if (step === STEPS.ORIGIN) {
                handleOnSubmitOriginStep(values, actions);
              }
            }}
            validationSchema={getShapeValidation()}
            validate={(values) => getCustomValidation(values)}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit(e);
                  }}
                  style={{ height: '100%' }}
                >
                  <S.Box>
                    <InfraStepper
                      activeIndex={step}
                      model={steps}
                      onSelected={handleOnSelected}
                      disabledOnSelected={true}
                    />
                    <S.BoxContent>{renderSteps(props)}</S.BoxContent>
                    {renderNavegation()}
                  </S.Box>
                </form>
              );
            }}
          </Formik>

          {alertContext.variation === 'success' ? (
            <AlertModalSuccessPromotion
              operation={alertContext.props?.operation === 'edit' ? 'edit' : 'create'}
              onClose={() => {
                setAlertContext({ ...alertContext, show: false });
                handleOnConfirmModal();
              }}
              show={alertContext.show}
              onConfirmModal={() => handleOnConfirmModal()}
              skusAffected={t('promotion.forms.textValidations.SkusInserted').replace(
                ':skusInserted',
                alertContext.props.skusInserted + '',
              )}
            />
          ) : (
            <AlertModal
              variation={'failed'}
              message={renderMessagesAlert}
              onClose={() => setAlertContext({ ...alertContext, show: false })}
              show={alertContext.show}
              onConfirmModal={() => handleOnConfirmModal()}
              width={alertContext?.props?.bothTypeError ? '600' : undefined}
            />
          )}

          <AlertModal
            variation={'failed'}
            message={t('promotion.forms.textValidations.PromotionNotFound')}
            onClose={() => setOpenAlertApiError(false)}
            show={openAlertApiError}
            onConfirmModal={() => {
              history.push('/promotions');
            }}
          />

          <AlertReport
            title={renderTitleAlertReport()}
            subtitle={renderSubtitleReport()}
            onClose={() => {
              setAlertContextReport({ ...alertContextReport, show: false });
              handleOnConfirmModal();
            }}
            show={alertContextReport.show}
            onConfirmModal={() => handleOnConfirmModal()}
          >
            {renderMessagesContextReport()}
          </AlertReport>
        </Box>
      </InfraCol>
    </InfraGrid>
  );
};

export default RegisterPromotionForm;
