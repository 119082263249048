import styled from 'styled-components';

export const WrapperModal = styled.div`
.modal-body {
  padding: 30px 40px -20px 40px;
}
  .title-modal {
    margin-top: -8px;
  }
`;

export const WrapperModalDetail = styled.div`
  .modal-body {
    padding: 1.2rem 1.844rem 1.98rem 1.844rem;
  }

  .title-modal {
    margin-top: -8px;
  }
`;

export const WrapperModalSkuScheduledPrice = styled.div`
 .modal-body {
    padding: 1.2rem 1.844rem 1.98rem 1.844rem;
  }
`
export const WrapperModalConfirm = styled.div`
  .wrapper-body-modal {
    width: 278px !important;
    height: 276px !important;
  }
  .wrapper-relative {
    width: 278px !important;
    height: 276px !important;
  }
  .modal-body {
    padding-inline: 19px;
  }
`

export const AdaptButton = styled.div`
  width: 100%;
  button {
    height: 45px !important;
  }
`

export const WrapperModalProgrammedPrice = styled.div`
  .modal-body {
    padding: 1.2rem 1.844rem 1.98rem 1.844rem;
  }
`