import { createGlobalStyle } from 'styled-components';
export default createGlobalStyle`
 body {
   -webkit-font-smoothing: antialiased !important;
 }
 body {
   background: '#fafafc';
 }
 button {
   cursor: pointer;
 }

 #mfe_div_connector.modal-current-opened {
   overflow: hidden !important;
   filter: none;
 }
`;
