import { WrapperMessage } from './styles';

export function RowErrorMessage(props) {
  return (
    <>
      <WrapperMessage>
        <span>{props.children}</span>
      </WrapperMessage>
    </>
  );
}
