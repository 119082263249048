import { useEffect, useState } from "react";
import { format } from 'date-fns';
import { useAccount } from "../../components";
import { ProgrammedPriceRepository } from "../../Repository/ProgrammedPriceRepository";

type UsePricesPaginateProps = {
  currentPage?: number
  pageSize: number
  activeAt: string
  deactiveAt: string
  seller: string
  channel: string
  statusId: string
  description: string
  categoryId: any[]
  brandId?: any[]
  order: any
}

type PricesParam = {
  pageNumber: number;
  pageSize: number;
  sellerId: string;
  statusId: string;
  description: string;
  category?: string;
  brands?: string;
  order?: string;
}

let timeout
const programmedPriceRepository = new ProgrammedPriceRepository();
const useProgrammedPricesPaginate = (props: UsePricesPaginateProps) => {
  const [loading, setLoading] = useState(true)
  const { currentChannel } = useAccount()
  const [data, setData] = useState<{
    data: any[],
    paging: {
      recordsReturned?: number;
      totalPages?: number;
      totalRecords?: number;
      currentPage?: number;
    };
  }>({
    data: [], paging: {}
  })

  async function fetchProgrammedPrices() {
    const { currentPage, pageSize, seller, channel, statusId, description, activeAt, deactiveAt, order, categoryId, brandId } = props
    try {
      setLoading(true);
      let params: any = {
        pageNumber: currentPage,
        pageSize,
        sellerId: seller,
        statusId,
        activeAt: activeAt ? new Date(activeAt).toISOString() : '',
        deactiveAt: deactiveAt ? new Date(deactiveAt).toISOString() : '',
        description: description,
      };
      if (order) {
        params.order = order
      }

      if (categoryId?.length) {
        params.category = categoryId?.map((e) => e.value).join(',');
      }

      if (brandId?.length) {
        params.brands = brandId?.map((e) => e.value).join(',');
      }

      const { records, paging } = await programmedPriceRepository.setChannelId(channel || currentChannel.id).findPaginate('1', params);

      records.map((el, key) => {
        return (records[key]['period'] =
          format(new Date(el.activateAt), 'dd.MM.yyyy HH:mm:ss') +
          ' - ' +
          format(new Date(el.deactivateAt), 'dd.MM.yyyy HH:mm:ss'));
      });

      setData({
        data: records,
        paging
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    clearTimeout(timeout)
    if (!currentChannel) return
    timeout = setTimeout(() =>{
      fetchProgrammedPrices()
    }, 300)
  }, [props, currentChannel])

  useEffect(() => {
    return () => programmedPriceRepository.source.cancel()
  }, [])

  return {
    loading,
    data: data.data,
    paging: data.paging,
    refetch: fetchProgrammedPrices
  }
}

export default useProgrammedPricesPaginate
