import { useEffect, useState, useContext, useMemo } from 'react';
import {
  InfraGrid,
  InfraCol,
  InfraBreadcrumbs,
  InfraTypography,
  InfraTextField,
  InfraTable,
  InfraTableColumn,
  InfraTag,
  InfraModal,
  InfraLabel,
  InfraDatepicker,
  InfraIcons,
  InfraTooltip,
} from '@infralabs/design-system';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import ModalProduct from './modalProduct';
import ModalSku from './modalSku';
import ModalDelete from './modalDelete';
import {
  AlertModal,
  Box,
  Flex,
  Paginate,
  Button,
  AutocompleteStatus,
  AutocompleteSellers,
  CurrentChannelContext,
  useAccount,
} from '../../components';
import { MultiSelectBrands, MultiSelectCategories } from '../../components/Selects';
import { FormatCurrency } from '../../Helper/FormatCurrency'
import { ResultEnum } from '../../common/enum/result.enum';
import { StatusColor } from '../../common/utils/statusColor';
import { StatusEnum } from '../../common/enum/status.enum';
import ModalDetails from './modalDetails';
import { WrapperModalConfirm, WrapperModalDetail, WrapperModalSkuScheduledPrice } from '../styles/style-wrapper-modal';
import { Colors } from '../../Helper';
import useDidMountEffect from '../../hooks/use-did-mount-effect';
import { useDebounce } from '../../hooks/debounce';
import Tooltip from '../../components/Tooltip';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';
import useSchedulePricesPaginate from './useSchedulePricesPaginate';
import { useHistory } from 'react-router-dom'

const ScheduledricesComponent = ({ locationUrl = '' }: { locationUrl: string }) => {
  const history = useHistory()
  useEffect(() => {
    if (locationUrl != '') {
      history.push(locationUrl)
    }
  }, [locationUrl])
  const { t } = useTranslation();
  const [seller, setSeller] = useState('');
  const [status, setStatus] = useState('');
  const [channel, setChannel] = useState('');
  const [scheduledAt, setScheduledAt] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [description, setDescription] = useState('');
  const [showModalProduct, setShowModalProduct] = useState(false);
  const [showModalSKU, setShowModalSKU] = useState(false);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [contextAlert, setContexAlert] = useState<{
    context: string;
    type: any;
    messages?: any;
  }>({ context: '', type: 'success' });
  const [showModalDeleteSchedulePrice, setShowModalDeleteschedulePrice] = useState(false);
  const [order, setOrder] = useState('status.id');
  const [scheduledPriceSelected, setScheduledPriceSelected] = useState(null);
  const [typeModal, setTypeModal] = useState('');
  const [showSuccessModalSku, setShowSuccessModalSku] = useState(false);
  const { currentTenant, currentChannel } = useAccount()
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [typeOperation, setTypeOperation] = useState<'edit' | 'new'>();
  const [categoryId, setStateCategories] = useState([]);
  const [brandId, setStateBrands] = useState([]);

  const params = useMemo(() => ({
    pageNumber: currentPage,
    description,
    sellerId: seller,
    channel,
    statusId: status,
    scheduledAt,
    pageSize,
    order,
    categoryId,
    brandId
  }), [currentPage, seller, channel, status, scheduledAt, pageSize, order, categoryId, brandId])
  const { loading, paging, data: schedulePrices, repository: schedulePriceRepository, refetch: loadSchedulePrices } = useSchedulePricesPaginate(params)

  const links = [
    {
      text: t('app'),
      href: '/',
      use: 'link',
    },
    {
      text: t('main'),
      href: '/',
      disabled: false,
    },
    {
      text: t('scheduledPrice.title'),
      disabled: false,
    },
  ];

  useDidMountEffect(() => {
    setShowModalAlert(false);
    setShowModalProduct(false);
    setShowModalSKU(false);
    setShowSuccessModalSku(false);
    setShowModalDeleteschedulePrice(false);
    setShowModalDetails(false);
    setScheduledPriceSelected(null);
  }, [currentTenant]);

  const renderStatus = (rowData: any) => {
    if (rowData.isActive == false && rowData.status.status == StatusEnum.pending) {
      return (
        <InfraTag
          color="inactive"
          name={t('scheduledPrice.form.labels.statusInactive')}
          outline={false}
          borde={false}
        />
      );
    } else {
      let statusColor = new StatusColor();
      return (
        <InfraTag
          color={statusColor.status[rowData.status.status]}
          name={
            rowData.status
              ? t('scheduledPrice.fields.status.status.' + rowData.status.status)
              : t('scheduledPrice.fields.status.status.pending')
          }
        >
          {' '}
        </InfraTag>
      );
    }
  };

  const renderscheduledAt = (rowData: any) => {
    return format(new Date(rowData['scheduledAt']), 'dd.MM.yyyy HH:mm:ss');
  };

  const renderDelete = (priceData: any) => {
    if (priceData.status.status == StatusEnum.pending) {
      return (
        <a onClick={() => handleDelete(priceData)}>
          <InfraIcons name="TrashSimple" size={16} weight="bold" />
        </a>
      );
    }
  };

  const renderDetails = (priceData: any) => {
    if (priceData.status !== StatusEnum.canceled && priceData.status !== StatusEnum.expired) {
      return (
        <a onClick={() => handleDetailsPrice(priceData)}>
          <InfraIcons name="MagnifyingGlass" size={16} weight="bold" />
        </a>
      );
    }
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handlePagination = (event, value) => {
    setCurrentPage(value);
  };

  const handleSellers = (id, name) => {
    setCurrentPage(1);
    setSeller(id);
  };

  const handleSalesChannel = (channel) => {
    setCurrentPage(1);
    setChannel(channel.id);
  }


  const handleStatus = (id, status) => {
    setStatus(id);
    setCurrentPage(1);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setCurrentPage(1);
  };

  const renderFilterIcon = () => {
    return (
      <span style={{ marginInline: '10px' }}>
        <InfraIcons name="SortDescending" size={16} weight="bold" />
      </span>
    );
  };

  const handleDetailsPrice = async (schedulePrice) => {
    const schedulepriceFound: any = await schedulePriceRepository.findOne('1', schedulePrice.id);
    setScheduledPriceSelected(schedulepriceFound.data['scheduled-prices'][0]);
    setShowModalDetails(true);
    setTypeModal('edit');
  };

  const handleDelete = (schedulePrice) => {
    setScheduledPriceSelected(schedulePrice);
    setShowModalDeleteschedulePrice(true);
  };

  const handleOperation = async (result) => {
    setShowModalAlert(true);
    setShowModalProduct(false);
    setContexAlert({ context: 'createPriceByProduct', type: result.type, messages: result.messages });
    loadSchedulePrices();
  };

  const renderError = () => {
    if (contextAlert.messages && contextAlert.messages.length > 0) {
      return contextAlert.messages.map((el, key) => {
        return el.error ? (
          <span key={key}>
            {String(t(`scheduledPrice.form.textValidations.${el.error}`)).replace(':sku', el.skuName)}
          </span>
        ) : (
          <span key={key}>{String(t(`scheduledPrice.form.textValidations.Default`)).replace(':sku', el.skuName)}</span>
        );
      });
    }
  };

  const renderModalAlert = () => {
    if (contextAlert.context === 'delete') {
      if (contextAlert.type === ResultEnum.failed) {
        return 'Falha ao deletar o registro';
      } else if (contextAlert.type === ResultEnum.success) {
        return 'Registro deletado com sucesso';
      }
    } else if (contextAlert.context === 'createPriceByProduct') {
      if (contextAlert.type === ResultEnum.failed) {
        return t('scheduledPrice.form.textValidations.AllSkuAreadyExists');
      } else if (contextAlert.type === ResultEnum.alreadyExists) {
        return t('scheduledPrice.form.textValidations.AllSkuAreadyExists');
      } else if (contextAlert.type === ResultEnum.priceNotExists) {
        return t('scheduledPrice.form.textValidations.ScheduledPriceNotExists');
      } else if (contextAlert.type === 'ScheduledPriceNotExists') {
        return t('scheduledPrice.form.textValidations.ScheduledPriceNotExists');
      } else if (contextAlert.type === ResultEnum.success) {
        return t('scheduledPrice.form.textValidations.PriceCreateSuccess');
      } else if (contextAlert.type === ResultEnum.both) {
        return () => renderError();
      }
    }
  };

  const handleModalSkuCreate = () => {
    setShowModalSKU(true);
    setTypeModal('create');
  };

  const handleCloseModal = () => {
    setShowModalSKU(false);
    loadSchedulePrices();
    setScheduledPriceSelected(null);
  };

  const handleCloseModalDetails = () => {
    setShowModalDetails(false);
    loadSchedulePrices();
    setScheduledPriceSelected(null);
  };

  const handleSuccessModalSku = (typeOperation) => {
    setTypeOperation(typeOperation);
    setShowSuccessModalSku(true);
    setScheduledPriceSelected(null);
    loadSchedulePrices();
  };

  const renderNumber = (value) => {
    return <span>{value ? FormatCurrency.format('BRL', value) : value}</span>;
  };

  const renderModalSuccessSku = () => {
    if (typeOperation === 'edit') {
      return t('successUpdateMessageDefault');
    }
    return t('successCreateMessageDefault');
  };

  const renderBreadcrumb = () => {
    return (
      <Box>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </Box>
    );
  };

  function handleMultiSelectCategory(data) {
    if (data.length > 0) {
      setCurrentPage(1);
      setStateCategories(data);
    } else {
      setStateCategories([]);
    }
  }

  function handleMultiSelectBrands(data) {
    setCurrentPage(1);
    if (data.length > 0) {
      setStateBrands(data);
    } else {
      setStateBrands([]);
    }
  }

  const renderTitle = () => {
    return (
      <Flex justify={'space-between'} align={'center'}>
        <InfraTypography variant={'h1_small'} weight={'bold'}>
          {t('scheduledPrice.title')}
        </InfraTypography>
        <Flex gap="8px" direction="row" justify={'end'} align={'center'}>
          <Box>
            <Button
              color="neutral"
              size="large"
              outline={false}
              onClick={handleModalSkuCreate}
              icon={'Plus'}
              iconRight={true}
            >
              <InfraTypography variant={'body_small'} weight={'semibold'} color={Colors.shade_0}>
                {t('scheduledPrice.button.sku')}
              </InfraTypography>
            </Button>
          </Box>
          <Box>
            <Button
              color="neutral"
              size="large"
              outline={false}
              onClick={() => setShowModalProduct(true)}
              icon={'Plus'}
              iconRight={true}
            >
              <InfraTypography variant={'body_small'} weight={'semibold'} color={Colors.shade_0}>
                {t('scheduledPrice.button.product')}
              </InfraTypography>
            </Button>
          </Box>
        </Flex>
      </Flex>
    );
  };

  function isClean(data) {
    if (data) {
      setScheduledAt('');
    }
  }

  const renderToolbar = () => {
    return (
      <Flex>
        <Flex gap="8px" direction="row" grow="0" shrink="1" basis="auto">
          <Box w="159.01px">
           <InfraLabel color="neutral_600">Channel</InfraLabel>
            <AutocompleteChannels
              onChange={handleSalesChannel}
            />
          </Box>
          <Box w="159.01px">
            <InfraLabel color="neutral_600">{t('scheduledPrice.fields.autocomplete.seller.label')}</InfraLabel>
            <AutocompleteSellers
              versionId="1"
              onChange={handleSellers}
              channelId={channel}
              placeholder={t('scheduledPrice.fields.autocomplete.seller.placeholder')}
            />
          </Box>

          <Box w="330px">
            <InfraLabel color="neutral_600">{t('scheduledPrice.fields.filter.period.label')}</InfraLabel>
            <div>
              <InfraDatepicker
                onCleared={isClean}
                height="small"
                autoComplete={'off'}
                subtractYears={10}
                placeholder={t('scheduledPrice.fields.filter.period.placeholder')}
                value={''}
                onChange={async (value, name) => {
                  if (Array.isArray(value)) {
                    setScheduledAt(format(new Date(value[0]), 'yyyy-MM-dd HH:mm:ss'));
                  } else {
                    setScheduledAt(format(new Date(value), 'yyyy-MM-dd HH:mm:ss'));
                  }
                  setCurrentPage(1);
                }}
                theme="light"
                setHours={true}
                maskDate="dd.MM.yyyy, HH:mm"
                shouldCloseOnSelect={false}
              />
            </div>
          </Box>

          <Box w="159px">
            <InfraLabel color="neutral_600">{t('price.fields.multselect.category.label')}</InfraLabel>
            <MultiSelectCategories
              limitTags={1}
              value={categoryId}
              onChange={(data) => {
                handleMultiSelectCategory(data);
              }}
              display="count"
              placeholder={t('price.fields.multselect.category.placeholder')}
            />
          </Box>

          <Box w="159px">
            <InfraLabel color="neutral_600">{t('price.fields.multselect.brands.label')}</InfraLabel>
            <MultiSelectBrands
              limitTags={1}
              value={brandId}
              display="count"
              onChange={(data) => {
                handleMultiSelectBrands(data);
              }}
              placeholder={t('price.fields.multselect.brands.placeholder')}
            />
          </Box>

          <Box w="160px">
            <InfraLabel color="neutral_600">{t('scheduledPrice.fields.autocomplete.status.label')}</InfraLabel>
            <AutocompleteStatus
              versionId="1"
              onChange={handleStatus}
              placeholder={t('scheduledPrice.fields.autocomplete.status.placeholder')}
              typePrice="scheduled"
            />
          </Box>

          <Box w="300px">
            <InfraLabel color="neutral_600">{t('price.fields.search.label')}</InfraLabel>
            <InfraTextField
              id="1"
              inputStyle="normal"
              onChange={() => {}}
              onChangeValue={(v) => setDescription(v.target.value)}
              placeholder={t('price.fields.search.placeholder')}
              type="text"
              style={{ height: '45px' }}
            />
          </Box>
        </Flex>
      </Flex>
    );
  };

  const displayCategories = (categories) => {
    if (!categories?.length) {
      return '-';
    }
    return categories?.map((c) => c?.name).join(', ');
  };

  const renderDataTable = () => {
    return (
      <InfraTable value={schedulePrices} loading={loading} messageEmptyData={t('emptyTable')}>
        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'sku.skuId' ? '' : 'sku.skuId')}
          field="skuId"
          header={t('price.fields.table.columnHeaders.skuId')}
          headerAlign="left"
        >
          {order === 'sku.skuId' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'product.productId' ? '' : 'product.productId')}
          field="productId"
          header={t('price.fields.table.columnHeaders.productId')}
          headerAlign="left"
        >
          {order === 'product.productId' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'sku.externalSku' ? '' : 'sku.externalSku')}
          field="externalSku"
          header={t('price.fields.table.columnHeaders.externalSku')}
          headerAlign="left"
          body={({ externalSku }) => externalSku || '-'}
        >
          {order === 'sku.externalSku' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'product.name' ? '' : 'product.name')}
          field="product"
          header={t('price.fields.table.columnHeaders.product')}
          headerAlign="left"
          body={({ product, sku }) => <Tooltip text={`${product} - ${sku}`} limit={35} />}
        >
          {order === 'product.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'scheduled_prices.highPrice' ? '' : 'scheduled_prices.highPrice')}
          field="highPrice"
          header={t('scheduledPrice.fields.table.columnHeaders.highPrice')}
          headerAlign="left"
          body={({ highPrice }) => renderNumber(highPrice)}
        >
          {order === 'scheduled_prices.highPrice' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'scheduled_prices.price' ? '' : 'scheduled_prices.price')}
          field="price"
          header={t('scheduledPrice.fields.table.columnHeaders.price')}
          headerAlign="left"
          body={({ price }) => renderNumber(price)}
        >
          {order === 'scheduled_prices.price' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'brand.name' ? '' : 'brand.name')}
          field="brand"
          header={t('price.fields.table.columnHeaders.brand')}
          headerAlign="left"
          body={({ brand }) => brand || '-'}
        >
          {order === 'brand.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'categories.name' ? '' : 'categories.name')}
          field="category"
          header={t('price.fields.table.columnHeaders.category')}
          headerAlign="left"
          body={({ categories }) => displayCategories(categories)}
        >
          {order === 'categories.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="seller"
          clickable
          onClick={(_, field) => setOrder(order === 'scheduled_prices.seller' ? '' : 'scheduled_prices.seller')}
          header={t('price.fields.autocomplete.seller.label')}
          headerAlign="left"
        >
          {order === 'scheduled_prices.seller' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="scheduledAt"
          clickable
          onClick={(_, field) =>
            setOrder(order === 'scheduled_prices.scheduledAt' ? '' : 'scheduled_prices.scheduledAt')
          }
          header={t('scheduledPrice.fields.table.columnHeaders.activation')}
          headerAlign="left"
          body={renderscheduledAt}
        >
          {order === 'scheduled_prices.scheduledAt' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="status"
          header="Status"
          align="left"
          headerAlign="left"
          body={renderStatus}
          clickable
          onClick={(_, field) => setOrder(order === 'status.id' ? '' : 'status.id')}
        >
          {order === 'status.id' && renderFilterIcon()}
        </InfraTableColumn>
        <InfraTableColumn
          field="priceId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={renderDetails}
        ></InfraTableColumn>
        <InfraTableColumn
          field="priceId"
          header=""
          headerAlign="left"
          padding="checkbox"
          body={renderDelete}
        ></InfraTableColumn>
      </InfraTable>
    );
  };

  const renderPagination = () => {
    return (
      <Paginate
        onChangePageSize={handlePageSize}
        onChangePage={handlePagination}
        totalPages={paging?.totalPages}
        currentPage={currentPage}
      />
    );
  };

  return (
    <>
      <InfraGrid>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'31px'}>{renderBreadcrumb()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'32px'}>{renderTitle()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'22px'}>{renderToolbar()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'-10px'}>{renderDataTable()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt="4px" mb="64px">
            {renderPagination()}
          </Box>
        </InfraCol>
      </InfraGrid>

      <WrapperModalSkuScheduledPrice>
        <InfraModal
          theme="light"
          show={showModalProduct}
          onClose={() => setShowModalProduct(false)}
          title={t('scheduledPrice.modais.titles.product')}
        >
          <ModalProduct onCloseModal={(result) => handleOperation(result)} />
        </InfraModal>
      </WrapperModalSkuScheduledPrice>

      <WrapperModalSkuScheduledPrice>
        <InfraModal
          theme="light"
          show={showModalSKU}
          onClose={handleCloseModal}
          title={typeModal === 'create' ? 'Cadastrar por SKU' : 'Editar por SKU'}
        >
          <ModalSku
            onCloseModal={setShowModalSKU}
            price={scheduledPriceSelected}
            cbAlertModal={handleSuccessModalSku}
          />
        </InfraModal>
      </WrapperModalSkuScheduledPrice>

      <WrapperModalDetail>
        <InfraModal
          theme="light"
          show={showModalDetails}
          onClose={handleCloseModalDetails}
          title={'Detalhes da Precificação Agendada'}
          width="350"
        >
          <ModalDetails
            onCloseModal={({ result }) => {
              setShowModalDetails(false);
              if (result) {
                loadSchedulePrices();
                setContexAlert({ context: 'delete', type: result });
                setShowModalAlert(true);
              }
              setScheduledPriceSelected(null);
            }}
            price={scheduledPriceSelected}
          />
        </InfraModal>
      </WrapperModalDetail>

      <WrapperModalConfirm>
        <InfraModal
          theme="light"
          show={showModalDeleteSchedulePrice}
          onClose={() => {
            setShowModalDeleteschedulePrice(false);
            setScheduledPriceSelected(null);
          }}
        >
          <ModalDelete
            onCloseModal={({ result }) => {
              setShowModalDeleteschedulePrice(false);
              if (result) {
                loadSchedulePrices();
                setContexAlert({ context: 'delete', type: result });
                setShowModalAlert(true);
              }
              setScheduledPriceSelected(null);
            }}
            price={scheduledPriceSelected}
          />
        </InfraModal>
      </WrapperModalConfirm>

      <AlertModal
        variation={contextAlert.type === 'both' ? 'failed' : contextAlert.type}
        message={renderModalAlert()}
        show={showModalAlert}
        onClose={() => setShowModalAlert(false)}
        width="278"
        onConfirmModal={() => setShowModalAlert(false)}
      />

      <AlertModal
        message={renderModalSuccessSku}
        show={showSuccessModalSku}
        onClose={() => setShowSuccessModalSku(false)}
        width="278"
        onConfirmModal={() => setShowSuccessModalSku(false)}
      />
    </>
  );
};

export default ScheduledricesComponent;
