import { Product } from '../../../Model';
import { memo, useContext, useEffect, useState } from 'react';

import { InfraSelect } from '@infralabs/design-system';
import { ProductRepository } from '../../../Repository';
import CurrentChannelContext, { useAccount } from '../../CurrentAccount';
import useDidMountEffect from '../../../hooks/use-did-mount-effect';
import { useDebounce } from '../../../hooks/debounce';

const rep = new ProductRepository();

type Props = {
  onChange: (data: any) => void;
  value?: any;
  placeholder?: string;
  disable?: boolean;
  externalId?: boolean;
  size?: 'small' | 'medium' | 'large';
  search?: boolean;
  searchPlaceholder?: string;
};

const PriceSelectProducts = ({
  onChange,
  placeholder,
  value,
  disable,
  externalId = false,
  size = 'small',
  search = false,
  searchPlaceholder = '',
}: Props) => {
  const { currentTenant, currentChannel } = useAccount();
  const [items, setItems] = useState<{ label: string; value: any; selected?: boolean }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>();

  function prepareItemsSelect(data: Product[]) {
    const options = data.map((el: Product) => ({
      label: el.name,
      value: el,
      selected: value && value === el.productId ? true : false,
    }));
    setItems(options);
  }

  const fetch = async () => {
    if (!inputSearch) return
    setLoading(true);
    (async () => {
      try {
        const { records } = await rep.setChannelId(currentChannel.id).findAll('1', { search: inputSearch });
        prepareItemsSelect(records);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setItems([]);
      }
    })();
  };

  useDidMountEffect(() => {
    if (!currentChannel) return
    fetch();
  }, [currentTenant, currentChannel]);

  useDebounce(() => {
    fetch();
  }, [inputSearch]);

  async function searchByCriteria(searchCriteria) {
    if (!searchCriteria) {
      return;
    }
    setInputSearch(searchCriteria);
  }
  return (
    <InfraSelect
      customTagColor="black"
      items={items}
      loading={loading}
      handlerClick={onChange}
      placeholder={placeholder}
      disable={disable}
      size={size}
      search={search}
      searchPlaceholder={searchPlaceholder}
      handlerSearch={searchByCriteria}
    />
  );
};

export const SelectProducts = memo(PriceSelectProducts)
