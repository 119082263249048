import styled from "styled-components";
import Colors from "../../../../Helper/Colors";

export const WrapperModal = styled.div`
   .wrapper-body-modal {
     width: 491px;
     .wrapper-relative {
      .modal-body {
        padding-top: 24px;
        padding-bottom: 32px;
        padding-inline-end: 36px;
        padding-inline-start: 37px;
      }
     }
   }
`;

export const Content = styled.div`
  margin-top: -15px;
`
export const Icon = styled.div`
  padding: 0;
  text-align: center;
`
export const Titles = styled.div`
  margin-top: 10px;
  min-height: 48px;
`;

export const ContentScrollable = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 16px;
`;

export const Scrollable = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
      background-color: ${Colors.neutral_100};
  }
  ::-webkit-scrollbar-thumb {
      background-color: ${Colors.neutral_500};
      border-radius: 4px;
  }
`

export const ContentButton = styled.div`
  margin-top: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  button {
    background-color: ${Colors.information_100};
    border: 1px solid ${Colors.information_100};
    :hover {
      background-color: ${Colors.information_200};
      border: 1px solid ${Colors.information_50};
    }
    :active {
      background-color: ${Colors.information_100};
      border: 1px solid ${Colors.information_50};
    }
    :focus {
      border: 2px solid ${Colors.information_200};
    }
  }
`