import { useEffect, useMemo, useState } from 'react';
import { useAccount } from '../../components';
import { PriceRepository } from '../../Repository';

type UsePricesPaginateProps = {
  currentPage?: number;
  pageSize: number;
  seller: string;
  channel?: string;
  statusId: string;
  description: string;
  categoryId: any[];
  clusterIds?: any[];
  order: any;
};

type PricesParam = {
  pageNumber: number;
  pageSize: number;
  sellerId: string;
  statusId: string;
  description: string;
  category?: string;
  clusterId?: string;
  order?: string;
};

let timeout;
const priceRepository = new PriceRepository();
const usePricesPaginate = (props: UsePricesPaginateProps) => {
  const [loading, setLoading] = useState(true);
  const { currentChannel } = useAccount();
  const [data, setData] = useState<{
    data: any[];
    paging: {
      recordsReturned?: number;
      totalPages?: number;
      totalRecords?: number;
      currentPage?: number;
    };
  }>({
    data: [],
    paging: {},
  });

  const fetchPrices = async () => {
    priceRepository.source.cancel();
    const { categoryId, description, order, pageSize, seller, channel, statusId, clusterIds, currentPage } = props;

    try {
      setLoading(true);
      let params: PricesParam = {
        pageNumber: currentPage || 1,
        pageSize: pageSize,
        sellerId: seller,
        statusId: statusId,
        description: description,
      };

      if (categoryId?.length) {
        params.category = categoryId?.map((e) => e.value).join(',');
      }

      if (clusterIds?.length) {
        params.clusterId = clusterIds?.map((e) => e.value).join(',');
      }

      if (order) {
        params.order = order;
      }

      const { paging, records } = await priceRepository
        .setChannelId(channel || currentChannel.id)
        .findPaginate('1', params);
      setData({
        paging,
        data: records,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    clearTimeout(timeout);
    if (!currentChannel) return;
    timeout = setTimeout(() => {
      fetchPrices();
    }, 300);
  }, [props, currentChannel]);

  useEffect(() => {
    return () => priceRepository.source.cancel();
  }, []);

  return {
    loading,
    data: data.data,
    paging: data.paging,
    refetch: fetchPrices,
  };
};

export default usePricesPaginate;
