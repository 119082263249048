import React from 'react';
import LayoutWrapper, { LayoutProps } from '../shared/share-styled';

export interface FlexProps extends LayoutProps {
  children: React.ReactNode;
}

export const Flex = React.forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  const styles = {
    display: 'flex'
  }
  return <LayoutWrapper ref={ref} {...props} style={styles}/>
});
