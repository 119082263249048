import {
  InfraButton,
  InfraInputCurrency,
  InfraLabel,
  InfraSwitch,
  InfraTag,
  InfraTextField,
  InfraTypography,
} from '@infralabs/design-system';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ClustersSelect from '../../components/Selects/ClientClusters';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prices } from '../../Model/Prices';
import { PriceRepository } from '../../Repository/PriceRepository';
import {
  AutocompleteSellers,
  Box,
  BoxErrorMessage,
  RowErrorMessage,
  SelectSkus,
  WrapperButtonModal,
  WrapperField,
  WrapperSwitchModal,
} from '../../components';

import { RegEx, getValueValid } from '../../Helper';
import { WrapperMessage } from '../../components/AlertMessage/styles';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';
import { AutocompleteClusters } from '../../components/AutocompleteCluster';

const ModalSku = ({ onCloseModal, price, cbAlertModal }) => {
  const { t } = useTranslation();

  const formLabel = {
    profitMargin: t('price.form.labels.profitMargin'),
    costPrice: t('price.form.labels.costPrice'),
    price: t('price.form.labels.price'),
    highPrice: t('price.form.labels.highPrice'),
    sku: t('price.form.labels.sku'),
    seller: t('price.form.labels.seller'),
    statusActive: t('price.form.labels.statusActive'),
    statusInactive: t('price.form.labels.statusInactive'),
  };

  const formPlaceholder = {
    profitMargin: t('price.form.placeholders.profitMargin'),
    costPrice: t('price.form.placeholders.costPrice'),
    price: t('price.form.placeholders.price'),
    highPrice: t('price.form.placeholders.highPrice'),
    product: t('price.form.placeholders.product'),
    sku: t('price.form.placeholders.sku'),
    seller: t('price.form.placeholders.seller'),
    searchProduct: t('price.form.placeholders.searchProduct'),
  };

  const schema = Yup.object().shape({
    highPrice: Yup.string(),
    price: Yup.string().required(t('textValidations.required')),
    seller: Yup.string().required(t('textValidations.required')),
    profitMargin: Yup.string().matches(RegEx.percentage(), t('textValidations.invalid')).notRequired(),
    sku: Yup.string().required(t('textValidations.required')),
  });

  const [checked, setChecked] = useState(price?.isActive || false);
  const [sellerAutoComplete, setSellerAutoComplete] = useState('');
  const [skuId, setSkuId] = useState(price?.skuId);
  const [showMessageAutoComplete, setShowMessageAutoComplete] = useState(false);
  const [errorAlert, setErrorAlert] = useState('');

  const handleValues = (values) => {
    let { price, highPrice, costPrice, profitMargin, salesChannel, clusterId } = values;
    price = price.toString();
    highPrice = highPrice?.toString() ?? undefined;
    costPrice = costPrice.toString();
    price = price.toString();

    let priceReplace = null;
    if (price.indexOf(',') !== -1) {
      priceReplace = Number(price.replace(',', '.'));
    } else {
      priceReplace = Number(values.price);
    }

    let highPriceReplace = null;
    if (highPrice !== undefined) {
      if (highPrice?.indexOf(',') !== -1) {
        highPriceReplace = Number(highPrice.replace(',', '.'));
      } else {
        highPriceReplace = Number(values.highPrice);
      }
    }

    if (highPriceReplace === 0) {
      highPriceReplace = undefined;
    }

    let costPriceReplace = null;
    if (!costPrice || costPrice === '') {
      costPriceReplace = 0;
    } else if (costPrice.indexOf(',') !== -1) {
      costPriceReplace = Number(costPrice.replace(',', '.'));
    } else {
      costPriceReplace = Number(costPrice);
    }

    let profitMarginReplace = 0;
    if (profitMargin === undefined || profitMargin === '' || profitMargin.length === 0) {
      profitMarginReplace = 0;
    } else {
      profitMarginReplace = Number(profitMargin);
    }

    return { priceReplace, highPriceReplace, costPriceReplace, profitMarginReplace, clusterId };
  };

  const handleSubmit = async (values, actions) => {
    const rep = new PriceRepository();
    let { priceReplace, highPriceReplace, costPriceReplace, profitMarginReplace, clusterId } = await handleValues(
      values,
    );

    actions.setSubmitting(false);

    try {
      if (price) {
        const data = [
          {
            id: price.id,
            skuId: skuId,
            currency: 986,
            costPrice: costPriceReplace,
            isActive: checked,
            highPrice: highPriceReplace ?? null,
            price: priceReplace,
            sellerId: price.sellerId,
            profitMargin: profitMarginReplace,
            clusterId: clusterId,
          } as Prices,
        ];
        await rep.setChannelId(values.salesChannel).update('1', data);
        onCloseModal(false);
        cbAlertModal({ typeOperation: 'edit' });
      } else {
        if (sellerAutoComplete === '') {
          setShowMessageAutoComplete(true);
          return;
        }
        const data = [
          {
            skuId: skuId,
            currency: 986,
            highPrice: highPriceReplace,
            price: priceReplace,
            costPrice: costPriceReplace,
            isActive: checked,
            sellerId: sellerAutoComplete,
            profitMargin: profitMarginReplace,
            clusterId: clusterId,
          } as Prices,
        ];
        await rep.setChannelId(values.salesChannel).save('1', data);
        onCloseModal(false);
        cbAlertModal({ typeOperation: 'new' });
      }
    } catch (error) {
      if (error.response.data.data) {
        error.response.data.data.prices.map((price) => {
          if (price.error === 'SkuAreadyExists') {
            actions.setFieldError('sku', String(t('price.form.textValidations.SkuAreadyExists')).replace(':sku', ''));
          }
        });
      }
    }
  };

  const handleSwitch = (_, checkedEvent) => {
    setChecked(checkedEvent);
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          sku: price?.sku || '',
          highPrice: price?.highPrice || '',
          price: price?.price || '',
          profitMargin: price?.profitMargin || '',
          costPrice: price?.costPrice || '',
          isActive: price?.isActive || false,
          seller: price?.seller || '',
          salesChannel: price?.salesChannel || '',
          clusterId: price?.cluster && price?.cluster.length > 0 ? price?.cluster[0].id : null,
        }}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: any = {};
          if (values.highPrice !== '') {
            if (String(values.highPrice).charAt(0) === ',') {
              errors.highPrice = t('textValidations.invalid');
            }
            if (Number(String(values.price).replace(',', '.')) > Number(String(values.highPrice).replace(',', '.'))) {
              errors.price = t('price.form.textValidations.priceBiggerThen');
            }
          }
          if (values.price !== '') {
            if (String(values.price).charAt(0) === ',') {
              errors.price = t('textValidations.invalid');
            }
            if (Number(String(values.price).replace(',', '.')) === 0) {
              errors.price = String(t('textValidations.min')).replace(':min', '0.01');
            }
          }

          return errors;
        }}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <Form>
            {errorAlert ? <BoxErrorMessage>{errorAlert}</BoxErrorMessage> : null}
            <WrapperField>
              <AutocompleteChannels
                readonly={!!price?.channels?.[0]?.id}
                labelBoxProps={{
                  mt: '24px',
                }}
                value={price?.channels?.[0]?.id}
                label="Channels"
                onChange={(salesChannel) => {
                  setFieldValue('salesChannel', salesChannel.id);
                }}
              />
            </WrapperField>
            <WrapperField>
              {price !== null ? (
                <>
                  <Box mt="24px">
                    <InfraLabel color="neutral_600">{formLabel.seller}</InfraLabel>
                  </Box>
                  <InfraTextField disabled={true} name="seller" value={price.seller} height="small" />
                </>
              ) : (
                <>
                  <Box mt="24px">
                    <InfraLabel color="neutral_600">{formLabel.seller}</InfraLabel>
                  </Box>
                  <AutocompleteSellers
                    versionId="1"
                    channelId={values.salesChannel ?? false}
                    onChange={(sellerId, sellerName) => {
                      setFieldValue('seller', sellerName);
                      setSellerAutoComplete(sellerId);
                    }}
                    placeholder={formPlaceholder.seller}
                  />
                  {errors.seller && touched.seller && <WrapperMessage>{errors.seller}</WrapperMessage>}
                </>
              )}
              {showMessageAutoComplete ? <RowErrorMessage>Seller é obrigatório</RowErrorMessage> : null}
            </WrapperField>
            <div>
              <WrapperField>
                {price !== null ? (
                  <>
                    <InfraTextField disabled={true} name="seller" value={price.sku} label="SKU" height="small" />
                  </>
                ) : (
                  <>
                    <InfraLabel color="neutral_600">{formLabel.sku}</InfraLabel>
                    <SelectSkus
                      search={true}
                      value={values.sku}
                      onChange={(data) => {
                        setFieldValue('sku', data.value, true);
                        setSkuId(data.value);
                      }}
                      placeholder={formPlaceholder.sku}
                      searchPlaceholder={formPlaceholder.searchProduct}
                      channelId={values.salesChannel ?? false}
                      sellerId={sellerAutoComplete ?? false}
                    />
                    {errors.sku && touched.sku && <RowErrorMessage>{errors.sku}</RowErrorMessage>}
                  </>
                )}
              </WrapperField>
              <WrapperField>
                {price !== null ? (
                  <>
                    <InfraTextField
                      disabled={true}
                      name="seller"
                      value={price?.cluster && price?.cluster.length > 0 ? price?.cluster?.[0].name : ''}
                      label={t('price.form.fields.clusters.label', 'Cluster')}
                      height="small"
                      placeholder=""
                    />
                  </>
                ) : (
                  <>
                    <InfraLabel color="neutral_600" optional={true}>
                      {t('price.form.fields.clusters.label', 'Cluster')}
                    </InfraLabel>

                    <AutocompleteClusters
                      placeholder={t('price.form.fields.clusters.placeholder', 'Selecione')}
                      disable={!!price}
                      defaultValue={
                        price?.cluster && price?.cluster.length > 0
                          ? {
                              label: price?.cluster?.[0].name,
                              value: price?.cluster?.[0].id,
                            }
                          : null
                      }
                      onChange={(cluster) => {
                        setFieldValue('clusterId', cluster?.value);
                      }}
                    />
                  </>
                )}
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600" optional={true}>
                  {formPlaceholder.highPrice}
                </InfraLabel>
                <InfraInputCurrency
                  name="highPrice"
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  autoComplete="off"
                  onBlur={handleBlur}
                  placeholder={formPlaceholder.highPrice}
                  value={values.highPrice}
                  onValueChange={(value) => setFieldValue('highPrice', getValueValid(value))}
                  decimalSeparator=","
                  decimalsLimit={2}
                  inputStyle={errors.highPrice && touched.highPrice ? 'error' : 'normal'}
                  height="small"
                  message={errors.highPrice && touched.highPrice ? errors.highPrice : ''}
                />
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600">{formLabel.price}</InfraLabel>
                <InfraInputCurrency
                  name="price"
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  autoComplete="off"
                  onBlur={handleBlur}
                  placeholder={formPlaceholder.price}
                  value={values.price}
                  onValueChange={(value) => setFieldValue('price', getValueValid(value))}
                  decimalSeparator=","
                  decimalsLimit={2}
                  inputStyle={errors.price && touched.price ? 'error' : 'normal'}
                  height="small"
                  message={errors.price && touched.price ? errors.price : ''}
                />
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600" optional={true}>
                  {formLabel.profitMargin}
                </InfraLabel>
                <InfraTextField
                  autoComplete="off"
                  name="profitMargin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.profitMargin}
                  placeholder={formPlaceholder.profitMargin}
                  inputStyle={errors.profitMargin && touched.profitMargin ? 'error' : 'normal'}
                  height="small"
                  message={errors.profitMargin && touched.profitMargin ? errors.profitMargin : ''}
                />
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600" optional={true}>
                  {formLabel.costPrice}
                </InfraLabel>
                <InfraInputCurrency
                  name="costPrice"
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  autoComplete="off"
                  onBlur={handleBlur}
                  placeholder={formPlaceholder.costPrice}
                  decimalSeparator=","
                  decimalsLimit={2}
                  defaultValue={0}
                  value={values.costPrice}
                  onValueChange={(value) => setFieldValue('costPrice', getValueValid(value))}
                  inputStyle={errors.costPrice && touched.costPrice ? 'error' : 'normal'}
                  height="small"
                  message={errors.costPrice && touched.costPrice ? errors.costPrice : ''}
                />
              </WrapperField>
              <WrapperSwitchModal>
                <div className="statusStyle">
                  <InfraTypography variant={'h5_small'} weight={'regular'} style={{ marginRight: '9px' }}>
                    Status
                  </InfraTypography>
                  <InfraSwitch checked={checked} onChange={(_, checkedEvent) => handleSwitch(_, checkedEvent)} />
                </div>
                {checked ? (
                  <div>
                    <InfraTag color="active" name={formLabel.statusActive} outline={false} borde={false} />
                  </div>
                ) : (
                  <div>
                    <InfraTag color="inactive" name={formLabel.statusInactive} outline={false} borde={false} />
                  </div>
                )}
              </WrapperSwitchModal>
              <WrapperButtonModal>
                <InfraButton
                  color="neutral"
                  type="submit"
                  size="large"
                  fullWidth={true}
                  outline={false}
                  onClick={() => onCloseModal(true)}
                >
                  {t('price.form.button.sku')}
                </InfraButton>
              </WrapperButtonModal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ModalSku;
