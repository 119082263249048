import { InfraTooltip } from '@infralabs/design-system';

const Tooltip = ({ text, limit }: { text: string; limit?: number }) => {
  if (text.length > limit) {
    return (
      <InfraTooltip message={text} position="top" theme="dark" width={250}>
        {`${text.substring(0, limit)}...`}
      </InfraTooltip>
    );
  }
  return <>{text}</>;
};

export default Tooltip;
