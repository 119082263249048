import React, { useState, ChangeEvent } from 'react';
import { InfraTextField } from '@infralabs/design-system';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const formatZipCode = (zipCode: unknown): string => {
  if (typeof zipCode === 'number') {
    zipCode = zipCode.toString();
  }

  if (typeof zipCode !== 'string') {
    return '';
  }

  const pattern = /^\d{5}-\d{3}$/;
  const cleanZipCode = zipCode.replace(/\D/g, '');

  if (pattern.test(zipCode)) {
    return zipCode;
  } else if (cleanZipCode.length === 8) {
    return `${cleanZipCode.slice(0, 5)}-${cleanZipCode.slice(5, 8)}`;
  } else {
    return cleanZipCode;
  }
};

const ZipCodeInput: React.FC<Props> = ({ value = '', onChange, disabled = false }) => {
  const [zipCode, setZipCode] = useState<string>(value);

  const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newZipCode = event.target.value.replace(/\D/g, '');

    if (newZipCode.length == 9) {
      return;
    }

    setZipCode(formatZipCode(newZipCode));

    if (onChange) {
      onChange(newZipCode.replace(/\D/g, ''));
    }
  };

  return (
    <InfraTextField
      value={zipCode}
      onChange={handleZipCodeChange}
      placeholder={'00000-000'}
      disabled={disabled}
      inputStyle={'normal'}
      message={''}
      height="small"
    ></InfraTextField>
  );
};

export default ZipCodeInput;
