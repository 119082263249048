import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';

import { useEffect } from 'react';
import ChargesComponent from '../Pages/Charges';
import { ChargeDetail } from '../Pages/Charges/Detail';
import RegisterChargeForm from '../Pages/Charges/Form';
import Home from '../Pages/Home';
import PricesComponent from '../Pages/Prices';
import ExportPricesComponent from '../Pages/Prices/export';
import { ImportPricesComponent } from '../Pages/Prices/Import';
import ProgrammedPricesComponent from '../Pages/ProgrammedPrices';
import PromotionsComponent from '../Pages/Promotions';
import { PromotionDetail } from '../Pages/Promotions/Detail';
import RegisterPromotionForm from '../Pages/Promotions/Form';
import ScheduledricesComponent from '../Pages/ScheduledPrices';

export const PageLayout = ({ children }) => {
  const navigate = useHistory();
  useEffect(() => {
    function handleRouteChange(event) {
      navigate.push(event.detail.newRoute);
    }
    window.addEventListener('changeRoute', handleRouteChange);
    return () => {
      window.removeEventListener('changeRoute', handleRouteChange);
    };
  }, []);
  return children;
};

export default function Routes({ basePath }) {
  return (
    <BrowserRouter basename={basePath}>
      <PageLayout>
        <Switch>
          <Route path="/prices" component={PricesComponent} exact />
          <Route path="/promotions" component={PromotionsComponent} exact />
          <Route path="/promotions/new" component={RegisterPromotionForm} exact />
          <Route path="/promotions/:id/edit" component={RegisterPromotionForm} exact />
          <Route path="/promotion/:id/details" component={PromotionDetail} exact />
          <Route path="/charges" component={ChargesComponent} exact />
          <Route path="/charges/new" component={RegisterChargeForm} exact />
          <Route path="/charges/:id/edit" component={RegisterChargeForm} exact />
          <Route path="/charge/:id/details" component={ChargeDetail} exact />
          <Route path="/prices/exports" component={ExportPricesComponent} exact />
          <Route path="/prices/imports" component={ImportPricesComponent} exact />
          <Route path="/programmed-prices" component={ProgrammedPricesComponent} exact />
          <Route path="/scheduled-prices" component={ScheduledricesComponent} exact />
          <Route path="/" component={Home} />
        </Switch>
      </PageLayout>
    </BrowserRouter>
  );
}
