import * as React from 'react';
import * as ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './Pages/App';

const options = {
  React,
  ReactDOM,
  domElementGetter: () => document.getElementById('mfe_div_connector'),
  rootComponent: App,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
};

if (process.env.STANDALONE) {
  delete options.domElementGetter;
}
const lifecycles = singleSpaReact(options);

export const { bootstrap, mount, unmount } = lifecycles;
export { App }