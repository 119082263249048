import { Product } from '../../../Model';
import { useContext, useEffect, useState } from 'react';

import { InfraSelect } from '@infralabs/design-system';
import { ProductRepository } from '../../../Repository';
import { useTranslation } from 'react-i18next';
import CurrentChannelContext from '../../CurrentAccount';

const rep = new ProductRepository();

type Props = {
  onChange: (data: any) => void;
  value?: any;
  placeholder?: string;
  disable?: boolean;
  limitTags?: number;
  externalId?: boolean;
  display?: 'chip' | 'count';
  size?: 'small' | 'medium' | 'large';
  search?: boolean;
  searchPlaceholder?: string;
};

export const MultiSelectProducts = ({
  onChange,
  placeholder,
  value,
  disable,
  limitTags = 2,
  externalId = false,
  display = 'chip',
  size = 'small',
  search = false,
  searchPlaceholder = '',
}: Props) => {
  const { currentTenant } = useContext(CurrentChannelContext);
  const [items, setItems] = useState<{ label: string; value: string; selected?: boolean }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>();

  const { t } = useTranslation();

  function prepareItemsSelect(data: Product[]) {
    const options = data.map((el: Product) => ({
      label: el.name,
      value: el.productId,
      selected: value?.find((x) => x.value === el.productId) ? true : false,
    }));
    setItems(options);
  }

  function itemsSelected() {
    return items.filter((item) => item.selected === true);
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { records } = await rep.findAll('1', {search: inputSearch});
        prepareItemsSelect(records);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setItems([]);
      }
    })();
  }, [currentTenant, inputSearch]);

  useEffect(() => {
    const options = items.map((item) => ({
      ...item,
      selected: value?.find((x) => x.value === item.value) ? true : false,
    }));
    setItems(options);
  }, [value]);

  async function searchByCriteria(searchCriteria) {
    setInputSearch(searchCriteria);
  }
  return (
    <>
      <InfraSelect
        customTagColor='black'
        customColorCheckbox='neutral'
        multiple={true}
        items={items}
        loading={loading}
        handlerClick={onChange}
        placeholder={placeholder}
        disable={disable}
        checkbox={true}
        limitTags={limitTags}
        display={display}
        customMultiSelectedItemText={t('customMultiSelectedItemText', { count: itemsSelected().length })}
        size={size}
        search={search}
        searchPlaceholder={searchPlaceholder}
        handlerSearch={searchByCriteria}
      />
    </>
  );
};
