import { DefaultTheme } from '@infralabs/design-system';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { CurrentChannelProvider } from '../components/CurrentAccount';

import { history } from '../Helper';
import i18n from '../i18nextInit';
import Routes from '../routes';
import GlogalStyle from '../styles/global';

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primaryColor: DefaultTheme.colors.neutral_700,
  },
};

export default function App(props) {
  const { basePath } = props;
  const _props: any = { ...props };
  if (!_props.tokenIdSubject) {
    console.warn('mock tokenIdSubject.subscribe is enabled')
    _props.tokenIdSubject = {
      subscribe(callback: (tokenObj: any) => void) {
        window.addEventListener('change:store', (e: CustomEvent) => {
          callback({ store: e.detail });
        });
      },
    };
  }

  return (
    <>
      <CurrentChannelProvider {..._props}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <Router history={history}>
              <GlogalStyle />
            <Routes basePath={basePath} />
            </Router>
          </I18nextProvider>
        </ThemeProvider>
      </CurrentChannelProvider>
    </>
  );
}
