import { ImportQueue } from '../Model';
import { API } from '../Services/api';
import { BaseRepository } from './BaseRepository';

export class ImportQueueRepository extends BaseRepository<ImportQueue, typeof ImportQueue.prototype.guid> {
  name = 'imports';

  async updateQueueById(versionId: string, data: ImportQueue, id: string): Promise<ImportQueue> {
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? this.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    const response: any = await API.put<ImportQueue>(`${this.name}/${id}`, data, {
      headers: headers,
    });
    return response.data;
  }

  async getResultDownload(versionId: string, id: string): Promise<any> {
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? this.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    const response = await API.get(`${this.name}/${id}/download`, {
      responseType: 'arraybuffer',
      headers: {
        ...headers,
      },
    });
    return response;
  }
}
