import { Formik, Form } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import {
  InfraTextField,
  InfraButton,
  InfraTag,
  InfraSwitch,
  InfraInputCurrency,
  InfraLabel,
  InfraDatepicker,
  InfraTypography,
} from '@infralabs/design-system';
import { format, setHours, setMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Prices } from '../../Model/Prices';
import {
  RowErrorMessage,
  BoxErrorMessage,
  AutocompleteSellers,
  Box,
  WrapperField,
  WrapperButtonModal,
  WrapperSwitchModal,
  SelectSkus,
} from '../../components';
import { WrapperMessage } from '../../components/AlertMessage/styles';
import { RegEx } from '../../Helper/RegEx';
import { ProgrammedPriceRepository } from '../../Repository/ProgrammedPriceRepository';
import { getValueValid, isDateDiffMinutesAllowed } from '../../Helper';
import { ResultEnum } from '../../common/enum/result.enum';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';

const ProgrammedPriceModalSku = ({ onCloseModal, price, cbAlertModal }) => {
  const { t } = useTranslation();

  const formLabel = {
    activateAt: t('programmedPrice.form.labels.activateAt'),
    deactivateAt: t('programmedPrice.form.labels.deactivateAt'),
    costPrice: t('programmedPrice.form.labels.costPrice'),
    price: t('programmedPrice.form.labels.price'),
    highPrice: t('programmedPrice.form.labels.highPrice'),
    sku: t('programmedPrice.form.labels.sku'),
    seller: t('programmedPrice.form.labels.seller'),
    statusActive: t('programmedPrice.form.labels.statusActive'),
    statusInactive: t('programmedPrice.form.labels.statusInactive'),
  };

  const formPlaceholder = {
    activateAt: t('programmedPrice.form.placeholders.activateAt'),
    deactivateAt: t('programmedPrice.form.placeholders.deactivateAt'),
    costPrice: t('programmedPrice.form.placeholders.costPrice'),
    price: t('programmedPrice.form.placeholders.price'),
    highPrice: t('programmedPrice.form.placeholders.highPrice'),
    product: t('programmedPrice.form.placeholders.product'),
    sku: t('programmedPrice.form.placeholders.sku'),
    seller: t('programmedPrice.form.placeholders.seller'),
    searchProduct: t('programmedPrice.form.placeholders.searchProduct'),
  };

  const schema = Yup.object().shape({
    highPrice: Yup.string(),
    price: Yup.string().required(t('textValidations.required')),
    seller: Yup.string().required(t('textValidations.required')),
    activateAt: Yup.string().required(t('textValidations.required')),
    deactivateAt: Yup.string().required(t('textValidations.required')),
    profitMargin: Yup.string().matches(RegEx.percentage(), t('textValidations.invalid')).notRequired(),
    sku: Yup.string().required(t('textValidations.required')),
  });

  const [checked, setChecked] = useState(price?.active || false);
  const [sellerAutoComplete, setSellerAutoComplete] = useState('');
  const [skuId, setSkuId] = useState(price?.skuId);
  const [showMessageAutoComplete, setShowMessageAutoComplete] = useState(false);
  const [showMessageExistsProgrammedPrice, setShowMessageExistsProgrammedPrice] = useState(false);
  const [errorAlert, setErrorAlert] = useState('');

  const handleValues = (values) => {
    let { price, highPrice, activateAt, deactivateAt, salesChannel } = values;
    price = price.toString();
    highPrice = highPrice == undefined ? '' : highPrice.toString();
    activateAt = activateAt;
    deactivateAt = deactivateAt;

    let priceReplace = null;
    if (price.indexOf(',') !== -1) {
      priceReplace = Number(price.replace(',', '.'));
    } else {
      priceReplace = Number(values.price);
    }

    let highPriceReplace = null;
    if (highPrice && highPrice.indexOf(',') !== -1) {
      highPriceReplace = Number(highPrice.replace(',', '.'));
    } else {
      highPriceReplace = Number(values.highPrice);
    }

    if (highPriceReplace === 0) {
      highPriceReplace = undefined;
    }

    if (activateAt === undefined || activateAt === '' || activateAt.length === 0) {
      activateAt = '';
    }

    if (deactivateAt === undefined || deactivateAt === '' || deactivateAt.length === 0) {
      deactivateAt = '';
    }

    return { priceReplace, highPriceReplace, activateAt, deactivateAt, salesChannel };
  };

  const handleSubmit = async (values, actions) => {
    const programmedPriceRepository = new ProgrammedPriceRepository();
    const { priceReplace, highPriceReplace, activateAt, deactivateAt, salesChannel } = await handleValues(values);
    actions.setSubmitting(false);
    try {
      if (price) {
        const data = [
          {
            id: price.id,
            skuId: skuId,
            currency: 986,
            active: checked,
            highPrice: highPriceReplace,
            price: priceReplace,
            sellerId: price.sellerId,
            activateAt: activateAt,
            deactivateAt: deactivateAt,
          } as Prices,
        ];

        const result = await programmedPriceRepository.setChannelId(salesChannel).update('1', data);
        if (result['data']['programmed-prices'][0]['error'] == ResultEnum.AreadyExistsOtherDate) {
          setShowMessageExistsProgrammedPrice(true);
          return;
        }

        onCloseModal(false);
        cbAlertModal({ typeOperation: 'edit' });
      } else {
        if (sellerAutoComplete === '') {
          setShowMessageAutoComplete(true);
          return;
        }

        const data = [
          {
            skuId: skuId,
            currency: 986,
            highPrice: highPriceReplace,
            price: priceReplace,
            active: checked,
            sellerId: sellerAutoComplete,
            activateAt: activateAt,
            deactivateAt: deactivateAt,
          } as Prices,
        ];
        const result = await programmedPriceRepository.setChannelId(salesChannel).save('1', data);

        if (result.records[0]['result'] == ResultEnum.alreadyExists) {
          setShowMessageExistsProgrammedPrice(true);
          return;
        }

        if (result.records[0]['result'] == ResultEnum.AreadyExistsOtherDate) {
          setShowMessageExistsProgrammedPrice(true);
          return;
        }

        onCloseModal(false);
        cbAlertModal({ typeOperation: 'new' });
      }
    } catch (error) {
      if (error.response.data.data) {
        error.response.data.data['programmed-prices'].map((price) => {
          if (price.error === 'SkuAreadyExists') {
            actions.setFieldError(
              'sku',
              String(t('programmedPrice.form.textValidations.SkuAreadyExists')).replace(':sku', ''),
            );
          }
        });
      }
    }
  };

  const handleSwitch = (_, checkedEvent) => {
    setChecked(checkedEvent);
  };

  const minTimeDifference = (hoursInSeconds: number) => new Date(new Date().getTime() + hoursInSeconds);
  const deactivationDateValid = (values) => {
    if (!values.activateAt) {
      return minTimeDifference(7200);
    }
    return new Date(new Date(values.activateAt).getTime());
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          sku: price?.sku || '',
          highPrice: price?.highPrice || '',
          price: price?.price || '',
          activateAt: price?.activateAt || '',
          deactivateAt: price?.deactivateAt || '',
          active: price?.active || false,
          seller: price?.seller || '',
          salesChannel: ''
        }}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: any = {};
          if (values.highPrice !== '') {
            if (Number(String(values.price).replace(',', '.')) > Number(String(values.highPrice).replace(',', '.'))) {
              errors.price = t('programmedPrice.form.textValidations.priceBiggerThen');
            }
          }

          if (values.price && values.price <= 0) {
            errors.price = t('textValidations.invalid');
          }

          if (values.activateAt == '') {
            errors.activateAt = t('textValidations.required');
          }
          if (values.deactivateAt == '') {
            errors.deactivateAt = t('textValidations.required');
          }

          if (isDateDiffMinutesAllowed(values.activateAt, +process.env.PROGRAMMED_DIFFERECE_DATE_LIMIT)) {
            errors.activateAt = t('programmedPrice.form.textValidations.ProgrammedPriceGreaterToday', {
              minutes: process.env.PROGRAMMED_DIFFERECE_DATE_LIMIT,
            });
          }

          if (values.deactivateAt <= values.activateAt) {
            errors.deactivateAt = t('programmedPrice.form.textValidations.ProgrammedPriceGreaterdeActive');
          }
          return errors;
        }}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <Form>
            {errorAlert ? <BoxErrorMessage>{errorAlert}</BoxErrorMessage> : null}
            <WrapperField>
              <AutocompleteChannels
                readonly={Boolean(price)}
                label="Channels"
                labelBoxProps={{
                  mt: "24px"
                }}
                onChange={(salesChannel) => {
                  setFieldValue('salesChannel', salesChannel.id);
                }}
              />
            </WrapperField>
            <WrapperField>
              {price !== null ? (
                <>
                  <Box mt="24px">
                    <InfraLabel color="neutral_600">{formLabel.seller}</InfraLabel>
                  </Box>
                  <InfraTextField disabled={true} name="seller" value={price.seller} height="small" />
                </>
              ) : (
                <>
                  <Box mt="24px">
                    <InfraLabel color="neutral_600">{formLabel.seller}</InfraLabel>
                  </Box>
                  <AutocompleteSellers
                    versionId="1"
                    channelId={values.salesChannel}
                    onChange={(sellerId, sellerName) => {
                      setFieldValue('seller', sellerName);
                      setSellerAutoComplete(sellerId);
                    }}
                    placeholder={formPlaceholder.seller}
                  />
                  {errors.seller && touched.seller && <WrapperMessage>{errors.seller}</WrapperMessage>}
                </>
              )}
              {showMessageAutoComplete ? <RowErrorMessage>Seller é obrigatório</RowErrorMessage> : null}
            </WrapperField>
            <div>
              <WrapperField>
                {price !== null ? (
                  <>
                    <InfraTextField disabled={true} name="seller" value={price.sku} label="SKU" height="small" />
                  </>
                ) : (
                  <>
                    <InfraLabel color="neutral_600">{formLabel.sku}</InfraLabel>
                    <SelectSkus
                      search={true}
                      value={values.sku}
                      onChange={(data) => {
                        setFieldValue('sku', data.value, true);
                        setSkuId(data.value);
                      }}
                      placeholder={formPlaceholder.sku}
                      searchPlaceholder={formPlaceholder.searchProduct}
                    />
                    {errors.sku && touched.sku && <RowErrorMessage>{errors.sku}</RowErrorMessage>}
                    {showMessageExistsProgrammedPrice ? (
                      <RowErrorMessage>
                        {t('programmedPrice.form.textValidations.ProgrammedPriceExists')}
                      </RowErrorMessage>
                    ) : null}
                  </>
                )}
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600" optional={true}>
                  {formPlaceholder.highPrice}
                </InfraLabel>
                <InfraInputCurrency
                  name="highPrice"
                  disabled={price ? true : false}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  autoComplete="off"
                  onBlur={handleBlur}
                  placeholder={formPlaceholder.highPrice}
                  value={values.highPrice}
                  onValueChange={(value) => setFieldValue('highPrice', getValueValid(value))}
                  decimalSeparator=","
                  decimalsLimit={2}
                  inputStyle={errors.highPrice && touched.highPrice ? 'error' : 'normal'}
                  height="small"
                  message={errors.highPrice && touched.highPrice ? errors.highPrice : ''}
                />
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600">{formLabel.price}</InfraLabel>
                <InfraInputCurrency
                  name="price"
                  disabled={price ? true : false}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  autoComplete="off"
                  onBlur={handleBlur}
                  placeholder={formPlaceholder.price}
                  value={values.price}
                  onValueChange={(value) => setFieldValue('price', getValueValid(value))}
                  decimalSeparator=","
                  decimalsLimit={2}
                  inputStyle={errors.price && touched.price ? 'error' : 'normal'}
                  height="small"
                  message={errors.price && touched.price ? errors.price : ''}
                />
              </WrapperField>

              <WrapperField>
                {price !== null ? (
                  <>
                    <InfraTextField
                      disabled={true}
                      value={format(new Date(values.activateAt), 'dd/MM/yyyy HH:mm:ss')}
                      label={t('programmedPrice.form.labels.activateAt')}
                      height="small"
                    />
                  </>
                ) : (
                  <>
                    <InfraLabel label="activateAt" theme="light" color="neutral_600">
                      {t('programmedPrice.form.labels.activateAt')}
                    </InfraLabel>
                    <InfraDatepicker
                      disabled={price ? true : false}
                      name="activateAt"
                      autoComplete={'off'}
                      height="small"
                      subtractYears={10}
                      placeholder={t('programmedPrice.fields.filter.period.placeholder')}
                      onChange={async (value, name) => {
                        setFieldValue('activateAt', value, true);
                        setFieldValue('deactivateAt', deactivationDateValid(values), false);
                        setShowMessageExistsProgrammedPrice(false);
                      }}
                      theme="light"
                      setHours={true}
                      maskDate="dd.MM.yyyy, HH:mm"
                      minTime={minTimeDifference(7200)}
                      maxTime={setHours(setMinutes(new Date(), 30), 24)}
                    />
                    {errors.activateAt && <WrapperMessage>{errors.activateAt}</WrapperMessage>}
                  </>
                )}
                {showMessageExistsProgrammedPrice ? (
                  <RowErrorMessage>{t('programmedPrice.form.textValidations.ProgrammedPriceExists')}</RowErrorMessage>
                ) : null}
              </WrapperField>

              <WrapperField>
                {price !== null ? (
                  <>
                    <InfraTextField
                      disabled={true}
                      value={format(new Date(values.deactivateAt), 'dd/MM/yyyy HH:mm:ss')}
                      label={t('programmedPrice.form.labels.deactivateAt')}
                      height="small"
                    />
                  </>
                ) : (
                  <>
                    <InfraLabel label="deactivateAt" theme="light" color="neutral_600">
                      {t('programmedPrice.form.labels.deactivateAt')}
                    </InfraLabel>
                    <InfraDatepicker
                      disabled={price ? true : false}
                      name="deactivateAt"
                      autoComplete={'off'}
                      height="small"
                      subtractYears={10}
                      placeholder={t('programmedPrice.fields.filter.period.placeholder')}
                      onChange={async (value, name) => {
                        setFieldValue('deactivateAt', value, true);
                      }}
                      theme="light"
                      setHours={true}
                      maskDate="dd.MM.yyyy, HH:mm"
                      minDate={deactivationDateValid(values)}
                    />
                    {errors.deactivateAt && <WrapperMessage>{errors.deactivateAt}</WrapperMessage>}
                  </>
                )}
              </WrapperField>

              <WrapperSwitchModal>
                <div className="statusStyle">
                  <InfraTypography variant={'h5_small'} weight={'regular'} style={{ marginRight: '9px' }}>
                    Status
                  </InfraTypography>
                  <InfraSwitch checked={checked} onChange={(_, checkedEvent) => handleSwitch(_, checkedEvent)} />
                </div>
                {checked ? (
                  <div>
                    <InfraTag color="active" name={formLabel.statusActive} outline={false} borde={false} />
                  </div>
                ) : (
                  <div>
                    <InfraTag color="inactive" name={formLabel.statusInactive} outline={false} borde={false} />
                  </div>
                )}
              </WrapperSwitchModal>
              <WrapperButtonModal>
                <InfraButton
                  color="neutral"
                  type="submit"
                  size="large"
                  outline={false}
                  fullWidth={true}
                  onClick={() => onCloseModal(true)}
                  >
                  {t('programmedPrice.form.button.sku')}
                </InfraButton>
              </WrapperButtonModal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProgrammedPriceModalSku;
