import styled from 'styled-components';

export const WrapperSwitchModal = styled.div`
  display: flex;
  margin-top: 5px;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  .statusStyle {
    display: flex;
    span:nth-child(1) {
      margin-top: -2px;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
      margin-right: 10px;
    }
    align-items: center;
  }
`;

export const WrapperButtonModal = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: center;
`;
