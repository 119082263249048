export enum ResultEnum {
  success = 'success',
  both = 'both',
  created = 'created',
  processed = 'processed',
  updated = 'updated',
  deleted = 'deleted',
  failed = 'failed',
  alreadyExists = 'alreadyExists',
  priceNotExists = 'priceNotExists',
  AreadyExistsOtherDate = 'AreadyExistsOtherDate',

}
