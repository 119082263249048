import {
  InfraBreadcrumbs,
  InfraButton,
  InfraCol,
  InfraGrid,
  InfraIcons,
  InfraLabel,
  InfraModal,
  InfraTable,
  InfraTableColumn,
  InfraTag,
  InfraTextField,
  InfraTypography,
} from '@infralabs/design-system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ImportTypeEnum } from '../../common/enum/import-type.enum';
import { StatusColor } from '../../common/utils/statusColor';
import {
  AlertModal,
  AutocompleteSellers,
  AutocompleteStatus,
  Box,
  Button,
  Flex,
  Paginate,
  SelectClusters,
  SelectImportTypes,
  useAccount,
} from '../../components';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';
import { MultiSelectCategories } from '../../components/Selects';
import Tooltip from '../../components/Tooltip';
import { Colors, createLinkAndDownload, dateToFile, FormatCurrency } from '../../Helper';
import { useDebounce } from '../../hooks/debounce';
import { Prices } from '../../Model';
import { PriceExportRepository, PriceRepository } from '../../Repository';
import { notEmptyObjectValue } from '../../utils/notEmptyObjectValue';
import { ContentButtonExport } from './styles';

type Paging = {
  recordsReturned?: number;
  totalPages?: number;
  totalRecords?: number;
  currentPage?: number;
};

type ItemsProps = {
  label: string;
  value: number;
  id: number;
};

export default function ExportPricesComponent({ locationUrl = '' }: { locationUrl: string }) {
  const { t } = useTranslation();
  const [seller, setSeller] = useState('');
  const [channel, setChannel] = useState(localStorage.getItem('ifc-channel-id') ?? '');
  const [status, setStatus] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState<Prices[]>([]);
  const [categoryId, setStateCategories] = useState([]);
  const [clustersIds, setClustersIds] = useState([{ value: 'default', selected: true }]);
  const [description, setDescription] = useState('');
  const [typeExportState, setTypeExportState] = useState(0);
  const [fileData, setfileData] = useState(null);
  const [order, setOrder] = useState('seller.name');
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const [typeExport, setTypeExport] = useState<ItemsProps>();
  const [paging, setPaging] = useState<Paging>({
    recordsReturned: 0,
    totalPages: 1,
    totalRecords: 0,
    currentPage: 1,
  });
  const { currentTenant } = useAccount();
  const priceRepository = new PriceRepository();

  const history = useHistory();
  useEffect(() => {
    if (locationUrl != '') {
      history.push(locationUrl);
    }
  }, [locationUrl]);

  const mountParamsAndBody = () => {
    let data: Record<string, any> = {
      channelId: channel,
      sellerId: seller,
      category: categoryId?.map((e) => e.value)?.join(','),
      clusterId: clustersIds?.map((e) => e.value)?.join(','),
      statusId: status,
      order,
      description,
    };

    return notEmptyObjectValue(data);
  };

  async function loadPrices() {
    try {
      if (seller) {
        setLoading(true);
        let params = mountParamsAndBody();
        params = {
          ...params,
          pageNumber: paging.currentPage,
          pageSize: pageSize,
        };
        const { records, paging: pagingData } = await priceRepository.setChannelId(channel).findPaginate('1', params);

        setPrices(records);
        setPaging(pagingData);
        setLoading(false);
      } else {
        setPrices([]);
        setPaging({
          recordsReturned: 0,
          totalPages: 1,
          totalRecords: 0,
          currentPage: 1,
        });
      }
    } catch (err) {
      setPrices([]);
      setPaging({
        recordsReturned: 0,
        totalPages: 1,
        totalRecords: 0,
        currentPage: 1,
      });
      setLoading(false);
    }
  }

  useDebounce(() => loadPrices(), description);

  useEffect(() => {
    loadPrices();
  }, [paging.currentPage, seller, pageSize, order, clustersIds, categoryId, status, currentTenant, channel]);

  const handleModalExport = () => {
    setModalExport(!modalExport);
  };

  function resetFields() {
    if (Object.keys(seller).length === 0) {
      setClustersIds([]);
      setStateCategories([]);
    }
  }

  useEffect(() => {
    resetFields();
  }, [seller]);

  useEffect(() => {
    setSeller('');
  }, [currentTenant]);

  const links = [
    {
      text: t('app'),
      href: '/',
      use: 'link',
    },
    {
      text: t('main'),
      href: '/',
      disabled: false,
    },
    {
      text: t('price.export.title'),
      disabled: false,
    },
  ];

  const renderStatus = (rowData: any) => {
    if (rowData.isActive == false) {
      return <InfraTag color="inactive" name={t('price.form.labels.statusInactive')} outline={false} borde={false} />;
    } else {
      let statusColor = new StatusColor();
      return (
        <InfraTag
          color={statusColor.status[rowData.status.status]}
          name={
            rowData.status
              ? t('price.fields.status.status.' + rowData.status.status)
              : t('price.fields.status.status.pending')
          }
        >
          {' '}
        </InfraTag>
      );
    }
  };

  const handlePagination = (event, value) => {
    setPaging({ ...paging, currentPage: value });
  };

  const handleSellers = (id, name) => {
    setPaging({ ...paging, currentPage: 1 });
    setSeller(id);
  };

  const handleChannel = (channel) => {
    setPaging({ ...paging, currentPage: 1 });
    setClustersIds([
      {
        value: 'default',
        selected: true,
      },
    ]);
    setChannel(localStorage.getItem('ifc-channel-id') ?? channel.id);
  };

  const handleStatus = (id, status) => {
    setStatus(id);
    setPaging({ ...paging, currentPage: 1 });
  };

  const renderFilterIcon = () => {
    return (
      <span style={{ marginInline: '10px' }}>
        <InfraIcons name="SortDescending" size={16} weight="bold" />
      </span>
    );
  };

  const handleExport = async () => {
    const exportRepository = new PriceExportRepository();
    if (seller) {
      let body = mountParamsAndBody();
      body = {
        ...body,
        typeExportId: typeExport.value,
        sellerId: seller,
      };
      setTypeExportState(typeExport.value);
      const response = await exportRepository.getExportPrices('1', body);
      if (response.status === 201) {
        setShowModalSuccess(true);
        setfileData(response.data);
      }
    }
  };

  function handleConfirmModal() {
    const fileNameMap = new Map<ImportTypeEnum, string>([
      [ImportTypeEnum.PRODUCT, '__PRODUCT__'],
      [ImportTypeEnum.SKU, '__SKU__'],
      [ImportTypeEnum.EXTERNAL_SKU, '__SKU__EXTERNO__'],
    ]);
    const nameDownload = fileNameMap.get(typeExport.value);

    createLinkAndDownload(fileData, `prices_export_${dateToFile()}${nameDownload}.xlsx`);
    setShowModalSuccess(false);
  }

  const renderNumber = (value) => {
    return <span>{FormatCurrency.format('BRL', value)}</span>;
  };

  const renderMessageModalSuccess = () => {
    if (typeExportState === 1) {
      return `${t('price.export.alert.product')}`;
    }
    if (typeExportState === 2) {
      return `${t('price.export.alert.sku')}`;
    }
    if (typeExportState === 3) {
      return `${t('price.export.alert.external_sku')}`;
    }
  };

  const renderBreadcrumb = () => {
    return (
      <Box>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </Box>
    );
  };

  const renderTitle = () => {
    return (
      <div>
        <InfraTypography variant={'h1_small'} weight={'bold'}>
          {t('price.export.title')}
        </InfraTypography>
      </div>
    );
  };

  function handleMultiSelectCategory(data) {
    if (data.length > 0) {
      setPaging({ ...paging, currentPage: 1 });
      setStateCategories(data);
    } else {
      setStateCategories([]);
    }
  }

  function handleMultiSelectClusters(data = []) {
    setPaging({ ...paging, currentPage: 1 });
    if (data.length > 0) {
      setClustersIds(data);
    } else {
      setClustersIds([]);
    }
  }

  function renderTypographyButton(message: string) {
    return (
      <InfraTypography
        variant={'body_small'}
        weight={'semibold'}
        color={seller === '' ? Colors.shade_0 : Colors.neutral_400}
      >
        {message}
      </InfraTypography>
    );
  }

  const renderToolbar = () => {
    return (
      <Flex justify={'space-between'}>
        <Flex gap={'8px'} direction="row">
          <Box d={'none'}>
            <AutocompleteChannels onChange={handleChannel} label="Channel" />
          </Box>
          <Box w="159px">
            <InfraLabel color="neutral_600">{t('price.fields.autocomplete.seller.label')}</InfraLabel>
            <AutocompleteSellers
              versionId="1"
              onChange={handleSellers}
              channelId={channel}
              placeholder={t('price.fields.autocomplete.seller.placeholder')}
            />
          </Box>
          <Box w="159px">
            <InfraLabel color="neutral_600">{t('price.fields.multselect.category.label')}</InfraLabel>
            <MultiSelectCategories
              disable={Object.keys(seller).length !== 0 ? false : true}
              limitTags={1}
              value={categoryId}
              onChange={(data) => {
                handleMultiSelectCategory(data);
              }}
              display="count"
              placeholder={t('price.fields.multselect.category.placeholder')}
            />
          </Box>
          <Box w="219px">
            <InfraLabel color="neutral_600">{t('price.fields.multselect.clusters.label')}</InfraLabel>

            <SelectClusters
              showDefaultOption={true}
              limitTags={1}
              multiple={false}
              value={[{ label: 'Default', value: 'default' }]}
              display="count"
              channelId={channel}
              onChange={(data) => {
                handleMultiSelectClusters([data]);
              }}
              placeholder={t('price.fields.multselect.clusters.placeholder')}
            />
          </Box>

          <Box w="159.01px">
            <InfraLabel color="neutral_600">{t('price.fields.autocomplete.status.label')}</InfraLabel>
            <AutocompleteStatus
              versionId="1"
              onChange={handleStatus}
              placeholder={t('price.fields.autocomplete.status.placeholder')}
              typePrice="price"
            />
          </Box>

          <Box w="280px">
            <InfraLabel color="neutral_600">{t('price.fields.search.label')}</InfraLabel>
            <InfraTextField
              id="1"
              inputStyle="normal"
              onChange={() => {}}
              onChangeValue={(v) => setDescription(v.target.value)}
              placeholder={t('price.fields.search.placeholder')}
              type="text"
              disabled={Object.keys(seller).length !== 0 ? false : true}
              style={{ height: '45px' }}
            />
          </Box>
        </Flex>
        <Flex gap="8px" direction="row" justify={'end'} align={'center'} mt="25px" grow="0" shrink="1" basis="auto">
          <Button
            color="normal"
            size="large"
            outline={false}
            icon="ArrowDown"
            iconRight={true}
            style={{
              background: seller === '' ? '#C6D3DE' : '#2F3C4E',
              border: seller === '' ? '#C6D3DE' : '#2F3C4E',
              color: seller === '' ? '#879BB4' : '#FFFFFF',
            }}
            onClick={() => handleModalExport()}
            disabled={Object.keys(seller).length !== 0 ? false : true}
          >
            {renderTypographyButton(t('price.export.button.export'))}
          </Button>
        </Flex>
      </Flex>
    );
  };

  const displayCategories = (categories) => {
    if (!categories?.length) {
      return '-';
    }
    return categories?.map((c) => c?.name).join(', ');
  };

  const renderDataTable = () => {
    return (
      <InfraTable value={prices} loading={loading} messageEmptyData={t('emptyTable')}>
        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'sku.skuId' ? '' : 'sku.skuId')}
          field="skuId"
          header={t('price.fields.table.columnHeaders.skuId')}
          headerAlign="left"
        >
          {order === 'skuId' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'product.productId' ? '' : 'product.productId')}
          field="productId"
          header={t('price.fields.table.columnHeaders.productId')}
          headerAlign="left"
        >
          {order === 'productId' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'sku.externalSku' ? '' : 'sku.externalSku')}
          field="externalSku"
          header={t('price.fields.table.columnHeaders.externalSku')}
          headerAlign="left"
          body={({ externalSku }) => externalSku || '-'}
        >
          {order === 'externalSku' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'product.name' ? '' : 'product.name')}
          field="product"
          header={t('price.fields.table.columnHeaders.product')}
          headerAlign="left"
          body={({ product, sku }) => <Tooltip text={`${product} - ${sku}`} limit={35} />}
        >
          {order === 'product.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'price.highPrice' ? '' : 'price.highPrice')}
          field="highPrice"
          header="Preço de"
          headerAlign="left"
          body={({ highPrice }) => renderNumber(highPrice)}
        >
          {order === 'price.highPrice' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'price.price' ? '' : 'price.price')}
          field="price"
          header="Preço por"
          headerAlign="left"
          body={({ price }) => renderNumber(price)}
        >
          {order === 'price.price' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'clientsCluster.name' ? '' : 'clientsCluster.name')}
          field="brand"
          header={t('price.fields.table.columnHeaders.clientsCluster')}
          headerAlign="left"
          body={({ cluster }) => {
            return cluster?.map((c) => c?.name).join(', ') ?? '-';
          }}
        >
          {order === 'clientsCluster.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          clickable
          onClick={(_, field) => setOrder(order === 'categories.name' ? '' : 'categories.name')}
          field="category"
          header={t('price.fields.table.columnHeaders.category')}
          headerAlign="left"
          body={({ categories }) => displayCategories(categories)}
        >
          {order === 'category' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="seller"
          clickable
          onClick={(_, field) => setOrder(order === 'seller.name' ? '' : 'seller.name')}
          header={t('price.fields.autocomplete.seller.label')}
          headerAlign="left"
        >
          {order === 'seller.name' && renderFilterIcon()}
        </InfraTableColumn>

        <InfraTableColumn
          field="active"
          header="Status"
          align="left"
          headerAlign="left"
          body={renderStatus}
          clickable
          onClick={(_, field) => setOrder(order === 'status.id' ? '' : 'status.id')}
        >
          {order === 'status.id' && renderFilterIcon()}
        </InfraTableColumn>
      </InfraTable>
    );
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
    setPaging({ ...paging, currentPage: 1 });
  };

  const renderPagination = () => {
    return (
      <Paginate
        onChangePageSize={handlePageSize}
        onChangePage={handlePagination}
        totalPages={paging?.totalPages}
        currentPage={paging.currentPage}
      />
    );
  };

  const changeTypeExport = (e) => {
    setTypeExport(e);
  };

  return (
    <>
      <InfraGrid>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'31px'}>{renderBreadcrumb()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'32px'}>{renderTitle()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'22px'}>{renderToolbar()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt={'-10px'}>{renderDataTable()}</Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12}>
          <Box mt="4px" mb="64px">
            {renderPagination()}
          </Box>
        </InfraCol>
      </InfraGrid>

      <AlertModal
        width="278"
        message={renderMessageModalSuccess}
        show={showModalSuccess}
        onClose={() => setShowModalSuccess(false)}
        onConfirmModal={handleConfirmModal}
      />

      <InfraModal show={modalExport} onClose={handleModalExport} title="Exportar Por">
        <Box mt="32px">
          <InfraLabel color="neutral_600">{t('price.export.modal_export.label')}</InfraLabel>
          <SelectImportTypes
            onChange={changeTypeExport}
            onFetched={() => {}}
            optionFilterDefault={false}
            placeholder={t('price.export.fields.importType.placeholder')}
          />
        </Box>
        <ContentButtonExport>
          <InfraButton
            disabled={!typeExport}
            size="medium"
            onClick={() => {
              handleExport();
              handleModalExport();
            }}
          >
            {t('price.export.button.export')}
          </InfraButton>
        </ContentButtonExport>
      </InfraModal>
    </>
  );
}
