import { Link } from 'react-router-dom';
import { InfraTypography, InfraIcons } from '@infralabs/design-system';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../Helper';
import { Container } from './styles';

export const ReturnLink = ({ to }: { to: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Link to={to}>
          <InfraIcons name="ArrowUUpLeft" weight="bold"  size={20} color={Colors.information_100} />
          <InfraTypography variant={'h6'} weight={'bold'} color={Colors.information_100}>
            {t('return')}
          </InfraTypography>
        </Link>
      </Container>
    </>
  );
};
