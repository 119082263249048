import {
  InfraBreadcrumbs, InfraCol, InfraGrid, InfraIcons, InfraTag, InfraTypography
} from '@infralabs/design-system';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { StatusColorEnumerator } from '../../../common';
import { Box, Flex, ReturnLink, useAccount } from '../../../components';
import { BoxDetail } from '../../../components/BoxDetail/style';
import { useQuery } from '../../../hooks/useQuery';
import { Charges } from '../../../Model/Charges';
import { ChargeRepository } from '../../../Repository';
import { toISOString } from '../../../utils/toISOString';
import * as S from './style';

export const ChargeDetail = ({ locationUrl = '' }: { locationUrl: string }) => {
  const { currentTenant } = useAccount();
  let history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  let query = useQuery();
  const channelId = useMemo(() => {
    const queryChannelId = query.get('channelId')
    if (!queryChannelId) history.push('/charges');
    return queryChannelId
  }, [query])
  const [charge, setCharge] = useState<Charges>({});
  const [sellerCodeValues, setSellerCodeValues] = useState([]);
  const [deliveryModesGroup, setDeliveryModesGroup] = useState([]);
  const [errorSalesOperators, setErrorSalesOperators] = useState([]);
  const chargeRepository = new ChargeRepository();


  async function loadCharges() {
    if (id) {
      try {
        const result: any = await chargeRepository.setChannelId(channelId).findOne('1', id);

        setCharge(result.data.promotions[0]);
        if (result.data.promotions[0]?.['sellerCodes']?.length > 0) {
          const sellerCodes = result.data.promotions[0]?.['sellerCodes']?.map((item) => ({
            value: item.sellerCode,
          }));
          setSellerCodeValues(sellerCodes);
        }
        if (result.data.promotions[0]?.['deliveryModes']?.length > 0) {
          const group = groupFrom(result.data.promotions[0]?.['deliveryModes'], 3);
          setDeliveryModesGroup([...group]);
        }
      } catch (err) {
        history.push('/charges');
      }
    }
  }

  function groupFrom(data, offset) {
    var item = [];
    for (let i = 0; i < data.length; i = i + offset) {
      const slice = data.slice(i, i + offset);
      item.push(slice);
    }
    return item;
  }

  useEffect(() => {
    loadCharges();
  }, [currentTenant]);

  const links = [
    {
      text: t('app'),
      href: '/',
      use: 'link',
    },
    {
      text: t('main'),
      href: '/',
      disabled: false,
    },
    {
      text: t('charge.breadcrumbs.home'),
      href: '/charges',
      disabled: false,
    },
    {
      text: t('charge.breadcrumbs.detail'),
      href: '',
      disabled: true,
    },
  ];

  const renderBreadcrumb = () => {
    return (
      <Box>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </Box>
    );
  };

  const backToAllCharges = () => {
    return <ReturnLink to="/charges" />;
  };

  const renderChargeStatus = (status: string, isActive: boolean) => {
    if (!status) return '';
    if (isActive == false) {
      return <InfraTag color="inactive" name={t('price.form.labels.statusInactive')} outline={false} borde={false} />;
    }
    return (
      <InfraTag
        color={StatusColorEnumerator[status]}
        name={status ? t('price.fields.status.status.' + status) : t('price.fields.status.status.pending')}
      ></InfraTag>
    );
  };

  const renderTitle = () => {
    return (
      <>
        <Flex wrap="wrap">
          <InfraTypography variant={'h1_small'} weight={'bold'}>
            <div style={{ textTransform: 'uppercase' }}>{charge?.name}</div>
          </InfraTypography>
          <S.Tag>{renderChargeStatus(charge?.['status'], charge?.isActive)}</S.Tag>
        </Flex>
        {backToAllCharges()}
      </>
    );
  };

  const renderDisabled = (title: string, message: string, sizeHeight = '158px') => {
    if (!title || !message) return '';
    return (
      <Box>
        <BoxDetail height={sizeHeight} disable={true} closeDefault>
          <S.TitleError>
            <Box>{title}</Box>
          </S.TitleError>
          {sizeHeight === '227px' ? <S.SpacingBox></S.SpacingBox> : null}
          <S.WarningWrapper>
            <InfraIcons name="Warning" size={24} />
          </S.WarningWrapper>
          <S.MessageError>
            <InfraTypography variant={'h4_small'} weight={'regular'} color={'neutral_400'}>
              {message}
            </InfraTypography>
          </S.MessageError>
        </BoxDetail>
      </Box>
    );
  };

  const formatDate = (date: Date) => {
    if (!date) return '';
    let dateToFormat = toISOString(date);
    return format(new Date(dateToFormat), 'dd.MM.yyyy HH:mm:ss');
  };

  const valueChargeTypeInString = (typeId: number): string => {
    if (!typeId) return '';
    if (typeId === 1) return 'sku';
    if (typeId === 2) return 'freight';
    if (typeId === 3) return 'payments';
  };

  const convertDeviceIdToName = (deviceId: number): string => {
    if (!deviceId) return '';
    if (deviceId === 1) return 'iOS';
    if (deviceId === 2) return 'Android';
    if (deviceId === 3) return 'Web';
    if (deviceId === 99) return 'Todos';
  };

  const renderChargeMethodsPayment = () => {
    return charge?.['methodsPayment']?.length > 0 ? (
      <Box>
        <BoxDetail height="227px">
          <S.Title>
            <Box>{t('charge.detail.payment')}</Box>
          </S.Title>
          {charge?.['methodsPayment'].map((payment, index) => (
            <React.Fragment key={index}>
              <S.BodyColumn>
                <Box>
                  <S.BodyName>{t('charge.detail.paymentOptions')}</S.BodyName>
                  <InfraTypography variant={'h5_small'} weight={'regular'}>
                    <S.BodyValue>{payment.name}</S.BodyValue>
                  </InfraTypography>
                </Box>
                <Box>
                  <S.BodyName>{t('charge.detail.installments')}</S.BodyName>
                  <InfraTypography variant={'h5_small'} weight={'regular'}>
                    <S.BodyValue>{payment.maxInstallmentsCount || 1}</S.BodyValue>
                  </InfraTypography>
                </Box>
                <Box>
                  <div></div>
                  <div></div>
                </Box>
              </S.BodyColumn>
              {charge?.['methodsPayment'].length - 1 !== index ? <S.Hr /> : null}
            </React.Fragment>
          ))}
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('charge.detail.payment'), t('charge.detail.paymentError'), '227px')
    );
  };

  const renderChargeDeliveryModes = () => {
    return charge?.['deliveryModes']?.length > 0 ? (
      <Box>
        <BoxDetail height="227px">
          <S.Title>
            <Box>{t('charge.detail.freight')}</Box>
          </S.Title>
          {deliveryModesGroup.length > 0 &&
            deliveryModesGroup.map((deliveriesMode, index) => {
              return (
                <React.Fragment key={index}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {deliveriesMode.map((delivery, indexDelivery) => {
                      return (
                        <S.BodyColumnFreight key={indexDelivery}>
                          <div style={{ width: '217px' }}>
                            <S.BodyName>{t('charge.detail.freightType')}</S.BodyName>
                            <S.BodyValue>{delivery.name}</S.BodyValue>
                          </div>
                        </S.BodyColumnFreight>
                      );
                    })}
                  </div>
                  {index !== deliveryModesGroup?.length - 1 ? <S.Hr /> : null}
                </React.Fragment>
              );
            })}
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('charge.detail.freight'), t('charge.detail.freigthError'), '227px')
    );
  };

  const renderChargeGeneralData = () => {
    return (
      <Box>
        <BoxDetail height="158px">
          <S.Title>
            <Box>{t('charge.detail.generalData')}</Box>
          </S.Title>
          <S.BodyColumn>
            <Box>
              <S.BodyName>{t('charge.detail.seller')}</S.BodyName>
              <S.BodyValue>{charge?.sellerName}</S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('charge.detail.chargePeriod')}</S.BodyName>
              <S.BodyValue>
                <InfraTypography variant={'h5_small'} weight={'regular'}>
                  {formatDate(charge?.activateAt)} - {formatDate(charge?.deactivateAt)}
                </InfraTypography>
              </S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('charge.detail.chargeType')}</S.BodyName>
              <InfraTypography variant={'h5_small'} weight={'regular'}>
                <S.BodyValue>{valueChargeTypeInString(charge?.['typeId'])}</S.BodyValue>
              </InfraTypography>
            </Box>
          </S.BodyColumn>
          <S.Hr />
        </BoxDetail>
      </Box>
    );
  };

  const renderChargeCategories = () => {
    return charge?.['categories']?.length > 0 ? (
      <Box>
        <BoxDetail height="158px">
          <S.Title>
            <Box>{t('charge.detail.category')}</Box>
          </S.Title>
          <S.BodyValue>
            <InfraTypography variant={'h5_small'} weight={'regular'}>
              {charge?.['categories'].map((category, key) =>
                charge?.['categories'].length - 1 !== key ? (
                  <span key={key}> {category.name}, </span>
                ) : (
                  <span key={key}> {category.name}. </span>
                ),
              )}
            </InfraTypography>
          </S.BodyValue>
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('charge.detail.category'), t('charge.detail.categoryError'))
    );
  };

  const renderAddition = () => {
    if (!charge?.id) return '';
    if (charge?.discountValue) return charge?.discountValue + ' R$';
    if (charge?.discountPercentage) return charge?.discountPercentage + '%';
  };

  const renderChargeType = () => {
    return (
      <Box>
        <BoxDetail height="227px">
          <S.Title>
            <Box>{t('charge.detail.titleChargeType')}</Box>
          </S.Title>
          <S.BodyColumn>
            <Box>
              <S.BodyName>{t('charge.detail.coupon')}</S.BodyName>
              <S.BodyValue>{charge?.code || 'Não'}</S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('charge.detail.addition')}</S.BodyName>
              <S.BodyValue>{renderAddition()}</S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('charge.detail.devices')}</S.BodyName>
              <S.BodyValue>{convertDeviceIdToName(charge?.['deviceId']) || 'Não'}</S.BodyValue>
            </Box>
          </S.BodyColumn>
        </BoxDetail>
      </Box>
    );
  };

  const renderChargeDescription = () => {
    return charge?.['description'] !== null && charge?.['description'] !== '' ? (
      <Box>
        <BoxDetail height="227px">
          <S.Title>
            <Box>{t('charge.detail.description')}</Box>
          </S.Title>
          <Box>
            <InfraTypography variant={'h5_small'} weight={'regular'}>
              <S.BodyValue>{charge?.['description']}</S.BodyValue>
            </InfraTypography>
          </Box>
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('charge.detail.description'), t('charge.detail.descriptionError'), '227px')
    );
  };

  const renderChargeSkuException = () => {
    return charge?.['skusException']?.length > 0 ? (
      <Box>
        <BoxDetail height="158px">
          <S.Title>
            <Box>{t('charge.detail.skuException')}</Box>
          </S.Title>
          <Box>
            {charge?.['skusException'].map((skuException, index) =>
              charge?.['skusException'].length - 1 !== index ? (
                <span key={index}>
                  <S.BodyValue>
                    <InfraTypography variant={'h5_small'} weight={'regular'}>
                      {t('charge.detail.sku')} {skuException.skuName} - {t('charge.detail.product')}{' '}
                      {skuException['productName']},{' '}
                    </InfraTypography>
                  </S.BodyValue>
                </span>
              ) : (
                <S.BodyValue key={index}>
                  <InfraTypography variant={'h5_small'} weight={'regular'}>
                    <span>
                      {t('charge.detail.sku')} {skuException.skuName} - {t('charge.detail.product')}{' '}
                      {skuException['productName']}.{' '}
                    </span>
                  </InfraTypography>
                </S.BodyValue>
              ),
            )}
          </Box>
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('charge.detail.skuException'), t('charge.detail.skuExceptionError'))
    );
  };

  const renderChargeSkus = () => {
    return charge?.['skus']?.length > 0 ? (
      <Box>
        <BoxDetail height="158px" >
          <S.Title>
            <Box>{t('charge.detail.sku')}</Box>
          </S.Title>
          <Box>
            {charge?.['skus'].map((sku, index) =>
              charge?.['skus'].length - 1 !== index ? (
                <span key={index}>
                  <S.BodyValue>
                    <InfraTypography variant={'h5_small'} weight={'regular'}>
                      {t('charge.detail.sku')} {sku.skuName} - {t('charge.detail.product')} {sku['productName']},{' '}
                    </InfraTypography>
                  </S.BodyValue>
                </span>
              ) : (
                <S.BodyValue key={index}>
                  <InfraTypography variant={'h5_small'} weight={'regular'}>
                    <span>
                      {t('charge.detail.sku')} {sku.skuName} - {t('charge.detail.product')} {sku['productName']}.{' '}
                    </span>
                  </InfraTypography>
                </S.BodyValue>
              ),
            )}
          </Box>
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('charge.detail.sku'), t('charge.detail.skuError'))
    );
  };

  const renderContent = () => {
    return (
      <>
        <S.Content height={errorSalesOperators.length > 0 ? '1096px' : '1079px'}>
          <Flex justify={'center'}>
            {renderChargeGeneralData()}
            {renderChargeCategories()}
          </Flex>
          <Flex justify={'center'}>
            {renderChargeMethodsPayment()}
            {renderChargeDeliveryModes()}
          </Flex>
          <Flex justify={'center'}>
            {renderChargeType()}
            {renderChargeDescription()}
          </Flex>
          <Flex justify={'center'}>
            {renderChargeSkuException()}
            {renderChargeSkus()}
          </Flex>
        </S.Content>
      </>
    );
  };

  return (
    <>
      <S.WrapperBreadCrumbs>
        <InfraGrid>
          <InfraCol xxl={12} xl={12} md={8} xs={4}>
            <Box mt={'31px'} w="100%">
              {renderBreadcrumb()}
            </Box>
          </InfraCol>
        </InfraGrid>
      </S.WrapperBreadCrumbs>
      <Flex justify={'center'} mb={'32px'}>
        <InfraGrid>
          <InfraCol xxl={12} xl={12} md={8} xs={4}>
            <Box mt={'30px'} w="100%">
              {renderTitle()}
            </Box>
          </InfraCol>
          <InfraCol xxl={12} xl={12} md={8} xs={4}>
            <Box mt={'-10px'} w="100%" overflowX="auto">
              {renderContent()}
            </Box>
          </InfraCol>
        </InfraGrid>
      </Flex>
    </>
  );
};
