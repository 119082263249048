import { InfraCol, InfraGrid, InfraModal } from '@infralabs/design-system';
import { useEffect, useMemo, useState } from 'react';
import { AlertModal, Box, Paginate } from '../../components';
import { KEY_SELLER_ID, KEY_SELLER_LABEL } from '../../constants/localStorageKeys';
import { WrapperModalConfirm } from '../styles/style-wrapper-modal';
import ChargesBreadcrumb from './Breadcrumbs';
import ModalDeleteCharge from './modalDeleteCharges';
import ChargeTable from './Table';
import ChargeTitle from './Title';
import ChargeToolbar from './Toolbar';
import useChargesPaginate from './useChargesPaginate';

const ChargesComponent = ({ locationUrl = '' }: { locationUrl: string }) => {
  const [filter, setFilter] = useState({
    seller: '',
    type: '',
    status: '',
    description: '',
    activeAt: '',
    deactiveAt: '',
    channel: localStorage.getItem('ifc-channel-id') ?? '',
  });
  const [pageSize, setPageSize] = useState(10);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [contextAlert, setContexAlert] = useState<{
    context: string;
    type: 'success' | 'failed' | 'both';
    messages?: any;
  }>({ context: '', type: 'success' });
  const [showModalDeleteCharge, setShowModalDeleteCharge] = useState(false);
  const [order, setOrder] = useState('status.id');
  const [chargeSelected, setChargeSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const params = useMemo(
    () => ({
      currentPage,
      pageSize,
      order,
      filter,
    }),
    [currentPage, pageSize, order, filter],
  );

  const { data: charges, loading, paging, refetch: loadCharges } = useChargesPaginate(params);

  useEffect(() => {
    localStorage.setItem(KEY_SELLER_ID, '');
    localStorage.setItem(KEY_SELLER_LABEL, '');
  }, []);

  const handlePagination = (_, value) => {
    setCurrentPage(value);
  };

  const handleDelete = (chargeData) => {
    setChargeSelected(chargeData);
    setShowModalDeleteCharge(true);
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const renderModalAlert = () => {
    if (contextAlert.context === 'delete') {
      if (contextAlert.type === 'failed') {
        return 'Falha ao deletar o registro';
      } else if (contextAlert.type === 'success') {
        return 'Registro deletado com sucesso';
      }
    }
  };

  const handleChangeFilter = (filter) => {
    setFilter(filter);
    setCurrentPage(1);
  };

  return (
    <>
      <InfraGrid>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt={'31px'} w="100%">
            <ChargesBreadcrumb />
          </Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt={'32px'} w="100%">
            <ChargeTitle />
          </Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt={'22px'} w="100%">
            <ChargeToolbar onChange={handleChangeFilter} />
          </Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt={'-10px'} w="100%" overflowX="auto">
            <ChargeTable
              loading={loading}
              onChangeOrder={(order) => setOrder(order)}
              onRemove={handleDelete}
              order={order}
              charges={charges}
            />
          </Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt="4px" mb="64px" w="100%">
            <Paginate
              onChangePageSize={handlePageSize}
              onChangePage={handlePagination}
              totalPages={paging?.totalPages}
              currentPage={currentPage}
            />
          </Box>
        </InfraCol>
      </InfraGrid>

      <WrapperModalConfirm>
        <InfraModal
          theme="light"
          show={showModalDeleteCharge}
          onClose={() => {
            setShowModalDeleteCharge(false);
            setChargeSelected(null);
          }}
        >
          <ModalDeleteCharge
            onCloseModal={({ result }) => {
              setShowModalDeleteCharge(false);
              if (result) {
                loadCharges();
                setContexAlert({ context: 'delete', type: result });
                setShowModalAlert(true);
              }
              setChargeSelected(null);
            }}
            charge={chargeSelected}
          />
        </InfraModal>
      </WrapperModalConfirm>

      <AlertModal
        variation={contextAlert.type === 'both' ? 'failed' : contextAlert.type}
        message={renderModalAlert()}
        show={showModalAlert}
        onConfirmModal={() => setShowModalAlert(false)}
        onClose={() => setShowModalAlert(false)}
        width={contextAlert.context === 'delete' ? '278' : undefined}
      />
    </>
  );
};

export default ChargesComponent;
