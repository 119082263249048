import {
  InfraButton,
  InfraInputCurrency,
  InfraLabel,
  InfraSwitch,
  InfraTag,
  InfraTextField,
  InfraTypography,
} from '@infralabs/design-system';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  AutocompleteSellers,
  RowErrorMessage,
  SelectProducts,
  WrapperButtonModal,
  WrapperField,
  WrapperSwitchModal,
} from '../../components';

import { RegEx } from '../../Helper/RegEx';
import { PriceRepository } from '../../Repository/PriceRepository';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';
import ClustersSelect from '../../components/Selects/ClientClusters';
import { AutocompleteClusters } from '../../components/AutocompleteCluster';

const priceRepository = new PriceRepository();
const ModalProduct = ({ onCloseModal }) => {
  const [sellerId, setSellerId] = useState('');
  const [product, setProduct] = useState(null);
  const [status, setStatus] = useState(false);
  const { t } = useTranslation();

  const formLabel = {
    profitMargin: t('price.form.labels.profitMargin'),
    costPrice: t('price.form.labels.costPrice'),
    price: t('price.form.labels.price'),
    highPrice: t('price.form.labels.highPrice'),
    product: t('price.form.labels.product'),
    seller: t('price.form.labels.seller'),
    statusActive: t('price.form.labels.statusActive'),
    statusInactive: t('price.form.labels.statusInactive'),
  };

  const formPlaceholder = {
    profitMargin: t('price.form.placeholders.profitMargin'),
    costPrice: t('price.form.placeholders.costPrice'),
    price: t('price.form.placeholders.price'),
    highPrice: t('price.form.placeholders.highPrice'),
    product: t('price.form.placeholders.product'),
    seller: t('price.form.placeholders.seller'),
    searchProduct: t('price.form.placeholders.searchProduct'),
  };

  const handleSubmit = async (values) => {
    let profitMargin = values.profitMargin != '' ? Number(String(values.profitMargin).replace(',', '.')) : 0;
    let costPrice = values.costPrices !== '' ? Number(String(values.costPrice).replace(',', '.')) : undefined;
    let price = Number(String(values.price).replace(',', '.'));
    let highPrice = Number(String(values.highPrice).replace(',', '.'));
    const clusterId = values.clusterId;

    if (highPrice === 0) {
      highPrice = undefined;
    }

    const prices = [
      ...product.skus.map((sku) => ({
        currency: 986,
        skuId: sku.skuId,
        sellerId,
        price: price,
        highPrice,
        isActive: status,
        profitMargin,
        costPrice,
        clusterId,
      })),
    ];

    type Result = {
      skuId: string;
      result: string;
    };

    try {
      const { records } = await priceRepository.setChannelId(values.salesChannel).save<Result>('1', prices);
      let type = 'success';
      let messages;
      const someFailed = records.some((el) => el.result === 'failed');
      if (someFailed) {
        type = 'both';
        messages = prepareMsgBoth(records);
      }
      onCloseModal({ type, messages });
    } catch (err) {
      let messages;
      if (err.response.data.message) {
        messages = prepareMsgsErrors(err.response.data.message, prices, 'validator');
      } else {
        messages = prepareMsgsErrors(err.response.data.data.prices, prices, 'internal');
      }
      onCloseModal({ type: 'failed', messages });
    }
  };

  function prepareMsgBoth(prices) {
    return prices.map((el) => ({
      id: el.id,
      sku: el.skuId,
      skuName: el.sku,
      result: el.result,
      error: el.error ?? null,
    }));
  }

  function prepareMsgsErrors(pricesFailedOrMessages: any[], prices, type: 'validator' | 'internal') {
    let errors = [];
    if (type === 'validator') {
      errors = pricesFailedOrMessages.map((error) => {
        const errorSplited = error.split('.');
        const position = parseInt(errorSplited[1]);
        const message = errorSplited[2];
        const price = prices[position];
        return { sku: price.skuId, message };
      });
    } else {
      errors = pricesFailedOrMessages
        .filter((el) => el.result === 'failed')
        .map((el) => ({
          sku: el.skuId,
          skuName: el.sku,
          message: el.error,
        }));
    }
    return errors;
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          product: '',
          seller: '',
          price: '',
          highPrice: '',
          costPrice: '',
          salesChannel: '',
          profitMargin: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          profitMargin: Yup.string().matches(RegEx.percentage(), t('textValidations.invalid')).notRequired(),
          highPrice: Yup.string().notRequired(),
          price: Yup.string().required(t('textValidations.required')),
          seller: Yup.string().required(t('textValidations.required')),
          product: Yup.string().required(t('textValidations.required')),
        })}
        validate={(values) => {
          const errors: any = {};
          if (values.highPrice !== '') {
            if (String(values.highPrice).charAt(0) === ',') {
              errors.highPrice = t('textValidations.invalid');
            }
            if (Number(String(values.price).replace(',', '.')) > Number(String(values.highPrice).replace(',', '.'))) {
              errors.price = t('price.form.textValidations.priceBiggerThen');
            }
          }
          if (values.price !== '') {
            if (String(values.price).charAt(0) === ',') {
              errors.price = t('textValidations.invalid');
            }
            if (Number(String(values.price).replace(',', '.')) === 0) {
              errors.price = String(t('textValidations.min')).replace(':min', '0.01');
            }
          }
          return errors;
        }}
      >
        {(props) => {
          const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } =
            props;
          return (
            <form onSubmit={handleSubmit}>
              <WrapperField>
                <AutocompleteChannels
                  labelBoxProps={{
                    mt: '24px',
                  }}
                  label="Channels"
                  onChange={(channel) => {
                    setFieldValue('salesChannel', channel.id);
                  }}
                />
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600">{`${formLabel.seller}`}</InfraLabel>
                <AutocompleteSellers
                  versionId="1"
                  onChange={(sellerId, label) => {
                    setFieldValue('seller', label);
                    setSellerId(sellerId);
                  }}
                  placeholder={formPlaceholder.seller}
                  channelId={values.salesChannel}
                />
                {errors.seller && touched.seller && <RowErrorMessage>{errors.seller}</RowErrorMessage>}
              </WrapperField>

              <WrapperField>
                <InfraLabel color="neutral_600">{formLabel.product}</InfraLabel>
                <SelectProducts
                  search={true}
                  value={values.product}
                  onChange={(data) => {
                    setFieldValue('product', data.label, true);
                    setProduct(data.value);
                  }}
                  placeholder={formPlaceholder.product}
                  searchPlaceholder={formPlaceholder.searchProduct}
                />
                {errors.product && touched.product && <RowErrorMessage>{errors.product}</RowErrorMessage>}
              </WrapperField>

              <div>
                <WrapperField>
                  <InfraLabel color="neutral_600" optional={true}>
                    {t('price.form.fields.clusters.label', 'Cluster')}
                  </InfraLabel>

                  <AutocompleteClusters
                    placeholder={t('price.form.fields.clusters.placeholder', 'Selecione')}
                    onChange={({ value }) => {
                      setFieldValue('clusterId', value);
                    }}
                  />
                </WrapperField>
                <WrapperField>
                  <InfraLabel color="neutral_600" optional={true}>
                    {formLabel.highPrice}
                  </InfraLabel>
                  <InfraInputCurrency
                    id="highPrice"
                    placeholder={formPlaceholder.highPrice}
                    decimalSeparator=","
                    inputStyle={errors.highPrice && touched.highPrice ? 'error' : 'normal'}
                    decimalsLimit={2}
                    onValueChange={(value, name) => {
                      setFieldValue('highPrice', value, true);
                    }}
                    message={errors.highPrice && touched.highPrice ? errors.highPrice : ''}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    height="small"
                  />
                </WrapperField>
                <WrapperField>
                  <InfraLabel color="neutral_600">{formLabel.price}</InfraLabel>
                  <InfraInputCurrency
                    id="price"
                    name="price"
                    placeholder={formPlaceholder.price}
                    decimalSeparator=","
                    decimalsLimit={2}
                    inputStyle={errors.price && touched.price ? 'error' : 'normal'}
                    onValueChange={(value, name) => {
                      setFieldValue(name, value, true);
                    }}
                    onBlur={handleBlur}
                    message={errors.price && touched.price ? errors.price : ''}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    height="small"
                  />
                </WrapperField>

                <WrapperField>
                  <InfraLabel color="neutral_600" optional={true}>
                    {formLabel.profitMargin}
                  </InfraLabel>
                  <InfraTextField
                    id="profitMargin"
                    placeholder={formPlaceholder.profitMargin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputStyle={errors.profitMargin && touched.profitMargin ? 'error' : 'normal'}
                    message={errors.profitMargin && touched.profitMargin ? errors.profitMargin : ''}
                    height="small"
                  />
                </WrapperField>

                <WrapperField>
                  <InfraLabel color="neutral_600" optional={true}>
                    {formLabel.costPrice}
                  </InfraLabel>
                  <InfraInputCurrency
                    id="costPrice"
                    name="costPrice"
                    placeholder={formPlaceholder.costPrice}
                    decimalSeparator=","
                    decimalsLimit={2}
                    onValueChange={(value, _) => {
                      setFieldValue('costPrice', value, true);
                    }}
                    inputStyle={errors.costPrice && touched.costPrice ? 'error' : 'normal'}
                    message={errors.costPrice && touched.costPrice ? errors.costPrice : ''}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    height="small"
                  />
                </WrapperField>

                <WrapperSwitchModal>
                  <div className="statusStyle">
                    <InfraTypography variant={'h5_small'} weight={'regular'} style={{ marginRight: '9px' }}>
                      Status
                    </InfraTypography>
                    <InfraSwitch onChange={(e, checked) => setStatus(checked)} checked={status} />
                  </div>
                  <div>
                    <InfraTag
                      color={status ? 'active' : 'inactive'}
                      name={status ? formLabel.statusActive : formLabel.statusInactive}
                      outline={false}
                      borde={false}
                    />
                  </div>
                </WrapperSwitchModal>
                <WrapperButtonModal>
                  <InfraButton
                    color="neutral"
                    type="submit"
                    size="large"
                    fullWidth={true}
                    outline={false}
                    disabled={isSubmitting}
                  >
                    {t('price.form.button.product')}
                  </InfraButton>
                </WrapperButtonModal>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ModalProduct;
