import {
  InfraDatepicker, InfraLabel,
  InfraTextField,
  InfraTypography
} from '@infralabs/design-system';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  AutocompleteSellers,
  AutocompleteStatus,
  Box,
  Button,
  Flex,
  SelectChargeTypes,
  useAccount,
} from '../../components';
import { KEY_CHANNEL_ID } from '../../constants/localStorageKeys';
import Colors from '../../Helper/Colors';

type ChargeToolbarProps = {
  onChange: any;
};

const ChargeToolbar: FC<ChargeToolbarProps> = (props) => {
  const { onChange } = props;
  const { t } = useTranslation();
  const { changeCurrentChannel } = useAccount();

  const [channelId, setChannelId] = useState(localStorage.getItem('ifc-channel-id') || '');

  useEffect(() => {
    const checkLocalStorage = () => {
      const value = localStorage.getItem('ifc-channel-id');
      if (value !== channelId) {
        setChannelId(value);
      }
    };

    const intervalId = setInterval(checkLocalStorage, 1000); // Verifica a cada 1 segundo

    return () => {
      clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
    };
  }, [channelId]);

  const [filter, setFilter] = useState({
    seller: '',
    type: '',
    status: '',
    description: '',
    activeAt: '',
    deactiveAt: '',
    channel: channelId,
  });

  const history = useHistory();

  const handleSellers = (id, name) => {
    setFilter((oldFilter) => {
      return {
        ...oldFilter,
        seller: id,
      };
    });
  };

  const handleType = (data) => {
    setFilter((oldFilter) => {
      return {
        ...oldFilter,
        type: data.value === -1 ? '' : data.value,
      };
    });
  };

  const handleStatus = (id) => {
    setFilter((oldFilter) => {
      return {
        ...oldFilter,
        status: id,
      };
    });
  };

  const handleDescription = (e) => {
    setFilter((oldFilter) => {
      return {
        ...oldFilter,
        description: e.target.value,
      };
    });
  };

  const handleDatepicker = (value) => {
    setFilter((oldFilter) => {
      let newFilter = oldFilter;
      if (Array.isArray(value)) {
        newFilter.activeAt = format(new Date(value[0]), 'yyyy-MM-dd HH:mm:ss');
        if (value[1] !== null) {
          newFilter.deactiveAt = format(new Date(value[1]), 'yyy-MM-dd HH:mm:ss');
        }
      } else {
        newFilter.activeAt = format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
      }

      return {
        ...newFilter,
      };
    });
  };

  useEffect(() => {
    setFilter((oldFilter) => {
      localStorage.setItem(KEY_CHANNEL_ID, localStorage.getItem('ifc-channel-id'));
      onChange?.(localStorage.getItem('ifc-channel-id') || {});
      changeCurrentChannel(localStorage.getItem('ifc-channel-id'));

      return {
        ...oldFilter,
        channel: localStorage.getItem('ifc-channel-id'),
      };
    });
  }, [, localStorage.getItem('ifc-channel-id')]);

  function isClean(data) {
    if (data) {
      setFilter((oldFilter) => {
        return {
          ...oldFilter,
          activeAt: '',
          deactiveAt: '',
        };
      });
    }
  }

  const goToCharge = () => {
    history.push('/charges/new');
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      onChange(filter);
    }, 800);
    return () => clearTimeout(delay);
  }, [filter]);

  return (
    <Flex justify="space-between">
      <Flex gap="8px" direction="row" grow="0" shrink="1" basis="auto" wrap="wrap">
        <Box w="200px">
          <InfraLabel color="neutral_600">{t('charge.fields.autocomplete.seller.label')}</InfraLabel>
          <AutocompleteSellers
            versionId="1"
            onChange={handleSellers}
            channelId={channelId}
            placeholder={t('charge.fields.autocomplete.seller.placeholder')}
          />
        </Box>
        <Box w="200px">
          <InfraLabel color="neutral_600">{t('charge.fields.autocomplete.type.label')}</InfraLabel>
          <SelectChargeTypes
            onChange={handleType}
            onFetch={() => {}}
            optionFilterDefault={true}
            placeholder={t('charge.fields.autocomplete.type.placeholder')}
            channelId={channelId}
          />
        </Box>

        <Box w="330px">
          <InfraLabel color="neutral_600">{t('programmedPrice.fields.filter.period.label')}</InfraLabel>
          <InfraDatepicker
            onCleared={isClean}
            height="small"
            autoComplete={'off'}
            subtractYears={10}
            placeholder={t('programmedPrice.fields.filter.period.placeholder')}
            period={true}
            value={''}
            shouldCloseOnSelect={false}
            onChange={handleDatepicker}
            theme="light"
            setHours={true}
            maskDate="dd.MM.yyyy, HH:mm"
          />
        </Box>

        <Box>
          <InfraLabel color="neutral_600">{t('charge.fields.autocomplete.status.label')}</InfraLabel>
          <Box w="159.01px">
            <AutocompleteStatus
              versionId="1"
              typePrice="programmed"
              onChange={handleStatus}
              placeholder={t('charge.fields.autocomplete.status.placeholder')}
            />
          </Box>
        </Box>

        <Box w="327px">
          <InfraLabel>&nbsp;</InfraLabel>
          <div>
            <InfraTextField
              placeholder={t('charge.fields.textField.name.placeholder')}
              height="small"
              icon="MagnifyingGlass"
              position="end"
              onChange={handleDescription}
            />
          </div>
        </Box>
      </Flex>
      <Flex>
        <Box>
          <InfraLabel>&nbsp;</InfraLabel>
          <Button color="neutral" size="large" outline={false} onClick={goToCharge} icon={'Plus'} iconRight={true}>
            <InfraTypography variant={'h5_small'} weight={'bold'} color={Colors.shade_0}>
              {t('charge.button.new')}
            </InfraTypography>
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ChargeToolbar
