import styled from 'styled-components';

export const TextModalContent = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  line-height: 22px;
  font-weight: normal;
  text-align: center;
  font-size: 16px;
  color: #414f62;
`;
