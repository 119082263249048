import { Box } from "../../components";
import {
  InfraBreadcrumbs,
} from '@infralabs/design-system';
import { FC } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const PromotionsBreadcrumb: FC = () => {
  const { t } = useTranslation()

  const links = [
    {
      text: t('app'),
      href: '/',
      use: 'link',
    },
    {
      text: t('main'),
      href: '/',
      disabled: false,
    },
    {
      text: t('promotion.breadcrumbs.home'),
      disabled: false,
    },
  ];

  return (
      <Box>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </Box>
    );
}

export default PromotionsBreadcrumb
