import styled from 'styled-components';

export const ContentContainer = styled.div`
  font-size: 16px;
  text-align: center;
  color: #414f62;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  line-height: 22px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 28px;
`;
export const WrapperIcon = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  width: 54px;
  height: 54px;
  margin-bottom: 15px;
`;

export const WrapperButton = styled.div`
  padding-right: 17px;
  padding-left: 17px;
`;

export const WrapperMessage = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextMessage = styled.div`
  min-width: 184px;
  min-height: 48px;
  font-size: 16px;
  text-align: center;
  color: #414f62;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  line-height: 22px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
`;
