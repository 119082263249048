import {
  InfraDatepicker,
  InfraLabel,
  InfraSwitch,
  InfraTag,
  InfraTextarea,
  InfraTextField,
} from '@infralabs/design-system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KEY_CHANNEL_ID } from '../../../constants/localStorageKeys';

import { AutocompleteSellers, Box, Flex, RowErrorMessage, SelectPromotionTypes, useAccount } from '../../../components';

const PromotionStep = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  controller,
  isEdit = false,
}: any) => {
  const { sellerController, isAccumulativeController, firstBuyController } = controller;
  const [sellerId, setSellerId] = sellerController;
  const [isAccumulative, setIsAccumulative] = isAccumulativeController;
  const [firstBuy, setFirstBuy] = firstBuyController;
  const { t } = useTranslation();
  const { changeCurrentChannel } = useAccount();

  useEffect(() => {
    changeCurrentChannel(localStorage.getItem('ifc-channel-id'));
    localStorage.setItem(KEY_CHANNEL_ID, localStorage.getItem('ifc-channel-id'));
  }, []);

  const [valuePromotionType, setValuePromotionType] = useState<boolean>(false);

  const formLabels = {
    seller: t('promotion.forms.promotion.labels.seller'),
    cluster: t('promotion.forms.promotion.labels.cluster'),
    activateAt: t('promotion.forms.promotion.labels.activateAt'),
    deactivateAt: t('promotion.forms.promotion.labels.deactivateAt'),
    promotionType: t('promotion.forms.promotion.labels.promotionType'),
    name: t('promotion.forms.promotion.labels.name'),
    isAccumulative: t('promotion.forms.promotion.labels.isAccumulative'),
    isAccumulativeYes: t('yes'),
    isAccumulativeNo: t('no'),
    firstBuy: t('promotion.forms.promotion.labels.firstBuy'),
    firstBuyYes: t('yes'),
    firstBuyNo: t('no'),
    description: t('promotion.forms.promotion.labels.description'),
  };
  const formPlaceholders = {
    seller: t('promotion.forms.promotion.placeholders.seller'),
    cluster: t('promotion.forms.promotion.placeholders.cluster'),
    activateAt: t('promotion.forms.promotion.placeholders.activateAt'),
    deactivateAt: t('promotion.forms.promotion.placeholders.deactivateAt'),
    promotionType: t('promotion.forms.promotion.placeholders.promotionType'),
    description: t('promotion.forms.promotion.placeholders.description'),
    name: t('promotion.forms.promotion.placeholders.name'),
  };

  const handleFieldNameMessage = () => {
    if (errors.name && touched.name) {
      return errors.name;
    }
    return t('promotion.forms.promotion.messages.max').replace(':max', '150');
  };

  const renderSellerInput = () => {
    if (!isEdit) {
      return (
        <AutocompleteSellers
          versionId="1"
          defaultValue={values.seller}
          onChange={(seller, label) => {
            setFieldValue('seller', label);
            setSellerId(seller);
          }}
          placeholder={formPlaceholders.seller}
          channelId={localStorage.getItem('ifc-channel-id')}
        />
      );
    }
    return <InfraTextField height="small" disabled={true} value={values.seller} />;
  };

  return (
    <>
      <Flex gap="64px" direction="column">
        <Flex gap="45px">
          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.seller}</InfraLabel>
            {renderSellerInput()}
            {errors.seller && touched.seller && <RowErrorMessage>{errors.seller}</RowErrorMessage>}
          </Box>

          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.activateAt}</InfraLabel>
            <InfraDatepicker
              id="activateAt"
              name="activateAt"
              autoComplete={'off'}
              value={values.activateAt}
              setHours={true}
              shouldCloseOnSelect={false}
              onChange={(value) => {
                setFieldValue('activateAt', value);
              }}
              placeholder={formPlaceholders.activateAt}
              height="small"
              maskDate="dd.MM.yyyy, HH:mm"
            />
            {errors.activateAt && <RowErrorMessage>{errors.activateAt}</RowErrorMessage>}
          </Box>
          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.deactivateAt}</InfraLabel>
            <InfraDatepicker
              id="deactivateAt"
              name="deactivateAt"
              autoComplete={'off'}
              value={values.deactivateAt}
              setHours={true}
              shouldCloseOnSelect={false}
              placeholder={formPlaceholders.deactivateAt}
              onChange={(value) => setFieldValue('deactivateAt', value)}
              height="small"
              maskDate="dd.MM.yyyy, HH:mm"
            />
            {errors.deactivateAt && <RowErrorMessage>{errors.deactivateAt}</RowErrorMessage>}
          </Box>
        </Flex>

        <Flex gap="45px">
          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.promotionType}</InfraLabel>
            <SelectPromotionTypes
              onChange={(data) => {
                setFieldValue('promotionTypeId', data.value, true);
                if (values.deliveryModes !== undefined) {
                  setFieldValue('deliveryModes', [], true);
                }

                if (values.methodsPayment !== undefined) {
                  setFieldValue('methodsPayment', [], true);
                }
              }}
              onFetch={() => {
                setValuePromotionType(true);
              }}
              value={valuePromotionType ? values.promotionTypeId || [] : []}
              placeholder={formPlaceholders.promotionType}
              channelId={localStorage.getItem('ifc-channel-id')}
            />
            {errors.promotionTypeId && <RowErrorMessage>{errors.promotionTypeId}</RowErrorMessage>}
          </Box>

          <Box w="270px">
            <InfraLabel color="neutral_600">{formLabels.name}</InfraLabel>
            <InfraTextField
              id="name"
              name="name"
              placeholder={formPlaceholders.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              inputStyle={errors.name && touched.name ? 'error' : 'normal'}
              message={handleFieldNameMessage()}
              height="small"
            />
          </Box>

          <Flex w="320px" justify="space-between" align={'center'} mt={'1rem'} gap="13px">
            <Box mt="5px" mr="-13px">
              <InfraLabel color="neutral_600">{formLabels.isAccumulative}:</InfraLabel>
            </Box>
            <div>
              <InfraSwitch
                onChange={(e, checked) => {
                  setIsAccumulative(checked);
                }}
                checked={isAccumulative || false}
              />
            </div>
            <Box pos="relative">
              <InfraTag
                color={isAccumulative ? 'active' : 'errorDefault'}
                name={isAccumulative ? formLabels.isAccumulativeYes : formLabels.isAccumulativeNo}
                outline={false}
                border={false}
              />
            </Box>
          </Flex>

          <Flex w="320px" justify="space-between" align={'center'} mt={'1rem'} gap="13px">
            <Box mt="5px" mr="-13px">
              <InfraLabel color="neutral_600">{formLabels.firstBuy}:</InfraLabel>
            </Box>
            <div>
              <InfraSwitch
                onChange={(e, checked) => {
                  setFirstBuy(checked);
                }}
                checked={firstBuy || false}
              />
            </div>
            <Box pos="relative">
              <InfraTag
                color={firstBuy ? 'active' : 'errorDefault'}
                name={firstBuy ? formLabels.firstBuyYes : formLabels.firstBuyNo}
                outline={false}
                border={false}
              />
            </Box>
          </Flex>
        </Flex>

        <Flex gap="20px" mt="-20px">
          <div style={{ flex: '1' }}>
            <InfraLabel color="neutral_600">{formLabels.description}</InfraLabel>
            <InfraTextarea
              id="description"
              value={values.description}
              name="description"
              placeholder={formPlaceholders.description}
              onChange={handleChange}
              icon={false}
              style={{ height: '178px' }}
            >
              {values.description}
            </InfraTextarea>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default PromotionStep;
