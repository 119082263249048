import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { InfraAutocomplete, InfraLabel, InfraTextField } from '@infralabs/design-system';
import { useTranslation } from 'react-i18next';
import { Channel } from '../../Model/Channel';
import { useAccount } from '../CurrentAccount';
import { AutocompleteChannelsProps } from './types';
import { AccountRepository } from '../../Repository';
import { Box } from '../index';
import { KEY_CHANNEL_ID, KEY_SELLER_ID } from '../../constants/localStorageKeys';
const accountRepository = new AccountRepository();

export const AutocompleteChannels: FC<AutocompleteChannelsProps> = memo<AutocompleteChannelsProps>(
  ({
    onChange,
    labelBoxProps = {},
    labelProps = {},
    value,
    readonly,
    label,
    name = 'channels',
    placeholder = 'Select',
  }) => {
    const prevTenant = useRef('');
    const channelName = useRef('');
    const { currentTenant, currentChannel, changeCurrentChannel } = useAccount();
    const [channels, setChannels] = useState<Channel[]>([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [localValue, setLocalValue] = useState<any>(value);
    const [inputValue, setInputValue] = useState('');
    const { t } = useTranslation();

    const handleEvent = (_, value) => {
      localStorage.setItem(KEY_CHANNEL_ID, value.id);
      localStorage.setItem(KEY_SELLER_ID, '');
      setOpen(false);
      setLocalValue(value?.name ?? '');
      onChange?.(value ?? '');
    };

    async function findAllAndLoad() {
      setLoading(true);
      try {
        const { accounts } = await accountRepository.getAllChannels('1', currentTenant.id);
        const formattedChannels = accounts.map((channel) => ({
          label: channel.name || '',
          ...channel,
        }));

        setChannels(formattedChannels);
      } catch (error) {
        setChannels([]);
      } finally {
        setLoading(false);
        setLocalValue(undefined);
      }
    }

    const handleChange = (channel?: Channel) => {
      if (channel) {
        setLocalValue(channel);
        onChange?.(channel || {});
        changeCurrentChannel(channel);
        channelName.current = channel?.name;
      }
    };

    useEffect(() => {
      if (!currentTenant?.id) return;
      const channel = localStorage.getItem(KEY_CHANNEL_ID);
      setLocalValue(channel);
      findAllAndLoad();
    }, [currentTenant]);

    useEffect(() => {
      if (!currentTenant) return;
      if (prevTenant.current && prevTenant.current === currentTenant.id) return;
      if (!prevTenant.current && currentTenant.id) {
        prevTenant.current = currentTenant.id;
        return;
      }

      setLocalValue(undefined);
      handleChange();
    }, [currentTenant]);

    useEffect(() => {
      if (localValue && !value) return;
      const selected = channels.find((channel) => channel.id === value);
      handleChange(selected);
    }, [value, channels]);

    useEffect(() => {
      if (localValue) return;
      let selected = channels.find((channel) => currentChannel?.id === channel.id);
      if (!selected) {
        selected = channels.find((channel) => Boolean(channel?.name));
      }

      return handleChange(selected);
    }, [localValue, currentChannel, channels, value]);

    const AUTOCOMPLETE_VALUE = useMemo(() => localValue?.name || localValue, [localValue]);
    return (
      <>
        {label ? (
          <Box {...labelBoxProps}>
            <InfraLabel {...labelProps} color="neutral_600">
              {label}
            </InfraLabel>
          </Box>
        ) : undefined}
        {readonly || loading ? (
          <InfraTextField
            disabled={true}
            name={name}
            value={loading ? t('loading') : channelName.current || AUTOCOMPLETE_VALUE}
            height="small"
          />
        ) : (
          <InfraAutocomplete
            size="small"
            disableClearable
            onChange={handleEvent}
            values={channels}
            options={channels}
            value={AUTOCOMPLETE_VALUE}
            loading={loading}
            placeholder={placeholder}
            loadingText={t('loading')}
            onInputChange={(_, newInputEvent) => {
              setInputValue(newInputEvent);
            }}
            inputValue={inputValue}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open}
            noOptionsText={t('noOption')}
          />
        )}
      </>
    );
  },
);
