import { InfraTag } from '@infralabs/design-system';
import styled from 'styled-components';
import Colors from '../../../Helper/Colors';
import { Type } from './type';

export const Content = styled.div<Type>`
  background-color: ${Colors.shade_0};
  overflow-y: hidden;
  max-width: 1248px;
  height: ${(props) => (props.height ? props.height : '1079px')};
  border-radius: 16px;
  border: 1px solid ${Colors.neutral_200};
  padding: 19px;
`;
export const Tag = styled.div<InfraTag>`
  display: flex;
  margin-left: 24px;
  align-items: center;
`;
export const Title = styled.div`
  color: ${Colors.neutral_900};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const TitleChips = styled.div`
  color: ${Colors.neutral_900};
  font-weight: 600;
  font-size: 16px;
`;
export const TitleError = styled.div`
  color: ${Colors.neutral_400};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16.75px;
`;
export const TitleErrorChips = styled.div`
  color: ${Colors.neutral_400};
  font-weight: 600;
  font-size: 16px;
`;
export const BodyColumn = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  color: ${Colors.neutral_900};
  justify-content: space-between;
`;
export const BodyColumnFreight = styled.div`
  display: flex;
  margin-top: 10px;
  color: ${Colors.neutral_900};
`;
export const BoxChip = styled.div`
  flex: 1;
  gap: 10px;
  width: 100%;
  color: ${Colors.neutral_900};
  justify-content: space-between;
`;
export const BodyName = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${Colors.neutral_400};
  margin-bottom: 5px;
`;
export const BodyValue = styled.div`
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: ${Colors.neutral_600};
`;
export const MessageError = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 7px;
  font-size: 16px;
  line-height: 22px;
`;
export const WarningWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const SpacingBox = styled.div`
  margin-top: 45px;
`;
export const Hr = styled.hr`
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid ${Colors.neutral_200};
  width: 518px;
  left: 32px;
  background: ;
`;

export const WrapperMessageErrorChips = styled.div`
  margin-left: 15px;
`;
export const WrapperBreadCrumbs = styled.div`
  margin-bottom: 9px;
`;
export const BoxSKU = styled.div`
  background: red;
  margin-bottom: 100px;
`;
