import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfraSelect } from '@infralabs/design-system';

import { ImportStatus } from '../../Model';
import { ImportStatusRepository } from '../../Repository';
import { useAccount } from '../CurrentAccount';

const importStatusRepository = new ImportStatusRepository();

type SelectImportStatusProps = {
  onChange: (data: any) => void;
  value?: any;
  placeholder?: string;
  onFetched?: () => void;
  optionFilterDefault?: boolean;
  disable?: boolean;
}

const PriceSelectImportStatus = ({ onChange, placeholder, value, onFetched, optionFilterDefault = false, ...rest }: SelectImportStatusProps) => {
  const { currentTenant, currentChannel } = useAccount();
  const { t } = useTranslation()
  const [items, setItems] = useState<{ label: string, value: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function prepareItemsSelect(data: ImportStatus[]) {
    const options = data.map((el: ImportStatus) => ({
      label: el.status,
      value: el.id,
      selected: value === el.id ? true : false
    }));
    if (optionFilterDefault) {
      setItems([{ label: t('optionFilterDefault'), value: -1 }, ...options])
    } else {
      setItems(options)
    }
    const onFetchedCallback = onFetched || null
    if (onFetchedCallback) {
      onFetchedCallback();
    }
  }

  useEffect(() => {
    if (!currentChannel || !currentTenant) return
    setLoading(true);
    (async () => {
      try {
        const { records } = await importStatusRepository.setChannelId(currentChannel.id).findAll('1');
        prepareItemsSelect(records);
      } catch (error) {
        setItems([])
      } finally {
        setLoading(false);
      }
    })();
  }, [currentTenant, currentChannel])

  useEffect(() => {
    const options = items.map((item) => ({
      ...item,
      selected: value == item.value ? true : false
    }));
    setItems(options);
  }, [value])

  return (
    <InfraSelect
      items={items}
      loading={loading}
      handlerClick={onChange}
      placeholder={placeholder}
      size={'small'}
      {...rest}
    />
  );
}

export const SelectImportStatus = memo(PriceSelectImportStatus)
