import { InfraCol, InfraGrid, InfraModal } from '@infralabs/design-system';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertModal, Box, Paginate } from '../../components';
import { KEY_SELLER_ID, KEY_SELLER_LABEL } from '../../constants/localStorageKeys';
import { WrapperModalConfirm } from '../styles/style-wrapper-modal';
import PromotionsBreadcrumb from './Breadcrumbs';
import PromotionTable from './Table';
import PromotionTitle from './Title';
import PromotionToolbar from './Toolbar';
import ModalDeletePromotion from './modalDeletePromotion';
import usePromotionsPaginate from './usePromotionsPaginate';

const PromotionsComponent = ({ locationUrl = '' }: { locationUrl: string }) => {
  const [filter, setFilter] = useState({
    seller: '',
    type: '',
    status: '',
    description: '',
    activeAt: '',
    deactiveAt: '',
    channel: localStorage.getItem('ifc-channel-id') ?? '',
  });
  const [pageSize, setPageSize] = useState(10);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [contextAlert, setContexAlert] = useState<{
    context: string;
    type: 'success' | 'failed' | 'both';
    messages?: any;
  }>({ context: '', type: 'success' });
  const [showModalDeletePromotion, setShowModalDeletePromotion] = useState(false);
  const [order, setOrder] = useState('status.id');
  const [promotionSelected, setPromotionSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const params = useMemo(
    () => ({
      currentPage,
      pageSize,
      order,
      filter,
    }),
    [currentPage, pageSize, order, filter],
  );
  const { data: promotions, loading, paging, refetch: loadPromotions } = usePromotionsPaginate(params);

  useEffect(() => {
    localStorage.setItem(KEY_SELLER_ID, '');
    localStorage.setItem(KEY_SELLER_LABEL, '');
  }, []);

  const handlePagination = (_, value) => {
    setCurrentPage(value);
  };

  const handleDelete = (promotionData) => {
    setPromotionSelected(promotionData);
    setShowModalDeletePromotion(true);
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const renderModalAlert = () => {
    if (contextAlert.context === 'delete') {
      if (contextAlert.type === 'failed') {
        return 'Falha ao deletar o registro';
      } else if (contextAlert.type === 'success') {
        return 'Registro deletado com sucesso';
      }
    }
  };

  const handleChangeFilter = (filter) => {
    setFilter(filter)
    setCurrentPage(1)
  }
  const history = useHistory()
  useEffect(() => {
    if (locationUrl != '') {
      history.push(locationUrl)
    }
  }, [locationUrl])

  return (
    <>
      <InfraGrid>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt={'31px'} w="100%">
            <PromotionsBreadcrumb />
          </Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt={'32px'} w="100%">
            <PromotionTitle />
          </Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt={'22px'} w="100%">
            <PromotionToolbar onChange={handleChangeFilter} />
          </Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt={'-10px'} w="100%" overflowX="auto">
            <PromotionTable
              loading={loading}
              onChangeOrder={(order) => setOrder(order)}
              onRemove={handleDelete}
              order={order}
              promotions={promotions}
            />
          </Box>
        </InfraCol>
        <InfraCol xxl={12} xl={12} md={8} xs={4}>
          <Box mt="4px" mb="64px" w="100%">
            <Paginate
              onChangePageSize={handlePageSize}
              onChangePage={handlePagination}
              totalPages={paging?.totalPages}
              currentPage={currentPage}
            />
          </Box>
        </InfraCol>
      </InfraGrid>

      <WrapperModalConfirm>
        <InfraModal
          theme="light"
          show={showModalDeletePromotion}
          onClose={() => {
            setShowModalDeletePromotion(false);
            setPromotionSelected(null);
          }}
        >
          <ModalDeletePromotion
            onCloseModal={({ result }) => {
              setShowModalDeletePromotion(false);
              if (result) {
                loadPromotions();
                setContexAlert({ context: 'delete', type: result });
                setShowModalAlert(true);
              }
              setPromotionSelected(null);
            }}
            promotion={promotionSelected}
          />
        </InfraModal>
      </WrapperModalConfirm>

      <AlertModal
        variation={contextAlert.type === 'both' ? 'failed' : contextAlert.type}
        message={renderModalAlert()}
        show={showModalAlert}
        onConfirmModal={() => setShowModalAlert(false)}
        onClose={() => setShowModalAlert(false)}
        width={contextAlert.context === 'delete' ? '278' : undefined}
      />
    </>
  );
};

export default PromotionsComponent;
