import styled from 'styled-components';
import { FlexBox, SpaceProps, PositionProps, OtherProps } from '.';

export interface LayoutProps extends SpaceProps, FlexBox, PositionProps, OtherProps {}

const LayoutWrapper = styled.div<LayoutProps>`

  z-index: ${({ zIndex }) => zIndex };
  position: ${({ pos }) => pos };
  top: ${({ pos }) => pos };
  right: ${({ right }) => right };
  left: ${({ left }) => left };
  bottom: ${({ bottom }) => bottom };
  top: ${({ pos }) => pos };
  inset: ${({ inset }) => inset };

  display: ${({d}) => d };
  width: ${({ w }) => w };
  min-width: ${({ minW }) => minW };
  max-width: ${({ maxW }) => maxW };

  height: ${({ h }) => h };
  min-height: ${({ minH }) => minH };
  max-height: ${({ maxH }) => maxH };

  margin: ${({ m }) => m};
  margin-top: ${({ mt }) => mt};
  margin-right: ${({ mr }) => mr};
  margin-left: ${({ ml }) => ml};
  margin-bottom: ${({ mb }) => mb};
  margin-inline: ${({ mx }) => mx};
  margin-block: ${({ my }) => my};
  padding: ${({ p }) => p};
  padding-top: ${({ pt }) => pt};
  padding-right: ${({ pr }) => pr};
  padding-left: ${({ pl }) => pl};
  padding-bottom: ${({ pb }) => pb};
  padding-inline: ${({ px }) => px};
  padding-block: ${({ py }) => py};

  flex-direction:  ${({ direction }) => direction};
  align-items: ${({ align }) => align};
  justify-content:  ${({ justify }) => justify };
  flex-wrap: ${({ wrap }) => wrap };
  flex-basis: ${({ basis }) => basis};
  flex-grow: ${({ grow }) => grow};
  flex-shrink: ${({ shrink }) => shrink};
  gap: ${({ gap }) => gap };

  overflow-x: ${({ overflowX }) => overflowX };
  overflow-y: ${({ overflowY }) => overflowY };
`;

export default LayoutWrapper;