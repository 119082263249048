import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  InfraTextField,
  InfraButton,
  InfraTag,
  InfraSwitch,
  InfraInputCurrency,
  InfraLabel,
  InfraDatepicker,
  InfraTypography,
} from '@infralabs/design-system';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { Prices } from '../../Model/Prices';
import {
  AutocompleteSkus,
  RowErrorMessage,
  BoxErrorMessage,
  WrapperField,
  AutocompleteSellers,
  Flex,
  Box,
  SelectSkus,
} from '../../components';
import { WrapperMessage } from '../../components/AlertMessage/styles';
import { RegEx } from '../../Helper/RegEx';
import { SchedulePriceRepository } from '../../Repository/SchedulePriceRepository';
import { getValueValid, isDateDiffMinutesAllowed } from '../../Helper';
import { AdaptButton } from '../styles/style-wrapper-modal';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';

const ModalSku = ({ onCloseModal, price, cbAlertModal }) => {
  const { t } = useTranslation();

  const formLabel = {
    scheduledAt: t('scheduledPrice.form.labels.scheduledAt'),
    costPrice: t('scheduledPrice.form.labels.costPrice'),
    price: t('scheduledPrice.form.labels.price'),
    highPrice: t('scheduledPrice.form.labels.highPrice'),
    sku: t('scheduledPrice.form.labels.sku'),
    seller: t('scheduledPrice.form.labels.seller'),
    statusActive: t('scheduledPrice.form.labels.statusActive'),
    statusInactive: t('scheduledPrice.form.labels.statusInactive'),
  };

  const formPlaceholder = {
    scheduledAt: t('scheduledPrice.form.placeholders.scheduledAt'),
    costPrice: t('scheduledPrice.form.placeholders.costPrice'),
    price: t('scheduledPrice.form.placeholders.price'),
    highPrice: t('scheduledPrice.form.placeholders.highPrice'),
    product: t('scheduledPrice.form.placeholders.product'),
    sku: t('scheduledPrice.form.placeholders.sku'),
    seller: t('scheduledPrice.form.placeholders.seller'),
  };

  const schema = Yup.object().shape({
    highPrice: Yup.string(),
    price: Yup.string().required(t('textValidations.required')),
    seller: Yup.string().required(t('textValidations.required')),
    scheduledAt: Yup.string().required(t('textValidations.required')),
    profitMargin: Yup.string().matches(RegEx.percentage(), t('textValidations.invalid')).notRequired(),
    sku: Yup.string().required(t('textValidations.required')),
  });

  const [checked, setChecked] = useState(price?.active || false);
  const [sellerAutoComplete, setSellerAutoComplete] = useState('');
  const [skuId, setSkuId] = useState(price?.skuId);
  const [showMessageAutoComplete, setShowMessageAutoComplete] = useState(false);
  const [errorAlert, setErrorAlert] = useState('');

  const handleValues = (values) => {
    let { price, highPrice, scheduledAt, salesChannel } = values;
    price = price.toString();
    highPrice = highPrice == undefined ? '' : highPrice.toString();
    scheduledAt = scheduledAt;

    let priceReplace = null;
    if (price.indexOf(',') !== -1) {
      priceReplace = Number(price.replace(',', '.'));
    } else {
      priceReplace = Number(values.price);
    }
    let highPriceReplace = null;
    if (highPrice && highPrice.indexOf(',') !== -1) {
      highPriceReplace = Number(highPrice.replace(',', '.'));
    } else {
      highPriceReplace = Number(values.highPrice);
    }
    if (highPriceReplace === 0) {
      highPriceReplace = undefined;
    }
    if (scheduledAt === undefined || scheduledAt === '' || scheduledAt.length === 0) {
      scheduledAt = '';
    }
    return { priceReplace, highPriceReplace, scheduledAt, salesChannel };
  };

  const handleSubmit = async (values, actions) => {
    const schedulePriceRepository = new SchedulePriceRepository();
    const { priceReplace, highPriceReplace, scheduledAt, salesChannel } = await handleValues(values);
    actions.setSubmitting(false);
    try {
      schedulePriceRepository.setChannelId(salesChannel)
      if (price) {
        const data = [
          {
            id: price.id,
            skuId: skuId,
            currency: 986,
            isActive: checked,
            highPrice: highPriceReplace,
            price: priceReplace,
            sellerId: price.sellerId,
            scheduledAt: scheduledAt,
          } as Prices,
        ];
        await schedulePriceRepository.update('1', data);
        onCloseModal(false);
        cbAlertModal({ typeOperation: 'edit' });
      } else {
        if (sellerAutoComplete === '') {
          setShowMessageAutoComplete(true);
          return;
        }

        const data = [
          {
            skuId: skuId,
            currency: 986,
            highPrice: highPriceReplace,
            price: priceReplace,
            isActive: checked,
            sellerId: sellerAutoComplete,
            scheduledAt: scheduledAt,
          } as Prices,
        ];

        await schedulePriceRepository.save('1', data);
        onCloseModal(false);
        cbAlertModal({ typeOperation: 'new' });
      }
    } catch (error) {
      if (error.response.data.error) {
        actions.setFieldError('scheduledAt', String(t('scheduledPrice.form.textValidations.ScheduledGreaterToday')));
      }

      if (error.response.data.data) {
        error.response.data.data['scheduled-prices'].map((price) => {
          if (price.error === 'SkuAreadyExists') {
            actions.setFieldError(
              'sku',
              String(t('scheduledPrice.form.textValidations.SkuAreadyExists')).replace(':sku', ''),
            );
          }
          if (price.error === 'ScheduledPriceNotExists') {
            actions.setFieldError(
              'sku',
              String(t('scheduledPrice.form.textValidations.ScheduledPriceNotExists')).replace(':sku', ''),
            );
          }
        });
      }
    }
  };

  const handleSwitch = (_, checkedEvent) => {
    setChecked(checkedEvent);
  };

  const minTimeDifference = (hoursInSeconds: number) => new Date(new Date().getTime() + hoursInSeconds);

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          sku: price?.sku || '',
          highPrice: price?.highPrice || '',
          price: price?.price || '',
          scheduledAt: price?.scheduledAt || '',
          active: price?.active || false,
          seller: price?.seller || '',
          salesChannel: ''
        }}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: any = {};
          if (values.highPrice !== '') {
            if (Number(String(values.price).replace(',', '.')) > Number(String(values.highPrice).replace(',', '.'))) {
              errors.price = t('scheduledPrice.form.textValidations.priceBiggerThen');
            }
          }

          if (values.scheduledAt == '') {
            errors.scheduledAt = t('textValidations.required');
          }

          if (isDateDiffMinutesAllowed(values.scheduledAt, +process.env.SCHEDULE_DIFFERECE_DATE_LIMIT)) {
            errors.scheduledAt = t('scheduledPrice.form.textValidations.ScheduledGreaterToday', {
              minutes: process.env.SCHEDULE_DIFFERECE_DATE_LIMIT,
            });
          }

          if (values.price && values.price <= 0) {
            errors.price = t('textValidations.invalid');
          }

          return errors;
        }}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <Form>
            {errorAlert ? <BoxErrorMessage>{errorAlert}</BoxErrorMessage> : null}
            <WrapperField>
              <AutocompleteChannels
                labelBoxProps={{
                  mt: '24px'
                }}
                readonly={Boolean(price)}
                label="Channels"
                onChange={(salesChannel) => {
                  setFieldValue('salesChannel', salesChannel.id);
                }}
              />
            </WrapperField>
            <WrapperField>
              {price !== null ? (
                <>
                  <div style={{ marginTop: '24px' }}>
                    <InfraLabel color="neutral_600">{formLabel.seller}</InfraLabel>
                  </div>
                  <InfraTextField disabled={true} name="seller" value={price.seller} height="small" />
                </>
              ) : (
                <>
                  <div style={{ marginTop: '24px' }}>
                    <InfraLabel color="neutral_600">{formLabel.seller}</InfraLabel>
                  </div>
                  <AutocompleteSellers
                    versionId="1"
                    channelId={values.salesChannel}
                    onChange={(sellerId, sellerName) => {
                      setFieldValue('seller', sellerName);
                      setSellerAutoComplete(sellerId);
                    }}
                    placeholder={formPlaceholder.seller}
                  />
                  {errors.seller && touched.seller && <WrapperMessage>{errors.seller}</WrapperMessage>}
                </>
              )}
              {showMessageAutoComplete ? <RowErrorMessage>Seller é obrigatório</RowErrorMessage> : null}
            </WrapperField>
            <div>
              <WrapperField>
                {price !== null ? (
                  <>
                    <InfraTextField disabled={true} name="seller" value={price.sku} label="SKU" height="small" />
                  </>
                ) : (
                  <>
                    <InfraLabel color="neutral_600">{formLabel.sku}</InfraLabel>
                    <SelectSkus
                      search={true}
                      value={values.sku}
                      placeholder={formPlaceholder.sku}
                      onChange={(data) => {
                        setFieldValue('sku', data.value, true);
                        setSkuId(data.value);
                      }}
                    />
                    {errors.sku && touched.sku && <RowErrorMessage>{errors.sku}</RowErrorMessage>}
                  </>
                )}
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600" optional={true}>
                  {formPlaceholder.highPrice}
                </InfraLabel>
                <InfraInputCurrency
                  name="highPrice"
                  disabled={price ? true : false}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  autoComplete="off"
                  onBlur={handleBlur}
                  placeholder={formPlaceholder.highPrice}
                  value={values.highPrice}
                  onValueChange={(value) => setFieldValue('highPrice', getValueValid(value))}
                  decimalSeparator=","
                  decimalsLimit={2}
                  inputStyle={errors.highPrice && touched.highPrice ? 'error' : 'normal'}
                  height="small"
                  message={errors.highPrice && touched.highPrice ? errors.highPrice : ''}
                />
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600">{formLabel.price}</InfraLabel>
                <InfraInputCurrency
                  name="price"
                  disabled={price ? true : false}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  autoComplete="off"
                  onBlur={handleBlur}
                  placeholder={formPlaceholder.price}
                  value={values.price}
                  onValueChange={(value) => setFieldValue('price', getValueValid(value))}
                  decimalSeparator=","
                  decimalsLimit={2}
                  inputStyle={errors.price && touched.price ? 'error' : 'normal'}
                  height="small"
                  message={errors.price && touched.price ? errors.price : ''}
                />
              </WrapperField>

              <WrapperField>
                {price !== null ? (
                  <>
                    <InfraTextField
                      disabled={true}
                      value={format(new Date(values.scheduledAt), 'dd/MM/yyyy HH:mm:ss')}
                      label={t('scheduledPrice.form.labels.scheduledAt')}
                      height="small"
                    />
                  </>
                ) : (
                  <>
                    <InfraLabel label="scheduledAt" theme="light" color="neutral_600">
                      {t('scheduledPrice.form.labels.scheduledAt')}
                    </InfraLabel>
                    <InfraDatepicker
                      disabled={price ? true : false}
                      name="scheduledAt"
                      autoComplete={'off'}
                      height="small"
                      subtractYears={10}
                      placeholder={t('scheduledPrice.fields.filter.period.placeholder')}
                      onChange={async (value, name) => {
                        setFieldValue('scheduledAt', value, true);
                      }}
                      theme="light"
                      setHours={true}
                      maskDate="dd.MM.yyyy, HH:mm"
                      shouldCloseOnSelect={false}
                      minTime={minTimeDifference(7200)}
                    />
                    {errors.scheduledAt && <WrapperMessage>{errors.scheduledAt}</WrapperMessage>}
                  </>
                )}
              </WrapperField>

              <Flex align={'center'} justify={'space-between'} mt="23px">
                <Flex align={'center'}>
                  <InfraTypography variant={'h5_small'} weight={'regular'} style={{ marginRight: '9px' }}>
                    Status
                  </InfraTypography>
                  <Box mt="-2px">
                    <InfraSwitch checked={checked} onChange={(_, checkedEvent) => handleSwitch(_, checkedEvent)} />
                  </Box>
                </Flex>
                {checked ? (
                  <div>
                    <InfraTag color="active" name={formLabel.statusActive} outline={false} borde={false} />
                  </div>
                ) : (
                  <div>
                    <InfraTag color="inactive" name={formLabel.statusInactive} outline={false} borde={false} />
                  </div>
                )}
              </Flex>
              <Box mt="32px">
                {price !== null ? (
                  <AdaptButton>
                    <InfraButton
                      color="neutral"
                      type="submit"
                      size="medium"
                      style={{ heigth: '45px' }}
                      fullWidth={true}
                      outline={false}
                      onClick={() => onCloseModal(true)}
                    >
                      {t('scheduledPrice.form.button.sku')}
                    </InfraButton>
                  </AdaptButton>
                ) : (
                  <AdaptButton>
                    <InfraButton
                      color="neutral"
                      type="submit"
                      size="medium"
                      outline={false}
                      fullWidth={true}
                      onClick={() => onCloseModal(true)}
                    >
                      {t('scheduledPrice.form.button.sku')}
                    </InfraButton>
                  </AdaptButton>
                )}
              </Box>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ModalSku;
