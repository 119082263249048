import { InfraAutocomplete } from '@infralabs/design-system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KEY_SELLER_ID, KEY_SELLER_LABEL } from '../../constants/localStorageKeys';
import { Sellers } from '../../Model/Sellers';
import { SellerRepository } from '../../Repository/SellerRepository';
import { useAccount } from '../CurrentAccount';
import { SelectSellersTypeProps } from './Type';

export const AutocompleteSellers: React.FC<SelectSellersTypeProps> = ({
  onChange,
  defaultValue = '',
  placeholder = 'Select',
  channelId,
}) => {
  const { currentTenant, currentChannel } = useAccount();
  const [sellers, setSellers] = useState<Sellers[]>([]);
  const rep = new SellerRepository();
  const [value, setValue] = useState<string | null>(defaultValue);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleEvent = (event: React.SyntheticEvent, value: any) => {
    localStorage.setItem(KEY_SELLER_ID, value.id);
    localStorage.setItem(KEY_SELLER_LABEL, value.label);
    setValue(value?.label || '');
    onChange(value?.id || '', value?.label || '');
  };

  const sellerValueAdapter = (seller) => ({
    label: seller.name || '',
    id: seller.id || '',
  });

  async function loadSellers() {
    const { records } = await rep
      .setChannelId(channelId || currentChannel?.id)
      .findAll('1')
      .catch(() => ({ records: [] }));

    setSellers(records);
  }

  useEffect(() => {
    if (!channelId) {
      if (!!currentChannel?.id) return;
    }

    (async () => {
      await loadSellers();
    })();
  }, [localStorage.getItem('ifc-channel-id')]);

  useEffect(() => {
    const sellerId = localStorage.getItem(KEY_SELLER_ID);

    const sellerInSellers = sellers.find((seller) => seller.id === sellerId);

    if (sellerInSellers) {
      setValue(sellerInSellers.name);
      setInputValue(sellerInSellers.id);
      onChange(sellerInSellers.id, sellerInSellers.name);
    } else if (sellers.length > 0) {
      const defaultSeller = sellers?.find((seller) => seller.name.includes('Default')) ?? sellers?.[0];

      setValue(defaultSeller.name);
      setInputValue(defaultSeller.id);
      onChange(defaultSeller.id, defaultSeller.name);
    } else {
      setValue('');
      setInputValue('');
    }
  }, [sellers]);

  return (
    <>
      <InfraAutocomplete
        size="small"
        onChange={handleEvent}
        options={sellers.map(sellerValueAdapter)}
        value={value}
        onInputChange={(event, newInputEvent) => {
          setInputValue(newInputEvent);
        }}
        inputValue={inputValue}
        placeholder={placeholder}
        noOptionsText={t('noOption')}
      />
    </>
  );
};
