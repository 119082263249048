import { Flex, Box } from '../../components';
import { InfraTag, InfraSwitch, InfraTypography } from '@infralabs/design-system';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FormatCurrency } from '../../Helper/FormatCurrency';
import { StatusColor } from '../../common/utils/statusColor';
import { SchedulePriceRepository } from '../../Repository/SchedulePriceRepository';
import { Prices } from '../../Model/Prices';
import { StatusEnum } from '../../common/enum/status.enum';
import { format } from 'date-fns';

const ModalDetails = ({ onCloseModal, price }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(price?.isActive || false);
  const formLabel = {
    statusActive: t('scheduledPrice.form.labels.statusActive'),
    statusInactive: t('scheduledPrice.form.labels.statusInactive'),
  };

  let statusColor = new StatusColor();

  const handleSwitch = async (_, checkedEvent) => {
    const scheduledPriceRepository = new SchedulePriceRepository();

    if (price) {
      const data = [
        {
          id: price.id,
          active: checkedEvent,
        } as Prices,
      ];
        const firstChannelId = price.channels?.[0]?.id
        scheduledPriceRepository.setChannelId(firstChannelId).update('1', data).then(() => {
          setChecked(checkedEvent);
        })
    }
  };

  const Item = ({ label, value }) => {
    return <Flex gap="8px" align={'center'}>
      <InfraTypography variant={'h5_small'} weight={'semibold'} color="neutral_500" >
        {label}:
      </InfraTypography>
      <InfraTypography variant={'h4_small'} weight={'regular'} color="neutral_400" >
        {value}
      </InfraTypography>
    </Flex>
  }

  return (
    <>
      <Flex gap="30px" direction="column" mt="24px">
        <Flex gap="10px" direction='column'>
          <Item label="Seller" value={price.seller} />
          <Item label="Produto" value={price.product} />
          <Item label="SKU" value={price.sku} />
          <Item label="Preço de" value={price.highPrice ? FormatCurrency.format('BRL', price.highPrice) : price.highPrice} />
          <Item label="Preço por" value={price.price ? FormatCurrency.format('BRL', price.price) : price.price} />
          <Flex mt="14px" gap="8px" align={'center'} mb="7px">
            <InfraTypography variant={'h4_small'} weight={'bold'} color="neutral_700" >
              Período
            </InfraTypography>
            <InfraTag
              color={statusColor.status[price.status]}
              name={
                price.status
                  ? t('scheduledPrice.fields.status.status.' + price.status)
                  : t('scheduledPrice.fields.status.status.pending')
              }
            />
          </Flex>
          <Item  label="Data de Ativação" value={format(new Date(price.scheduledAt), 'dd.MM.yyyy HH:mm')} />    
          <Flex align={'center'} justify={'space-between'}>
            <Flex align={'center'}>
              <InfraTypography variant={'h5_small'} weight={'semibold'} color="neutral_500" >
                Status:
              </InfraTypography>
              <Box ml="8px" mt="-2px">
                <InfraSwitch
                  checked={checked}
                  disabled={price.status !== StatusEnum.pending ?? false}
                  onChange={(_, checkedEvent) => handleSwitch(_, checkedEvent)}
                />
              </Box>
            </Flex>
            {checked ? (
              <Box mt="-6px">
                <InfraTag color="active" name={formLabel.statusActive} outline={false} borde={false} />
              </Box>
            ) : (
              <Box mt="-6px">
                <InfraTag color="inactive" name={formLabel.statusInactive} outline={false} borde={false} />
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ModalDetails;
