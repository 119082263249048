import { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { differenceInHours } from 'date-fns';

import {
  InfraButton,
  InfraTag,
  InfraSwitch,
  InfraInputCurrency,
  InfraLabel,
  InfraDatepicker,
  InfraTypography,
} from '@infralabs/design-system';

import { WrapperField, Box, AutocompleteSellers, RowErrorMessage, Flex } from '../../components';
import { SelectProducts } from '../../components/Selects';

import { ResultEnum } from '../../common/enum/result.enum';
import { WrapperMessage } from '../../components/AlertMessage/styles';
import { SchedulePriceRepository } from '../../Repository/SchedulePriceRepository';
import { AdaptButton } from '../styles/style-wrapper-modal';
import { isDateDiffMinutesAllowed } from '../../Helper';
import { AutocompleteChannels } from '../../components/AutocompleteChannels';

const scheduledPriceRepository = new SchedulePriceRepository();

const ModalProduct = ({ onCloseModal }) => {
  const [sellerId, setSellerId] = useState('');
  const [product, setProduct] = useState(null);
  const [status, setStatus] = useState(false);
  const { t } = useTranslation();

  const formLabel = {
    createdAt: t('scheduledPrice.form.labels.createdAt'),
    costPrice: t('scheduledPrice.form.labels.costPrice'),
    price: t('scheduledPrice.form.labels.price'),
    highPrice: t('scheduledPrice.form.labels.highPrice'),
    product: t('scheduledPrice.form.labels.product'),
    seller: t('scheduledPrice.form.labels.seller'),
    statusActive: t('scheduledPrice.form.labels.statusActive'),
    statusInactive: t('scheduledPrice.form.labels.statusInactive'),
    scheduledAt: t('scheduledPrice.form.labels.scheduledAt'),
  };

  const formPlaceholder = {
    createdAt: t('scheduledPrice.form.placeholders.createdAt'),
    costPrice: t('scheduledPrice.form.placeholders.costPrice'),
    price: t('scheduledPrice.form.placeholders.price'),
    highPrice: t('scheduledPrice.form.placeholders.highPrice'),
    product: t('scheduledPrice.form.placeholders.product'),
    seller: t('scheduledPrice.form.placeholders.seller'),
    scheduledAt: t('scheduledPrice.form.placeholders.scheduledAt'),
  };

  const handleSubmit = async (values) => {
    let price = Number(String(values.price).replace(',', '.'));
    let highPrice = Number(String(values.highPrice).replace(',', '.'));
    let scheduledAt = values.scheduledAt;
    if (highPrice === 0) {
      highPrice = undefined;
    }

    if (scheduledAt === undefined || scheduledAt === '' || scheduledAt.length === 0) {
      scheduledAt = '';
    }

    const scheduledPrice = [
      ...product.skus.map((sku) => ({
        currency: 986,
        skuId: sku.skuId,
        sellerId,
        price: price,
        highPrice,
        isActive: status,
        scheduledAt: scheduledAt,
      })),
    ];

    type Result = {
      skuId: string;
      result: string;
    };

    try {
      const { records } = await scheduledPriceRepository.setChannelId(values.salesChannel).save<Result>('1', scheduledPrice);
      let type = ResultEnum.success;
      let messages;
      const someFailed = records.some(
        (el) => el.result === ResultEnum.failed || el.result === ResultEnum.alreadyExists,
      );
      if (someFailed) {
        type = ResultEnum.both;
        messages = prepareMsgBoth(records);
      }
      onCloseModal({ type, messages });
    } catch (err) {
      let messages;

      if (err.response.data.message) {
        messages = prepareMsgsErrors(err.response.data.message, scheduledPrice, 'validator');
      } else {
        messages = prepareMsgsErrors(err.response.data.data['scheduled-prices'], scheduledPrice, 'internal');
      }

      onCloseModal({ type: 'failed', messages });
    }
  };

  function prepareMsgBoth(prices) {
    return prices.map((el) => ({
      id: el.id,
      sku: el.skuId,
      skuName: el.sku,
      result: el.result,
      error: el.error ?? null,
    }));
  }

  function prepareMsgsErrors(pricesFailedOrMessages: any[], prices, type: 'validator' | 'internal') {
    let errors = [];
    if (type === 'validator') {
      errors = pricesFailedOrMessages.map((error) => {
        const errorSplited = error.split('.');
        const position = parseInt(errorSplited[1]);
        const message = errorSplited[2];
        const price = prices[position];
        return { sku: price.skuId, message };
      });
    } else {
      errors = pricesFailedOrMessages
        .filter((el) => el.result === 'failed')
        .map((el) => ({
          sku: el.skuId,
          skuName: el.sku,
          message: el.error,
        }));
    }
    return errors;
  }

  return (
    <>
      <Formik
        initialValues={{
          salesChannel: '',
          email: '',
          product: '',
          seller: '',
          price: '',
          highPrice: '',
          scheduledAt: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          highPrice: Yup.string().notRequired(),
          price: Yup.string().required(t('textValidations.required')),
          seller: Yup.string().required(t('textValidations.required')),
          product: Yup.string().required(t('textValidations.required')),
          scheduledAt: Yup.string().required(t('textValidations.required')),
        })}
        validate={(values) => {
          const errors: any = {};
          if (values.highPrice !== '') {
            if (Number(String(values.price).replace(',', '.')) > Number(String(values.highPrice).replace(',', '.'))) {
              errors.price = t('scheduledPrice.form.textValidations.priceBiggerThen');
            }
          }

          if (values.price && values.price <= 0) {
            errors.price = t('textValidations.invalid');
          }

          if (values.scheduledAt == '') {
            errors.scheduledAt = t('textValidations.required');
          }

          if (isDateDiffMinutesAllowed(values.scheduledAt, +process.env.SCHEDULE_DIFFERECE_DATE_LIMIT)) {
            errors.scheduledAt = t('scheduledPrice.form.textValidations.ScheduledGreaterToday', {
              minutes: process.env.SCHEDULE_DIFFERECE_DATE_LIMIT,
            });
          }

          return errors;
        }}
      >
        {(props) => {
          const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } =
            props;
          return (
            <form onSubmit={handleSubmit}>
              <WrapperField>
                <AutocompleteChannels
                  labelBoxProps={{
                    mt: '24px'
                  }}
                  label="Channels"
                  onChange={(salesChannel) => {
                    setFieldValue('salesChannel', salesChannel.id);
                  }}
                />
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600">{`${formLabel.seller}`}</InfraLabel>
                <AutocompleteSellers
                  versionId="1"
                  onChange={(sellerId, label) => {
                    setFieldValue('seller', label);
                    setSellerId(sellerId);
                  }}
                  placeholder={formPlaceholder.seller}
                  channelId={values.channelId}
                />
                {errors.seller && touched.seller && <RowErrorMessage>{errors.seller}</RowErrorMessage>}
              </WrapperField>
              <WrapperField>
                <InfraLabel color="neutral_600">{formLabel.product}</InfraLabel>
                <SelectProducts
                  search={true}
                  value={values.product}
                  onChange={(data) => {
                    setFieldValue('product', data.label, true);
                    setProduct(data.value);
                  }}
                  placeholder={formPlaceholder.product}
                  searchPlaceholder={formPlaceholder.product}
                />

                {errors.product && touched.product && <RowErrorMessage>{errors.product}</RowErrorMessage>}
              </WrapperField>
              <div>
                <WrapperField>
                  <InfraLabel color="neutral_600" optional={true}>{`${formLabel.highPrice}`}</InfraLabel>
                  <InfraInputCurrency
                    id="highPrice"
                    placeholder={formPlaceholder.highPrice}
                    decimalSeparator=","
                    inputStyle={errors.highPrice && touched.highPrice ? 'error' : 'normal'}
                    decimalsLimit={2}
                    onValueChange={(value, name) => {
                      setFieldValue('highPrice', value, true);
                    }}
                    message={errors.highPrice && touched.highPrice ? errors.highPrice : ''}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    height="small"
                  />
                </WrapperField>
                <WrapperField>
                  <InfraLabel color="neutral_600">{`${formLabel.price}`}</InfraLabel>
                  <InfraInputCurrency
                    id="price"
                    name="price"
                    placeholder={formPlaceholder.price}
                    decimalSeparator=","
                    decimalsLimit={2}
                    inputStyle={errors.price && touched.price ? 'error' : 'normal'}
                    onValueChange={(value, name) => {
                      setFieldValue(name, value, true);
                    }}
                    onBlur={handleBlur}
                    message={errors.price && touched.price ? errors.price : ''}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    height="small"
                  />
                </WrapperField>

                <WrapperField>
                  <InfraLabel label="activateAt" theme="light" color="neutral_600">
                    {t('scheduledPrice.form.labels.scheduledAt')}
                  </InfraLabel>
                  <InfraDatepicker
                    height="small"
                    autoComplete={'off'}
                    subtractYears={10}
                    placeholder={t('scheduledPrice.fields.filter.period.placeholder')}
                    onChange={(value, name) => {
                      setFieldValue('scheduledAt', value, true);
                    }}
                    theme="light"
                    setHours={true}
                    maskDate="dd.MM.yyyy, HH:mm"
                    shouldCloseOnSelect={false}
                  />
                  {errors.scheduledAt && <WrapperMessage>{errors.scheduledAt}</WrapperMessage>}
                </WrapperField>

                <Flex justify={'space-between'} align={'center'} mt="24px">
                  <Flex align={'center'}>
                    <InfraTypography variant={'h5_small'} weight={'regular'} style={{ marginRight: '9px' }}>
                      Status
                    </InfraTypography>
                    <Box mt="-2px">
                      <InfraSwitch onChange={(e, checked) => setStatus(checked)} checked={status} />
                    </Box>
                  </Flex>
                  <Box mt="-2px">
                    <InfraTag
                      color={status ? 'active' : 'inactive'}
                      name={status ? formLabel.statusActive : formLabel.statusInactive}
                      outline={false}
                      borde={false}
                    />
                  </Box>
                </Flex>
                <Box mt="32px">
                  <AdaptButton>
                    <InfraButton
                      color="neutral"
                      type="submit"
                      size="large"
                      fullWidth={true}
                      outline={false}
                      disabled={isSubmitting}
                    >
                      <InfraTypography variant={'h5'} weight={'bold'} color="shade_0">
                        {t('programmedPrice.form.button.product')}
                      </InfraTypography>
                    </InfraButton>
                  </AdaptButton>
                </Box>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ModalProduct;
