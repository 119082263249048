import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  InfraGrid,
  InfraCol,
  InfraBreadcrumbs,
  InfraTypography,
  InfraTag,
  InfraIcons,
  InfraChips,
} from '@infralabs/design-system';
import * as S from './style';
import { Flex, Box, ReturnLink, CurrentChannelContext, useAccount } from '../../../components';
import { BoxDetail } from '../../../components/BoxDetail/style';
import { Promotions } from '../../../Model/Promotions';
import { PromotionRepository, PromotionSalesOperatorRepository } from '../../../Repository';
import { StatusColorEnumerator } from '../../../common';
import { MultiSelectSalesOperator } from '../../../components/Selects/MultiSelectSalesOperator';
import { SalesOperatorCodeId } from '../../../Model/SalesOperator';
import { toISOString } from '../../../utils/toISOString';
import { useQuery } from '../../../hooks/useQuery';

export const PromotionDetail = ({ locationUrl = '' }: { locationUrl: string }) => {
  const { currentTenant } = useAccount();
  let history = useHistory();

  useEffect(() => {
    if (locationUrl != '') {
      history.push(locationUrl)
    }
  }, [locationUrl])

  const { t } = useTranslation();
  const { id } = useParams();
  let query = useQuery();
  const channelId = useMemo(() => {
    const queryChannelId = query.get('channelId')
    if (!queryChannelId) history.push('/promotions');
    return queryChannelId
  }, [query])
  const [promotion, setPromotion] = useState<Promotions>({});
  const [sellerCodeValues, setSellerCodeValues] = useState([]);
  const [deliveryModesGroup, setDeliveryModesGroup] = useState([]);
  const [errorSalesOperators, setErrorSalesOperators] = useState([]);
  const promotionRepository = new PromotionRepository();
  const promotionSalesOperatorRepository = new PromotionSalesOperatorRepository();


  async function loadPromotions() {
    if (id) {
      try {
        const result: any = await promotionRepository.setChannelId(channelId).findOne('1', id);
        setPromotion(result.data.promotions[0]);
        if (result.data.promotions[0]?.['sellerCodes']?.length > 0) {
          const sellerCodes = result.data.promotions[0]?.['sellerCodes']?.map((item) => ({
            value: item.sellerCode,
          }));
          setSellerCodeValues(sellerCodes);
        }
        if (result.data.promotions[0]?.['deliveryModes']?.length > 0) {
          const group = groupFrom(result.data.promotions[0]?.['deliveryModes'], 3);
          setDeliveryModesGroup([...group]);
        }
      } catch (err) {
        history.push('/promotions');
      }
    }
  }

  const putSellerCodesCallback = useCallback(async function putSellerCodes(updatedSalesOperatorItems: Array<SalesOperatorCodeId>) {
    const salesOperators = updatedSalesOperatorItems.map((values: any) => ({  codeId: values.value  }));

    try {
      await promotionSalesOperatorRepository.setChannelId(channelId).updateSaleOperator('1', id, salesOperators);
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorSalesOperators(['error']);
      }
    }
  }, [])

  function groupFrom(data, offset) {
    var item = [];
    for (let i = 0; i < data.length; i = i + offset) {
      const slice = data.slice(i, i + offset);
      item.push(slice);
    }
    return item;
  }

  useEffect(() => {
    loadPromotions();
  }, [currentTenant]);

  const links = [
    {
      text: t('app'),
      href: '/',
      use: 'link',
    },
    {
      text: t('main'),
      href: '/',
      disabled: false,
    },
    {
      text: t('promotion.breadcrumbs.home'),
      href: '/promotions',
      disabled: false,
    },
    {
      text: t('promotion.breadcrumbs.detail'),
      href: '',
      disabled: true,
    },
  ];

  const renderBreadcrumb = () => {
    return (
      <Box>
        <InfraBreadcrumbs links={links} componentLink={Link} />
      </Box>
    );
  };

  const backToAllPromotions = () => {
    return <ReturnLink to="/promotions" />;
  };

  const renderPromotionStatus = (status: string, isActive: boolean) => {
    if (!status) return '';
    if (isActive == false) {
      return <InfraTag color="inactive" name={t('price.form.labels.statusInactive')} outline={false} borde={false} />;
    }
    return (
      <InfraTag
        color={StatusColorEnumerator[status]}
        name={status ? t('price.fields.status.status.' + status) : t('price.fields.status.status.pending')}
      ></InfraTag>
    );
  };

  const renderTitle = () => {
    return (
      <>
        <Flex wrap="wrap">
          <InfraTypography variant={'h1_small'} weight={'bold'}>
            <div style={{ textTransform: 'uppercase' }}>{promotion?.name}</div>
          </InfraTypography>
          <S.Tag>{renderPromotionStatus(promotion?.['status'], promotion?.isActive)}</S.Tag>
        </Flex>
        {backToAllPromotions()}
      </>
    );
  };

  const renderDisabled = (title: string, message: string, sizeHeight = '158px') => {
    if (!title || !message) return '';
    return (
      <Box>
        <BoxDetail height={sizeHeight} disable={true} closeDefault>
          <S.TitleError>
            <Box>{title}</Box>
          </S.TitleError>
          {sizeHeight === '227px' ? <S.SpacingBox></S.SpacingBox> : null}
          <S.WarningWrapper>
            <InfraIcons name="Warning" size={24} />
          </S.WarningWrapper>
          <S.MessageError>
            <InfraTypography variant={'h4_small'} weight={'regular'} color={'neutral_400'}>
              {message}
            </InfraTypography>
          </S.MessageError>
        </BoxDetail>
      </Box>
    );
  };

  const formatDate = (date: Date) => {
    if (!date) return '';
    let dateToFormat = toISOString(date);
    return format(new Date(dateToFormat), 'dd.MM.yyyy HH:mm:ss');
  };

  const valuePromotionTypeInString = (typeId: number): string => {
    if (!typeId) return '';
    if (typeId === 1) return 'sku';
    if (typeId === 2) return 'freight';
    if (typeId === 3) return 'payments';
  };

  const convertDeviceIdToName = (deviceId: number): string => {
    if (!deviceId) return '';
    if (deviceId === 1) return 'iOS';
    if (deviceId === 2) return 'Android';
    if (deviceId === 3) return 'Web';
    if (deviceId === 99) return 'Todos';
  };

  const hasUtmAtPromotion = (utmTerm: string, utmCampaign: string, utmSource: string, utmMedium: string) => {
    if (
      !promotion?.['utmTerm'] ||
      !promotion?.['utmCampaign'] ||
      !promotion?.['utmSource'] ||
      !promotion?.['utmMedium']
    )
      return false;
    if (promotion?.['utmTerm'] || promotion?.['utmCampaign'] || promotion?.['utmSource'] || promotion?.['utmMedium'])
      return true;
  };

  const renderPromotionMethodsPayment = () => {
    return promotion?.['methodsPayment']?.length > 0 ? (
      <Box>
        <BoxDetail height="227px">
          <S.Title>
            <Box>{t('promotion.detail.payment')}</Box>
          </S.Title>
          {promotion?.['methodsPayment'].map((payment, index) => (
            <React.Fragment key={index}>
              <S.BodyColumn>
                <Box>
                  <S.BodyName>{t('promotion.detail.paymentOptions')}</S.BodyName>
                  <InfraTypography variant={'h5_small'} weight={'regular'}>
                    <S.BodyValue>{payment.name}</S.BodyValue>
                  </InfraTypography>
                </Box>
                <Box>
                  <S.BodyName>{t('promotion.detail.installments')}</S.BodyName>
                  <InfraTypography variant={'h5_small'} weight={'regular'}>
                    <S.BodyValue>{payment.maxInstallmentsCount || 1}</S.BodyValue>
                  </InfraTypography>
                </Box>
                <Box>
                  <div></div>
                  <div></div>
                </Box>
              </S.BodyColumn>
              {promotion?.['methodsPayment'].length - 1 !== index ? <S.Hr /> : null}
            </React.Fragment>
          ))}
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('promotion.detail.payment'), t('promotion.detail.paymentError'), '227px')
    );
  };

  const renderPromotionDeliveryModes = () => {
    return promotion?.['deliveryModes']?.length > 0 ? (
      <Box>
        <BoxDetail height="227px">
          <S.Title>
            <Box>{t('promotion.detail.freight')}</Box>
          </S.Title>
          {deliveryModesGroup.length > 0 &&
            deliveryModesGroup.map((deliveriesMode, index) => {
              return (
                <React.Fragment key={index}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {deliveriesMode.map((delivery, indexDelivery) => {
                      return (
                        <S.BodyColumnFreight key={indexDelivery}>
                          <div style={{ width: '217px' }}>
                            <S.BodyName>{t('promotion.detail.freightType')}</S.BodyName>
                            <S.BodyValue>{delivery.name}</S.BodyValue>
                          </div>
                        </S.BodyColumnFreight>
                      );
                    })}
                  </div>
                  {index !== deliveryModesGroup?.length - 1 ? <S.Hr /> : null}
                </React.Fragment>
              );
            })}
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('promotion.detail.freight'), t('promotion.detail.freigthError'), '227px')
    );
  };

  const renderPromotionGeneralData = () => {
    return (
      <Box>
        <BoxDetail height="158px">
          <S.Title>
            <Box>{t('promotion.detail.generalData')}</Box>
          </S.Title>
          <S.BodyColumn>
            <Box>
              <S.BodyName>{t('promotion.detail.seller')}</S.BodyName>
              <S.BodyValue>{promotion?.sellerName}</S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('promotion.detail.promotionPeriod')}</S.BodyName>
              <S.BodyValue>
                <InfraTypography variant={'h5_small'} weight={'regular'}>
                  {formatDate(promotion?.activateAt)} - {formatDate(promotion?.deactivateAt)}
                </InfraTypography>
              </S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('promotion.detail.promotionType')}</S.BodyName>
              <InfraTypography variant={'h5_small'} weight={'regular'}>
                <S.BodyValue>{valuePromotionTypeInString(promotion?.['typeId'])}</S.BodyValue>
              </InfraTypography>
            </Box>
          </S.BodyColumn>
          <S.Hr />
          <S.BodyColumn>
            <Box>
              <S.BodyName>{t('promotion.forms.promotion.labels.isAccumulative')}</S.BodyName>
              <S.BodyValue>
                <InfraTypography variant={'h5_small'} weight={'regular'}>
                  {promotion?.isAccumulative ? t('yes') : t('no')}
                </InfraTypography>
              </S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('promotion.forms.promotion.labels.firstBuy')}</S.BodyName>
              <S.BodyValue>
                <InfraTypography variant={'h5_small'} weight={'regular'}>
                  {promotion?.firstBuy ? t('yes') : t('no')}
                </InfraTypography>
              </S.BodyValue>
            </Box>
          </S.BodyColumn>
        </BoxDetail>
      </Box>
    );
  };

  const renderPromotionCategories = () => {
    return promotion?.['categories']?.length > 0 ? (
      <Box>
        <BoxDetail height="158px">
          <S.Title>
            <Box>{t('promotion.detail.category')}</Box>
          </S.Title>
          <S.BodyValue>
            <InfraTypography variant={'h5_small'} weight={'regular'}>
              {promotion?.['categories'].map((category, key) =>
                promotion?.['categories'].length - 1 !== key ? (
                  <span key={key}> {category.name}, </span>
                ) : (
                  <span key={key}> {category.name}. </span>
                ),
              )}
            </InfraTypography>
          </S.BodyValue>
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('promotion.detail.category'), t('promotion.detail.categoryError'))
    );
  };

  const renderDiscount = () => {
    if (!promotion?.id) return '';
    if (promotion?.discountValue) return promotion?.discountValue + ' R$';
    if (promotion?.discountPercentage) return promotion?.discountPercentage + '%';
  };

  const renderPromotionType = () => {
    return (
      <Box>
        <BoxDetail height="227px">
          <S.Title>
            <Box>{t('promotion.detail.titlePromotionType')}</Box>
          </S.Title>
          <S.BodyColumn>
            <Box>
              <S.BodyName>{t('promotion.detail.coupon')}</S.BodyName>
              <S.BodyValue>{promotion?.code || 'Não'}</S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('promotion.detail.discount')}</S.BodyName>
              <S.BodyValue>{renderDiscount()}</S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('promotion.detail.devices')}</S.BodyName>
              <S.BodyValue>{convertDeviceIdToName(promotion?.['deviceId']) || 'Não'}</S.BodyValue>
            </Box>
          </S.BodyColumn>
        </BoxDetail>
      </Box>
    );
  };

  const renderPromotionDescription = () => {
    return promotion?.['description'] !== null && promotion?.['description'] !== '' ? (
      <Box>
        <BoxDetail height="227px">
          <S.Title>
            <Box>{t('promotion.detail.description')}</Box>
          </S.Title>
          <Box>
            <InfraTypography variant={'h5_small'} weight={'regular'}>
              <S.BodyValue>{promotion?.['description']}</S.BodyValue>
            </InfraTypography>
          </Box>
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('promotion.detail.description'), t('promotion.detail.descriptionError'), '227px')
    );
  };

  const isPromotionInactiveExpiredOrHasCode = () => {
    if (!promotion?.['status']) return '';
    if (promotion?.code) return true;
    if (promotion?.['status'] === 'expired') return true;
    if (promotion?.['status'] === 'canceled') return true;
    return false;
  };

  const renderPromotionSellerCodes = () => {
    return isPromotionInactiveExpiredOrHasCode() ? (
      <Box>
        <BoxDetail height="227px" >
          <S.TitleErrorChips>
            <Box>{t('promotion.detail.sellerCode')}</Box>
          </S.TitleErrorChips>

          <S.BodyColumn>
            <S.BoxChip>
              <InfraChips disabled={true} color="default" values={[...sellerCodeValues].map(({value}) => ({ text: value }))} />
            </S.BoxChip>
          </S.BodyColumn>
        </BoxDetail>
      </Box>
    ) : (
      <Box>
        <BoxDetail  height="158px" disableOverflow={"true"} >
          <S.TitleChips >
            <Box>{t('promotion.detail.sellerCode')}</Box>
          </S.TitleChips>
           <MultiSelectSalesOperator channelId={channelId} value={sellerCodeValues} onChange={putSellerCodesCallback}/>
        </BoxDetail>
      </Box>
    );
  };

  const renderPromotionSkuException = () => {
    return promotion?.['skusException']?.length > 0 ? (
      <Box>
        <BoxDetail height="158px">
          <S.Title>
            <Box>{t('promotion.detail.skuException')}</Box>
          </S.Title>
          <Box>
            {promotion?.['skusException'].map((skuException, index) =>
              promotion?.['skusException'].length - 1 !== index ? (
                <span key={index}>
                  <S.BodyValue>
                    <InfraTypography variant={'h5_small'} weight={'regular'}>
                      {t('promotion.detail.sku')} {skuException.skuName} - {t('promotion.detail.product')}{' '}
                      {skuException['productName']},{' '}
                    </InfraTypography>
                  </S.BodyValue>
                </span>
              ) : (
                <S.BodyValue key={index}>
                  <InfraTypography variant={'h5_small'} weight={'regular'}>
                    <span>
                      {t('promotion.detail.sku')} {skuException.skuName} - {t('promotion.detail.product')}{' '}
                      {skuException['productName']}.{' '}
                    </span>
                  </InfraTypography>
                </S.BodyValue>
              ),
            )}
          </Box>
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('promotion.detail.skuException'), t('promotion.detail.skuExceptionError'))
    );
  };

  const renderPromotionUtm = () => {
    return hasUtmAtPromotion(
      promotion?.['utmTerm'],
      promotion?.['utmCampaign'],
      promotion?.['utmSource'],
      promotion?.['utmMedium'],
    ) ? (
      <Box>
        <BoxDetail height="158px">
          <S.Title>
            <Box>{t('promotion.detail.utm')}</Box>
          </S.Title>
          <S.BodyColumn>
            <Box>
              <S.BodyName>{t('promotion.detail.term')}</S.BodyName>
              <S.BodyValue>{promotion?.['utmTerm'] || 0}</S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('promotion.detail.campaign')}</S.BodyName>
              <S.BodyValue>{promotion?.['utmCampaign'] || 0}</S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('promotion.detail.source')}</S.BodyName>
              <S.BodyValue>{promotion?.['utmSource'] || 0}</S.BodyValue>
            </Box>
            <Box>
              <S.BodyName>{t('promotion.detail.medium')}</S.BodyName>
              <S.BodyValue>{promotion?.['utmMedium'] || 0}</S.BodyValue>
            </Box>
          </S.BodyColumn>
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('promotion.detail.utm'), t('promotion.detail.utmError'))
    );
  };

  const renderPromotionSkus = () => {
    return promotion?.['skus']?.length > 0 ? (
      <Box>
        <BoxDetail height="158px" >
          <S.Title>
            <Box>{t('promotion.detail.sku')}</Box>
          </S.Title>
          <Box>
            {promotion?.['skus'].map((sku, index) =>
              promotion?.['skus'].length - 1 !== index ? (
                <span key={index}>
                  <S.BodyValue>
                    <InfraTypography variant={'h5_small'} weight={'regular'}>
                      {t('promotion.detail.sku')} {sku.skuName} - {t('promotion.detail.product')} {sku['productName']},{' '}
                    </InfraTypography>
                  </S.BodyValue>
                </span>
              ) : (
                <S.BodyValue key={index}>
                  <InfraTypography variant={'h5_small'} weight={'regular'}>
                    <span>
                      {t('promotion.detail.sku')} {sku.skuName} - {t('promotion.detail.product')} {sku['productName']}.{' '}
                    </span>
                  </InfraTypography>
                </S.BodyValue>
              ),
            )}
          </Box>
        </BoxDetail>
      </Box>
    ) : (
      renderDisabled(t('promotion.detail.sku'), t('promotion.detail.skuError'))
    );
  };

  const renderContent = () => {
    return (
      <>
        <S.Content height={errorSalesOperators.length > 0 ? '1096px' : '1079px'}>
          <Flex justify={'center'}>
            {renderPromotionGeneralData()}
            {renderPromotionCategories()}
          </Flex>
          <Flex justify={'center'}>
            {renderPromotionMethodsPayment()}
            {renderPromotionDeliveryModes()}
          </Flex>
          <Flex justify={'center'}>
            {renderPromotionType()}
            {renderPromotionDescription()}
          </Flex>
          <Flex justify={'center'}>
            {renderPromotionSellerCodes()}
            {renderPromotionSkuException()}
          </Flex>
          <Flex>
            {renderPromotionUtm()}
            {renderPromotionSkus()}
          </Flex>
        </S.Content>
      </>
    );
  };

  return (
    <>
      <S.WrapperBreadCrumbs>
        <InfraGrid>
          <InfraCol xxl={12} xl={12} md={8} xs={4}>
            <Box mt={'31px'} w="100%">
              {renderBreadcrumb()}
            </Box>
          </InfraCol>
        </InfraGrid>
      </S.WrapperBreadCrumbs>
      <Flex justify={'center'} mb={'32px'}>
        <InfraGrid>
          <InfraCol xxl={12} xl={12} md={8} xs={4}>
            <Box mt={'30px'} w="100%">
              {renderTitle()}
            </Box>
          </InfraCol>
          <InfraCol xxl={12} xl={12} md={8} xs={4}>
            <Box mt={'-10px'} w="100%" overflowX="auto">
              {renderContent()}
            </Box>
          </InfraCol>
        </InfraGrid>
      </Flex>
    </>
  );
};
