import { InfraAutocomplete } from '@infralabs/design-system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from '../../Model/Status';
import { StatusRepository } from '../../Repository/StatusRepository';
import { useAccount } from '../CurrentAccount';
import { SelectStatusTypeProps } from './Type';

export const AutocompleteStatus: React.FC<SelectStatusTypeProps> = ({
  versionId,
  onChange,
  defaultValue = '',
  id,
  placeholder = 'Select',
  typePrice = null,
}) => {
  const { currentTenant, currentChannel } = useAccount();
  const [status, setStatus] = React.useState<Status[]>([]);
  const { t } = useTranslation();
  const statusRepository = new StatusRepository();
  const [value, setValue] = React.useState<string | null>(defaultValue);
  const [inputValue, setInputValue] = React.useState('');
  let typePriceTranslation = typePrice;

  if (typePrice !== 'price') {
    typePriceTranslation = typePrice + 'Price';
  }

  const handleEvent = (event: React.SyntheticEvent, value: any) => {
    setValue(value?.label ?? '');
    onChange(value?.id ?? '', value?.label ?? '');
  };

  useEffect(() => {
    setValue('');
    onChange('', '');

    if (!currentChannel) return;
    async function loadStatus() {
      const { records } = await statusRepository
        .setChannelId(localStorage.getItem('ifc-channel-id'))
        .findAll(versionId, typePrice ? { typePrice: typePrice } : null);
      setStatus(records);
    }
    loadStatus();
  }, [currentTenant, currentChannel]);

  return (
    <>
      <InfraAutocomplete
        size="small"
        onChange={handleEvent}
        options={status.map((status) => ({
          label: t(typePriceTranslation + '.fields.status.status.' + status.status),
          id: status.id,
        }))}
        value={value}
        onInputChange={(event, newInputEvent) => {
          setInputValue(newInputEvent);
        }}
        inputValue={inputValue}
        placeholder={placeholder}
      />
    </>
  );
};
