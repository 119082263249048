import { InfraSelect } from '@infralabs/design-system';
import { useContext, useEffect, useState } from 'react';
import { KEY_CHANNEL_ID } from '../../constants/localStorageKeys';
import { ChargeType } from '../../Model/ChargeType';
import { ChargeTypeRepository } from '../../Repository/ChargeTypeRepository';
import CurrentChannelContext, { useAccount } from '../CurrentAccount';

const chargeTypeRepository = new ChargeTypeRepository();

type SelectChargeProp = {
  onChange: (data: any) => void;
  value?: any;
  placeholder?: string;
  onFetch?: () => void;
  optionFilterDefault?: boolean;
  channelId: string;
};

export const SelectChargeTypes = ({
  onChange,
  placeholder,
  value,
  onFetch,
  optionFilterDefault = false,
  channelId,
}: SelectChargeProp) => {
  const { currentTenant, currentChannel } = useContext(CurrentChannelContext);
  const [items, setItems] = useState<{ label: string; value: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { changeCurrentChannel } = useAccount();

  function prepareItemsSelect(data: ChargeType[]) {
    const options = data.map((el: ChargeType) => ({
      label: el.name,
      value: el.id,
      selected: value === el.id ? true : false,
    }));
    if (optionFilterDefault) {
      setItems([{ label: 'Todos', value: -1 }, ...options]);
    } else {
      setItems(options);
    }
    onFetch();
  }

  useEffect(() => {
    setLoading(true);
    changeCurrentChannel(localStorage.getItem('ifc-channel-id'));
    localStorage.setItem(KEY_CHANNEL_ID, localStorage.getItem('ifc-channel-id'));
    (async () => {
      const { records } = await chargeTypeRepository.setChannelId(localStorage.getItem('ifc-channel-id')).findAll('1');
      prepareItemsSelect(records);
      setLoading(false);
    })();
  }, [currentChannel]);

  useEffect(() => {
    const options = items.map((item) => ({
      ...item,
      selected: value == item.value ? true : false,
    }));

    setItems(options);
  }, [value]);

  return (
    <>
      <InfraSelect size="small" items={items} loading={loading} handlerClick={onChange} placeholder={placeholder} />
    </>
  );
};
