import { InfraSelect } from '@infralabs/design-system';
import { useEffect, useState, useContext } from 'react';
import { Device } from '../../Model/Device';
import { DeviceRepository } from '../../Repository/DeviceRespository';
import CurrentChannelContext from '../CurrentAccount';

const deviceRepository = new DeviceRepository();

type SelectDeviceProp = {
  onChange: (data: any) => void;
  channel: string;
  value?: any;
  placeholder?: string;
  onFetch: () => void;
}

const SelectDevices = ({onChange, channel, value, placeholder, onFetch}: SelectDeviceProp) => {
  const { currentTenant } = useContext(CurrentChannelContext);
  const [items, setItems] = useState<{label: string, value: number}[]>([]);

  function prepareItemsSelect(data: Device[]) {
    const options = data.map((el: Device) => ({
      label: el.name,
      value: el.id,
      selected: el.id === value?.value ? true : false
    }));
    setItems(options)
    onFetch();
  }

  useEffect(() => {
    (async () => {
      const { records } = await deviceRepository.setChannelId(channel).findAll('1');
      prepareItemsSelect(records);
    })();
  },[currentTenant, channel])

  useEffect(() => {
    const options = items.map((item) => ({
      ...item,
      selected: value?.value === item.value ? true : false
    }));
    setItems(options);
  }, [value])

  return (
    <>
      <InfraSelect
        size="small"
        items={items} 
        handlerClick={onChange} 
        placeholder={placeholder}
      />
    </>
  );
}

export default SelectDevices;