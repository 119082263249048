import LayoutWrapper, { LayoutProps } from '../shared/share-styled';

export interface BoxProps extends LayoutProps {
  children: React.ReactNode;
}

export const Box = (props: BoxProps) => {
  return <LayoutWrapper {...props} />
}

