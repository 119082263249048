import { useContext, useEffect, useState } from 'react';
import { InfraSelect } from '@infralabs/design-system';
import { PromotionType } from '../../Model/PromotionType';
import { PromotionTypeRepository } from '../../Repository/PromotionTypeRepository';
import CurrentChannelContext from '../CurrentAccount';

const promotionTypeRepository = new PromotionTypeRepository();

type SelectPromotionProp = {
  onChange: (data: any) => void;
  value?: any;
  placeholder?: string;
  onFetch?: () => void;
  optionFilterDefault?: boolean,
  channelId: string
}

export const SelectPromotionTypes = ({ onChange, placeholder, value, onFetch, optionFilterDefault = false, channelId }: SelectPromotionProp) => {
  const { currentTenant } = useContext(CurrentChannelContext);
  const [items, setItems] = useState<{ label: string, value: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function prepareItemsSelect(data: PromotionType[]) {
    const options = data.map((el: PromotionType) => ({
      label: el.name,
      value: el.id,
      selected: value === el.id ? true : false
    }));
    if (optionFilterDefault) {
      setItems([{label: 'Todos', value: -1 },...options])
    } else {
      setItems(options)
    }
    onFetch();
  }

  useEffect(() => {
    if (!currentTenant || !channelId) return
    setLoading(true);
    (async () => {
      const { records } = await promotionTypeRepository.setChannelId(channelId).findAll('1');
      prepareItemsSelect(records);
      setLoading(false);
    })();
  }, [currentTenant, channelId])

  useEffect(() => {
    if (!value || !items?.length) {
      return;
    }
    const options = items.map((item) => ({
      ...item,
      selected: value == item.value ? true : false
    }));
    setItems(options);
  }, [value])

  return (
    <>
      <InfraSelect
        size="small"
        items={items}
        loading={loading}
        handlerClick={onChange}
        placeholder={placeholder}
      />
    </>
  );
}
