import { Account } from '../Model/Account';
import { API } from '../Services/api';
import { BaseRepository } from './BaseRepository';

export class AccountRepository extends BaseRepository<Account, any> {
  name = 'accounts';
  async getFirstChannel(versionId: string, tenantId: string): Promise<Account> {
    const response: any = await API.get<Account>(`/${this.name}/tenant/` + tenantId + '/channels', {
      headers: { 'X-Version': versionId },
    }).then((response) => response.data);
    return response.data;
  }

    async getAllChannels(versionId: string, tenantId: string): Promise<any> {
    const response: any = await API.get<Account>(`/${this.name}/tenant/` + tenantId + '/channels', {
      headers: { 'X-Version': versionId },
    }).then((response) => response.data);
    return response.data;
  }
}
