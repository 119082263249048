import styled from "styled-components";
import Colors from "../../Helper/Colors";

export const Container = styled.div`
    a {
        display:flex;
        text-decoration: none;
    }
    h6 {
        margin-left: 8px;
        color:${Colors.information_100};
    }
    width:60px;
`;
