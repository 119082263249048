import { useEffect, useState } from 'react';
import { ClustersModule } from '@infralabs/admin-utility-services/dist/services/customer/admin/Clusters.js';
import { InfraSelect } from '@infralabs/design-system';
import { useAccount } from '../../CurrentAccount';
import type { PaginateClustersDataDto } from '@infralabs/admin-utility-services/dist/services/customer/admin/data-contracts';

type ClientsClustersSelectProps = {
  onChange: (data: any) => void;
  value?: any;
  placeholder?: string;
  disable?: boolean;
};

const clustersModule = new ClustersModule();

const ClientsClustersSelect = ({ onChange, placeholder, value, ...rest }: ClientsClustersSelectProps) => {
  const [clustersItems, setClustersItems] = useState([]);
  const { currentChannel } = useAccount();

  function prepareItemsSelect(clusters: PaginateClustersDataDto[]) {
    const options = clusters.map((cluster: PaginateClustersDataDto) => ({
      label: cluster.name,
      value: cluster.id,
      selected: value?.some((x) => x.value === cluster.id),
    }));

    setClustersItems(options);
    return options;
  }

  useEffect(() => {
    (async () => {
      try {
        const { data } = await clustersModule.listCluster(
          {
            active: true,
            perPage: 100,
            page: 1,
          },
          {
            headers: {
              'x-channel-id': currentChannel?.id ?? '',
            },
          },
        );

        prepareItemsSelect(data.data);
      } catch (error) {
        console.warn(error);
        setClustersItems([]);
      }
    })();
  }, [currentChannel]);

  return (
    <InfraSelect items={clustersItems} handlerClick={onChange} placeholder={placeholder} size={'small'} {...rest} />
  );
};

export default ClientsClustersSelect;
