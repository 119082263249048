import { useEffect, useState } from "react";
import { useAccount } from "../../components";
import { SchedulePriceRepository } from "../../Repository/SchedulePriceRepository";

type UseSchedulePricesPaginateProps = {
  pageNumber: number;
  pageSize: number
  sellerId: string;
  channel: string;
  statusId: string
  scheduledAt: string
  description: string
  categoryId: any[]
  brandId: any[]
  order: string
}

interface SchedulePricesParams extends Partial<Omit<UseSchedulePricesPaginateProps, "currentPage" | "categoryId" | "brandId">> {
  category?: string
  brands?: string
}

let timeout
const schedulePriceRepository = new SchedulePriceRepository();
const useSchedulePricesPaginate = (props: UseSchedulePricesPaginateProps) => {
  const [loading, setLoading] = useState(true)
  const { currentChannel } = useAccount()
  const [data, setData] = useState<{
    data: any[],
    paging: {
      recordsReturned?: number;
      totalPages?: number;
      totalRecords?: number;
      currentPage?: number;
    };
  }>({
    data: [], paging: {}
  })

  async function fetchSchedulePrices() {
    const { scheduledAt, categoryId, channel, brandId, order } = props
    try {
      setLoading(true);
      let params: SchedulePricesParams = {
        ...props,
        scheduledAt: scheduledAt ? new Date(scheduledAt).toISOString() : '',
      };

      if (categoryId?.length) {
        params.category = categoryId?.map((e) => e.value).join(',');
      }

      if (brandId?.length) {
        params.brands = brandId?.map((e) => e.value).join(',');
      }

      if (order) {
        params.order = order;
      }

      const { records, paging } = await schedulePriceRepository.setChannelId(channel || currentChannel.id).findPaginate('1', params);

      setData({
        data: records,
        paging
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    clearTimeout(timeout)
    if (!currentChannel) return
    timeout = setTimeout(() =>{
      fetchSchedulePrices()
    }, 300)
  }, [props, currentChannel])

  useEffect(() => {
    return () => schedulePriceRepository.source.cancel()
  }, [])

  return {
    loading,
    data: data.data,
    paging: data.paging,
    repository: schedulePriceRepository,
    refetch: fetchSchedulePrices
  }
}

export default useSchedulePricesPaginate
