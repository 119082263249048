import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 6px;
`;

export const Container = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  font-size: 0.75rem;
  background-color: #f8d7da;
  color: #a12029;
  border-radius: 6px;
  padding: 15px;
`;

export const WrapperMessage = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  color: #e11d2b;
`;

export const ContainerMessageModal = styled.div`
  font-size: 13px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  color: #000;
  span { font-weight: 900 }
  padding-top: 10px;
  padding-bottom: 10px;
`;

