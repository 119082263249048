import { ClustersModule } from '@infralabs/admin-utility-services/dist/services/customer/admin/Clusters.js';
import { InfraAutocomplete } from '@infralabs/design-system';
import { useContext, useEffect, useState } from 'react';
import { Clusters } from '../../Model/Clusters';
import { ClusterRepository } from '../../Repository/ClusterRepository';
import { useTranslation } from 'react-i18next';
import CurrentChannelContext, { useAccount } from '../CurrentAccount';
import type { PaginateClustersDataDto } from '@infralabs/admin-utility-services/dist/services/customer/admin/data-contracts';
import { AutocompleteClusterValue } from './types';

const clusterRepository = new ClusterRepository();

type AutocompleteClusterProps = {
  onChange: (data: AutocompleteClusterValue) => void;
  defaultValue?: AutocompleteClusterValue;
  channelId?: string;
  placeholder?: string;
  disable?: boolean;
  limitTags?: number;
  display?: 'count' | 'chip';
  size?: 'small' | 'medium' | 'large';
  search?: boolean;
  onFetch?: (item) => void;
};

const clustersModule = new ClustersModule();

export const AutocompleteClusters = ({
  onChange,
  onFetch,
  disable = false,
  placeholder,
  defaultValue,
  channelId,
}: AutocompleteClusterProps) => {
  const { currentTenant } = useContext(CurrentChannelContext);
  const { t } = useTranslation();
  const { currentChannel } = useAccount();
  const [items, setItems] = useState<AutocompleteClusterValue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<AutocompleteClusterValue | null>();

  function prepareItemsSelect(clusters: PaginateClustersDataDto[]) {
    const options = [
      ...clusters.map((cluster: PaginateClustersDataDto) => ({
        label: cluster.name,
        value: cluster.id,
      })),
    ];

    setItems(options);
    return options;
  }

  const handleEvent = (event: React.SyntheticEvent, value: AutocompleteClusterValue) => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { data } = await clustersModule.listCluster(
          {
            active: true,
            perPage: 100,
            page: 1,
          },
          {
            headers: {
              'x-channel-id': currentChannel?.id ?? '',
            },
          },
        );

        const items = prepareItemsSelect(data.data);

        if (defaultValue) {
          handleEvent(null, defaultValue);
        }

        onFetch?.(items);
      } catch (error) {
        console.warn(error);
        onFetch?.([]);
        setItems([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentTenant, channelId]);

  return (
    <>
      <InfraAutocomplete
        disabled={disable}
        size="small"
        onChange={handleEvent}
        options={items}
        value={value?.label}
        inputValue={value?.value}
        placeholder={placeholder}
        noOptionsText={t('noOption')}
      />
    </>
  );
};
