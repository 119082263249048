import { useEffect, useMemo, useState } from "react";
import { useAccount } from "../../../components";
import { ImportQueueRepository } from "../../../Repository/ImportQueueRepository";
import { notEmptyObjectValue } from "../../../utils/notEmptyObjectValue";

type UsePricesImportPaginateProps = {
  currentPage?: number
  pageSize: number
  sellerId: string
  channel: string
  order: any
  importType: any
  importStatus: any
  initDate: string
  endDate: string
}

let timeout
const importRepository = new ImportQueueRepository();
const usePricesImportPaginate = (props: UsePricesImportPaginateProps) => {
  const [loading, setLoading] = useState(true)
  const { currentChannel } = useAccount()
  const [data, setData] = useState<{
    data: any[],
    paging: {
      recordsReturned?: number;
      totalPages?: number;
      totalRecords?: number;
      currentPage?: number;
    };
  }>({
    data: [], paging: {}
  })

  const fetchImportQueue = async () => {
    importRepository.source.cancel();
    try {
      setLoading(true);
      const { currentPage = 1, endDate, initDate, channel, ...restProps } = props
      const params = notEmptyObjectValue({
        pageNumber: currentPage,
        endDate: endDate ? new Date(endDate).toISOString() : '',
        initDate: initDate ? new Date(initDate).toISOString() : '',
        ...restProps,
      })

      const { paging, records } = await importRepository.setChannelId(channel || currentChannel.id).findPaginate('1', params);
      setData({
        paging,
        data: records
      })
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fetchImportQueue()
    }, 300)
  }, [props, currentChannel])

  useEffect(() => {
    return () => importRepository.source.cancel()
  }, [])

  return {
    loading,
    data: data.data,
    paging: data.paging,
    refetch: fetchImportQueue,
    repository: importRepository
  }
}

export default usePricesImportPaginate
