import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfraSelect } from '@infralabs/design-system';

type States = {
  id: string;
  name: string;
};

type SelectItem = {
  label: string;
  value: string;
};

type SelectStatesProps = {
  onChange: (data: any) => void;
  value?: Array<SelectItem>;
  placeholder?: string;
  disable?: boolean;
  limitTags?: number;
  display?: 'count' | 'chip';
  size?: 'small' | 'medium' | 'large';
};

const statesOfBrazil: Array<States> = [
  { id: 'AC', name: 'Acre' },
  { id: 'AL', name: 'Alagoas' },
  { id: 'AP', name: 'Amapá' },
  { id: 'AM', name: 'Amazonas' },
  { id: 'BA', name: 'Bahia' },
  { id: 'CE', name: 'Ceará' },
  { id: 'DF', name: 'Distrito Federal' },
  { id: 'ES', name: 'Espírito Santo' },
  { id: 'GO', name: 'Goiás' },
  { id: 'MA', name: 'Maranhão' },
  { id: 'MT', name: 'Mato Grosso' },
  { id: 'MS', name: 'Mato Grosso do Sul' },
  { id: 'MG', name: 'Minas Gerais' },
  { id: 'PA', name: 'Pará' },
  { id: 'PB', name: 'Paraíba' },
  { id: 'PR', name: 'Paraná' },
  { id: 'PE', name: 'Pernambuco' },
  { id: 'PI', name: 'Piauí' },
  { id: 'RJ', name: 'Rio de Janeiro' },
  { id: 'RN', name: 'Rio Grande do Norte' },
  { id: 'RS', name: 'Rio Grande do Sul' },
  { id: 'RO', name: 'Rondônia' },
  { id: 'RR', name: 'Roraima' },
  { id: 'SC', name: 'Santa Catarina' },
  { id: 'SP', name: 'São Paulo' },
  { id: 'SE', name: 'Sergipe' },
  { id: 'TO', name: 'Tocantins' },
];

export const MultiSelectStates = (props: SelectStatesProps) => {
  const { onChange, placeholder, value, disable = false, limitTags = 2, display = 'chip', size = 'small' } = props;
  const [items, setItems] = useState<{ label: string; value: string; selected?: boolean }[]>([]);
  const { t } = useTranslation();

  function itemsSelected() {
    return items.filter((item) => item.selected === true);
  }

  useEffect(() => {
    const states = statesOfBrazil.map((el: States) => ({
      label: el.name,
      value: el.id,
      selected: value?.find((x) => x.value === el.id) ? true : false,
    }));
    setItems(states);
  }, [value]);

  return (
    <>
      <InfraSelect
        customTagColor="black"
        customColorCheckbox="neutral"
        multiple={true}
        items={items}
        handlerClick={onChange}
        placeholder={placeholder}
        disable={disable}
        checkbox={true}
        limitTags={limitTags}
        display={display}
        customMultiSelectedItemText={t('customMultiSelectedItemText', { count: itemsSelected().length })}
        size={size}
      />
    </>
  );
};
