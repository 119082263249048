import axios from 'axios';
import { KEY_CHANNEL_ID } from '../constants/localStorageKeys';
import { DataWithoutPaginate, DataWithPaginate } from '../Model/Data';
import { Entity } from '../Model/Entity';
import { API } from '../Services/api';
const log = console.log;

export abstract class BaseRepository<Model extends Entity, typeId> {
  abstract name: string;
  channelId?: string
  source = axios.CancelToken.source();

  setChannelId(channelId) {
    if (!channelId) console.error("Must set valid channelId")
    this.channelId = channelId
    return this
  }

  setCancelToken() {

  }

  async findAll(versionId: string, queryParams?: any): Promise<DataWithoutPaginate<Model>> {
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? this.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    let url = this.name;
    if (queryParams !== undefined) {
      const params = new URLSearchParams(queryParams);
      url = url + '?' + params;
    }
    const response: any = await API.get<Model[]>(url, {
      headers: headers,
    }).then((response) => response.data);

    const data: DataWithoutPaginate<Model> = {
      records: response.data[`${this.name}`],
    };
    return data;
  }

  async save<T>(versionId: string, data: Model[]): Promise<DataWithoutPaginate<T & Model>> {
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? this.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    const dataToSave = {
      [`${this.name}`]: data,
    };

    const response: any = await API.post<T & Model>(this.name, dataToSave, { headers: headers }).then(
      (response) => response.data,
    );

    const resp: DataWithoutPaginate<T & Model> = {
      records: response.data[`${this.name}`],
    };
    return resp;
  }

  async update<T>(versionId: string, data: Model[]): Promise<T & Model> {
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? this.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    const dataToUpdate = {
      [`${this.name}`]: data,
    };
    const response: any = await API.put<T & Model>(this.name, dataToUpdate, {
      headers: headers,
    }).then((response) => response.data);
    const rep: T & Model = response;
    return rep;
  }

  async findPaginate(versionId: string, queryParams: any, paramUri?: string): Promise<DataWithPaginate<Model>> {
    this.source = axios.CancelToken.source();
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel: string = localStorage.getItem('ifc-channel-id') ?? this.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    const params = new URLSearchParams(queryParams);
    let uri = this.name;

    if (paramUri) {
      uri = this.name + '/' + paramUri;
    }

    try {
      const { data }: any = await API.get<Model[]>(`${uri}?${params}`, {
        cancelToken: this.source.token,
        headers,
      })

      const resolved: DataWithPaginate<Model> = {
        records: data.data[`${this.name}`],
        paging: {
          recordsReturned: data.data.paging.recordsReturned,
          totalPages: data.data.paging.totalPages,
          totalRecords: data.data.paging.totalRecords,
          currentPage: data.data.paging.currentPage,
        },
      };

      return resolved;
    } catch (err) {
      const response = {
        paging: {},
        records: []
      }
      console.log(err)
      if (axios.isCancel(err)) return response
      if (err.reponse) {
        log(err.response.data);
        log(err.response.status);
        log(err.response.headers);
      } else if (err.request) {
        log(err.request.response);
      } else {
        log(err.message);
      }

      return response
    }
  }

  async findOne(versionId: string, key: typeId): Promise<Model> {
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? this.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    const response: any = await API.get<Model>(this.name + '/' + key, {
      headers: headers,
    }).then((response) => response.data);
    const data: Model = response;
    return data;
  }

  protected getHeaders(tenantId: string, channelId: string | any, versionId: string): any {
    return {
      'X-Tenant-Id': localStorage.getItem('ifc-tenant-id') ?? tenantId,
      'X-Channel-Id': localStorage.getItem('ifc-channel-id') ?? channelId,
      'X-Version': versionId,
    };
  }

  protected getValuesStorage() {
    const STORAGE = JSON.parse(localStorage.getItem('pricing'));
    const CHANNEL_ID = localStorage.getItem('ifc-channel-id') ?? localStorage.getItem(KEY_CHANNEL_ID);
    let tenantId = localStorage.getItem('ifc-tenant-id') ?? STORAGE.tenantId;

    return {
      tenantId,
      channelId: CHANNEL_ID,
    };
  }

  async delete(versionId: string, key: typeId): Promise<boolean> {
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel = localStorage.getItem('ifc-channel-id') ?? this.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    const response: any = await API.delete<Model>(this.name + '/' + key, {
      headers: headers,
    })
      .then((response) => response.data)
      .catch((err) => {
        if (err.reponse) {
          log(err.response.data);
          log(err.response.status);
          log(err.response.headers);
          throw new Error(err.response);
        } else if (err.request) {
          log(err.request.response);
          throw new Error(err.response);
        } else {
          log(err.message);
          throw new Error(err.response);
        }
      });
    const data: Model = response;
    return true;
  }

  async softdelete(versionId: string, key: typeId): Promise<boolean> {
    const { tenantId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channel: string = localStorage.getItem('ifc-channel-id') ?? this.channelId;
    let headers = this.getHeaders(tenant, channel, versionId);
    const response: any = await API.patch<Model>(
      this.name,
      { id: key },
      {
        headers: headers,
      },
    )
      .then((response) => response.data)
      .catch((err) => {
        if (err.reponse) {
          log(err.response.data);
          log(err.response.status);
          log(err.response.headers);
          throw new Error(err.response);
        } else if (err.request) {
          log(err.request.response);
          throw new Error(err.response);
        } else {
          log(err.message);
          throw new Error(err.response);
        }
      });
    const data: Model = response;
    return true;
  }
}
