import {
  InfraTypography,
} from '@infralabs/design-system';
import {
  Flex,
  Box,
  Button,
} from '../../components';
import { useHistory } from 'react-router-dom';
import Colors from '../../Helper/Colors';
import { useTranslation } from 'react-i18next';

const PromotionTitle = () => {
  const history = useHistory()
  const { t } = useTranslation();
  const goToPromotion = () => {
    history.push('/promotions/new');
  }

  return (
    <Flex justify={'space-between'} wrap="wrap">
      <InfraTypography variant={'h1_small'} weight={'bold'}>
        {t('promotion.title')}
      </InfraTypography>
      <Box w="180px">
        <Button
          color="neutral"
          size="large"
          outline={false}
          onClick={goToPromotion}
          icon={'Plus'}
          iconRight={true}
        >
          <InfraTypography variant={'h5_small'} weight={'bold'} color={Colors.shade_0}>
            {t('promotion.button.new')}
          </InfraTypography>
        </Button>
      </Box>
    </Flex>
  );
}

export default PromotionTitle
