import { Clusters } from '../Model/Clusters';
import { API } from '../Services/api';
import { BaseRepository } from './BaseRepository';

export class ClusterRepository extends BaseRepository<Clusters, any> {
  name = 'clusters';
  async find(channel) {
    const { tenantId, channelId } = this.getValuesStorage();
    const tenant = localStorage.getItem('ifc-tenant-id') ?? tenantId;
    const channelKey = localStorage.getItem('ifc-channel-id') ?? channel ?? channelId;
    let headers = this.getHeaders(tenant, channelKey, '1');
    const response: any = await API.get<Clusters>('/clusters', {
      headers,
    }).then((response) => {
      return response.data;
    });
    return response.data;
  }
}


