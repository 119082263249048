import { useContext, useEffect, useRef, useState } from 'react';
import { InfraSelect } from '@infralabs/design-system';
import { SkuRepository } from '../../../Repository';
import { Skus } from '../../../Model';
import { useTranslation } from 'react-i18next';
import CurrentChannelContext from '../../CurrentAccount';

const skuRepository = new SkuRepository();
type SelectSkusProp = {
  onChange: (data: any) => void;
  onFetch?: () => void;
  value?: any;
  placeholder?: string;
  disable?: boolean;
  categories?: any[];
  limitTags?: number;
  display?: 'chip' | 'count';
  size?: 'small' | 'medium' | 'large';
  search?: boolean;
  channelId?: string;
};

let timeout
export const MultiSelectSkus = ({
  onChange,
  placeholder,
  value,
  disable,
  categories,
  onFetch,
  limitTags = 2,
  display = 'count',
  size = 'small',
  search = false,
  channelId,
}: SelectSkusProp) => {
  const { currentTenant, currentChannel } = useContext(CurrentChannelContext);
  const [items, setItems] = useState<{ label: string; value: string; selected?: boolean }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>()
  const selected = useRef([])
  const { t } = useTranslation();

  function prepareItemsSelect(data: Skus[]) {
    const uniqueOptions = new Map()
    selected.current.forEach((sku) => {
      uniqueOptions.set(sku.value, sku)
    })

    data.forEach((sku: Skus) => {
      if (uniqueOptions.has(sku.skuId)) return
      uniqueOptions.set(sku.skuId, {
        label: `${sku.productName} - ${sku.name}`,
        value: sku.skuId,
        selected: value?.some((x) => x.value === sku.skuId),
      })
    })

    // @ts-ignore
    const sorttedOptions = [...uniqueOptions.values()].sort((prev, next) => {
      if (prev.label > next.label) return 1;
      if (prev.label < next.label) return -1;
      return 0;
    })
    setItems(sorttedOptions);
    onFetch();
  }

  const fetch = async (search?: string) => {
    try {
      let categoriesParams: { categories?: string, search?: string } = {}
      if (categories?.length) {
        categoriesParams = { categories: categories.map((el) => el.value).join(',') };
      }
      if (search) categoriesParams.search = search
      const { records } = await skuRepository.setChannelId(currentChannel.id).findAll('1', categoriesParams);
      prepareItemsSelect(records);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setItems([]);
    }
  };

  const handleChange = (currentSelected) => {
    selected.current = currentSelected
    onChange(currentSelected)
  }

  useEffect(() => {
    const refetchItems = items.map((item) => ({
      ...item,
      selected: value?.some((x) => x.value === item.value),
    }));
    setItems(refetchItems)
  }, [value])

  useEffect(() => {
    setLoading(true);
    fetch();
  }, [categories, currentTenant]);

  useEffect(() => {
    const target = wrapperRef.current.querySelector('div');
    const observer = new MutationObserver(function(mutations) {
      mutations.forEach(function() {
        const input = wrapperRef.current.querySelector('input')
        input?.focus()
        input?.addEventListener('input', function() {
          const value = this.value.trim()
          if (!value) return fetch()
          if (value.length < 3) return
          clearInterval(timeout)
          timeout = setTimeout(() => {
            fetch(value)
          }, 300)
        })
      });
    });

    observer.observe(target, { childList: true });

    return () => observer.disconnect();
  }, [])

  return (
    <>
      <div ref={wrapperRef}>
        <InfraSelect
          customTagColor='black'
          customColorCheckbox='neutral'
          size={size}
          multiple={true}
          items={items}
          loading={loading}
          handlerClick={handleChange}
          placeholder={placeholder}
          disable={disable}
          checkbox={true}
          limitTags={limitTags}
          display={display}
          search={search}
          customMultiSelectedItemText={t('customMultiSelectedItemText', { count: selected.current.length })}
          searchPlaceholder={t('price.fields.multselect.skus.searchPlaceholder')}
        />
      </div>
    </>
  );
};
