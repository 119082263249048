
import styled from 'styled-components';
import { InfraButton, InfraIcons, InfraModal, InfraTypography} from '@infralabs/design-system';
import * as S  from './style'
import { Colors } from '../../../../Helper';
import { useTranslation } from 'react-i18next';
import { Box } from '../../../../components/Layout';

type AlertModalSuccessProp = {
  operation: 'edit' | 'create';
  show: boolean;
  onClose: any;
  onConfirmModal?: () => void;
  skusAffected: string;
};

const WrapperAlert = styled.div`
  .modal-body {
    padding: 4px 1.844rem 2.5rem 1.844rem;
  }  
`;

export function AlertModalSuccessPromotion({
  operation = 'create',
  show,
  onClose,
  onConfirmModal,
  skusAffected
}: AlertModalSuccessProp) {

  const { t } = useTranslation();
 
  function renderTitle() {
    return t('promotion.forms.textValidations.' + (operation === 'edit' ? 'PromotionUpdated' : 'PromotionCreated'))
  }

  return (
    <WrapperAlert>
      <InfraModal show={show} onClose={onClose} width={'350'}>
        <div>
          <S.IconContainer>
            <S.WrapperIcon>
              <span><InfraIcons name="CheckCircle" color={Colors.success_100} size={65} /></span>
            </S.WrapperIcon>
          </S.IconContainer>
          <S.ContentContainer>
          
          <InfraTypography variant={'h5'} weight={'semibold'} align="center" color="neutral_600">{renderTitle()}</InfraTypography>
          <Box mt="8px">
            <InfraTypography variant={'h5'} weight={'regular'} align="center" color="neutral_600">{skusAffected}</InfraTypography>
          </Box>
          </S.ContentContainer>
          <div>
            <S.WrapperButton>
              <InfraButton
                color={'success'}
                size="large"
                onClick={onConfirmModal}
                outline={false}
                fullWidth={true}
                style={{ background: Colors.success_100, borderColor: Colors.success_100 }}
              >
                OK
              </InfraButton>
            </S.WrapperButton>
          </div>
        </div>
      </InfraModal>
    </WrapperAlert>
  );
}
