import styled from 'styled-components'
import Colors from '../../../Helper/Colors';


export const ListContent = styled.div`
  height: 292px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
      background-color: ${Colors.neutral_100};
  }
  ::-webkit-scrollbar-thumb {
      background-color: ${Colors.neutral_500};
      border-radius: 4px;
  }
}`;

export const ListItem = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  display:  flex;
  align-items: center;
  line-height: 20px;
  min-height: 42px;
  padding-block: 2px;
  gap: 0.6rem;
  color: ${Colors.neutral_600};
  border-bottom: 1px solid ${Colors.neutral_100};
  text-align: start;
  .list-item-span {
    color: ${Colors.neutral_400};
  }
`;

export const ListMessageHeader = styled.div`
  margin-bottom: 2rem;
`
export const ListMessageTitle = styled.p`
  font-size: 20px;
  line-height: 20px;
  color: ${Colors.neutral_600};
`;

export const ListMessageSubTitle = styled.p`
  font-size: 16px;
  line-height: 18px;
  color: ${Colors.neutral_400};
`
export const ListMessageHeaderDefault = styled.div`
 font-size: 20px;
 line-height: 20px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 0px 6px 12px rgba(119, 132, 163, 0.11);
  box-shadow: 0px 6px 12px rgba(119, 132, 163, 0.11);
  padding: 20px 43px;
  position: relative;
  background: ${Colors.shade_0};
  border-radius: 8px;
  min-height: 728px;

  /* .infrasteps_step {
    margin-right: 66px;

    .infrasteps_step--label {
      margin-top: 18px;
    }
  } */

`;

export const BoxContent = styled.div`
  flex: 1;
  padding-top: 44px;
  padding-left: 16px;
  height: 100%;
`
export const ModalTitle = styled.span`
  display: flex!important;
  justify-content: center;
  align-items: center;
  gap: 8px;

  & > div {
    display: flex;
  }
`

export const BoxRadio = styled.div`
  display: flex;
  gap: 16px;

  & > div {
    display: flex;
    gap: 8px
  }

  & input {
    margin: 0
  }

  & label, & input {
    cursor: pointer;
  }
`
export const Categories = styled.div`
  [width="100"] {
    z-index: 1;
    left: -30px;
  }
`
