import styled, { css } from 'styled-components';
import Colors from '../../Helper/Colors';
import { Type } from './type';

export const BoxDetail = styled.div<Type>`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
  font-size: 14px;
  line-height: 20px;
  width: 582px;
  height: ${(props) => props.height};
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid ${Colors.neutral_200};
  padding-inline: 30px;
  padding-top: 27px;
  margin-inline: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ${(props) => setDisabled(props.disable)};
  ${(props) => setOverFlow(props.closeDefault)};
  ${(props) => setNormalOverflow(props.disableOverflow)};
`;

const setDisabled = (disable: boolean) => {
  if (disable) {
    return css`
 ;     color: ${Colors.neutral_400};
      ::-webkit-scrollbar-track {
        background-color: ${Colors.neutral_warn_100};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${Colors.neutral_warn_100};
      }
      background-color: ${Colors.neutral_warn_100};
    `;
  }
  return css`
    background-color: ${Colors.shade_0};
    ::-webkit-scrollbar-track {
      background-color: ${Colors.shade_0};
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${Colors.neutral_200};
      border-radius: 4px;
      border-radius: 55px;
    }
  `;
};

const setOverFlow = (closeDefault: boolean) => {
  if (closeDefault) {
    return css`
      overflow: hidden;
    `;
  }
};

const setNormalOverflow = (disableOverflow: "true" | "false") => {
  if(disableOverflow === "false") {
    return css``;
  }
  return css`
    overflow: initial !important;
  `
}